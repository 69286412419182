/*------------------------------------
	General
------------------------------------*/
#switcher, #switchers {
	position: fixed;
	width: 16.5rem;
	top: 7rem;
	right: 0px;
	background: $white;
	padding: 1rem;
	border: 1px solid $border-color;
	z-index: 999999999999;
	#theme-settings, #theme-setting {
		width: 3rem;
		display: block;
		background: $white;
		position: absolute;
		left: -3rem;
		top: -1px;
		border-left: 1px solid $border-color;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		cursor: pointer;
		font-size: 1.5rem;
		text-align: center;
		padding: 8px;
		color: #666;
		i, img { 
			animation-name: spin;
			animation-duration: 3s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			@keyframes spin { 
				from { 
					transform: rotate(360deg); 
				} to { 
					transform: rotate(0deg); 
				}
			}
		}
	}
	.badge {
		border-radius: 0;
	}
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		margin-bottom: 0.5rem;
	}
	h6 {
		text-align: center;
	}
	ul {
		text-align: center;
		padding-left: 0;
		clear: both;
		overflow: hidden;
		padding-bottom: 0.5rem;
		margin-bottom: .25rem;
	   li {
			display: inline-block;
			list-style: none;
			margin: 2px;
			a {
				&.badge{
					font-weight: 400;
					min-width: 4rem;
					padding: .4rem;
					color: $dark;
					&:hover{
						background: $light;
					}
				}
				&.badge-light{
					&:hover {
						background: transparent !important;
					}
				}
			}
		}
		&.bg-img {
			padding-bottom: 0;
			margin-bottom: 0;
			li {
				margin-bottom: 10px;
			}
		}
	}
	.theme-colors, .second-colors {
	    li {
		    a {
		        &.badge {
					min-width: 2.3rem;
				}
			}
		}
		.red{
			background-color: $red !important;
		}
		.purple{
			background-color: $purple !important;
		}
		.pink{
			background-color: $pink !important;
		}
		.orange{
			background-color: $orange !important;
		}
		.blue{
			background-color: $blue !important;
		}
		.darkblue{
			background-color: $darkblue !important;
		}
		.lightblue{
			background-color: $lightblue !important;
		}
		.green{
			background-color: $green !important;
		}
		.yellow{
			background-color: $yellow !important;
		}
		.blacksoft{
			background-color: $blacksoft !important;
		}
		.black{
			background-color: $black !important;
		}
	}
}
#switchers {
    position: relative;
	top: 0;
	z-index: 10;
}
.show-tools {
	margin-right: 0px;
}
.hide-tools {
	margin-right: -16.5rem;
}
#colorWell {
	border: $white 0px solid;
	background: $white;
	width: 2.3rem;
	margin: 0;
	height: 1.3rem;
	padding: 0;
}
.bs-component {
	margin-bottom: 1rem;
}
.mobile-back {
	max-width: 430px;
	text-align: center;
	min-height: 920px;
	background: url(../img/iphone.png) no-repeat;
	padding: 103px 0 107px 0px;
}
#mobile-frame, #mobile-frame2 {
	height: 600px;
	width: 375px;
	margin: 0 auto;
	margin-top: 20px;
	padding-bottom: 26px;
	padding-left: 20px;
	float: left;
	iframe {
		height: 600px;
		border: none;
		width: 375px;
		box-shadow: 0px 2px 10px #cecece;
	}
}
.dark-skin{
	color: #fff;
    #switcher, #switchers, #theme-settings {
	    background: $dark !important;
	    border-right: 0;
	    a{
		    &.badge {
			    color: $light !important;
			}
		}
		.theme-starter,.theme-skin ,.bg-img {
			li {
				a{
				&.badge {
				    color: $light !important;
				    background: $dark !important;
				    }
				    &:hover {
						color: $light !important;
					    background: $black !important;
					}
				}
			}
		}
		&.full-width {
			background: $dark;
		}
	}
	#theme-settings {
		.fa-cog {
			color: #2196f3;
		}

	}
}
/*------------------------------------
	BACKGROUND SAMPLE
------------------------------------*/
.bg-section {
	background: #83cbf1;
	background: -webkit-linear-gradient(to right,#3b79ce,#83cbf1);
	background: linear-gradient(to right,#3b79ce,#83cbf1);
}
.border-back:after {
	content: "";
	background: #212529;
	width: 4rem;
	height: 2px;
	position: absolute;
	bottom: 2rem;
	left: 50%;
	margin-left: -30px;
}