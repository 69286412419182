/*----------------------------------------------------------------------
    * Bootstrap Customizer
	* Bootstrap Demo Layout and Color Customizer
	* Copyright Ari Budin Bootstrap.News
	* Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*------------------------------------
	Demo Customizer by aribudin www.Bootstrap.News
------------------------------------*/
/*------------------------------------
	General
------------------------------------*/
#switcher, #switchers {
  position: fixed;
  width: 16.5rem;
  top: 7rem;
  right: 0px;
  background: #ffffff;
  padding: 1rem;
  border: 1px solid #dee2e6;
  z-index: 999999999999;
}

#switcher #theme-settings, #switcher #theme-setting, #switchers #theme-settings, #switchers #theme-setting {
  width: 3rem;
  display: block;
  background: #ffffff;
  position: absolute;
  left: -3rem;
  top: -1px;
  border-left: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  font-size: 1.5rem;
  text-align: center;
  padding: 8px;
  color: #666;
}

#switcher #theme-settings i, #switcher #theme-settings img, #switcher #theme-setting i, #switcher #theme-setting img, #switchers #theme-settings i, #switchers #theme-settings img, #switchers #theme-setting i, #switchers #theme-setting img {
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

#switcher .badge, #switchers .badge {
  border-radius: 0;
}

#switcher h1, #switcher h2, #switcher h3, #switcher h4, #switcher h5, #switcher h6, #switcher .h1, #switcher .h2, #switcher .h3, #switcher .h4, #switcher .h5, #switcher .h6, #switchers h1, #switchers h2, #switchers h3, #switchers h4, #switchers h5, #switchers h6, #switchers .h1, #switchers .h2, #switchers .h3, #switchers .h4, #switchers .h5, #switchers .h6 {
  margin-bottom: 0.5rem;
}

#switcher h6, #switchers h6 {
  text-align: center;
}

#switcher ul, #switchers ul {
  text-align: center;
  padding-left: 0;
  clear: both;
  overflow: hidden;
  padding-bottom: 0.5rem;
  margin-bottom: .25rem;
}

#switcher ul li, #switchers ul li {
  display: inline-block;
  list-style: none;
  margin: 2px;
}

#switcher ul li a.badge, #switchers ul li a.badge {
  font-weight: 400;
  min-width: 4rem;
  padding: .4rem;
  color: #1b1b1b;
}

#switcher ul li a.badge:hover, #switchers ul li a.badge:hover {
  background: #f6f6f6;
}

#switcher ul li a.badge-light:hover, #switchers ul li a.badge-light:hover {
  background: transparent !important;
}

#switcher ul.bg-img, #switchers ul.bg-img {
  padding-bottom: 0;
  margin-bottom: 0;
}

#switcher ul.bg-img li, #switchers ul.bg-img li {
  margin-bottom: 10px;
}

#switcher .theme-colors li a.badge, #switcher .second-colors li a.badge, #switchers .theme-colors li a.badge, #switchers .second-colors li a.badge {
  min-width: 2.3rem;
}

#switcher .theme-colors .red, #switcher .second-colors .red, #switchers .theme-colors .red, #switchers .second-colors .red {
  background-color: #d72924 !important;
}

#switcher .theme-colors .purple, #switcher .second-colors .purple, #switchers .theme-colors .purple, #switchers .second-colors .purple {
  background-color: #7952B3 !important;
}

#switcher .theme-colors .pink, #switcher .second-colors .pink, #switchers .theme-colors .pink, #switchers .second-colors .pink {
  background-color: #e83e8c !important;
}

#switcher .theme-colors .orange, #switcher .second-colors .orange, #switchers .theme-colors .orange, #switchers .second-colors .orange {
  background-color: #fa983a !important;
}

#switcher .theme-colors .blue, #switcher .second-colors .blue, #switchers .theme-colors .blue, #switchers .second-colors .blue {
  background-color: #2196f3 !important;
}

#switcher .theme-colors .darkblue, #switcher .second-colors .darkblue, #switchers .theme-colors .darkblue, #switchers .second-colors .darkblue {
  background-color: #07478f !important;
}

#switcher .theme-colors .lightblue, #switcher .second-colors .lightblue, #switchers .theme-colors .lightblue, #switchers .second-colors .lightblue {
  background-color: #1db8fa !important;
}

#switcher .theme-colors .green, #switcher .second-colors .green, #switchers .theme-colors .green, #switchers .second-colors .green {
  background-color: #00b191 !important;
}

#switcher .theme-colors .yellow, #switcher .second-colors .yellow, #switchers .theme-colors .yellow, #switchers .second-colors .yellow {
  background-color: #f4b12c !important;
}

#switcher .theme-colors .blacksoft, #switcher .second-colors .blacksoft, #switchers .theme-colors .blacksoft, #switchers .second-colors .blacksoft {
  background-color: #131313 !important;
}

#switcher .theme-colors .black, #switcher .second-colors .black, #switchers .theme-colors .black, #switchers .second-colors .black {
  background-color: #000000 !important;
}

#switchers {
  position: relative;
  top: 0;
  z-index: 10;
}

.show-tools {
  margin-right: 0px;
}

.hide-tools {
  margin-right: -16.5rem;
}

#colorWell {
  border: #ffffff 0px solid;
  background: #ffffff;
  width: 2.3rem;
  margin: 0;
  height: 1.3rem;
  padding: 0;
}

.bs-component {
  margin-bottom: 1rem;
}

.mobile-back {
  max-width: 430px;
  text-align: center;
  min-height: 920px;
  background: url(../img/iphone.png) no-repeat;
  padding: 103px 0 107px 0px;
}

#mobile-frame, #mobile-frame2 {
  height: 600px;
  width: 375px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 26px;
  padding-left: 20px;
  float: left;
}

#mobile-frame iframe, #mobile-frame2 iframe {
  height: 600px;
  border: none;
  width: 375px;
  box-shadow: 0px 2px 10px #cecece;
}

.dark-skin {
  color: #fff;
}

.dark-skin #switcher, .dark-skin #switchers, .dark-skin #theme-settings {
  background: #1b1b1b !important;
  border-right: 0;
}

.dark-skin #switcher a.badge, .dark-skin #switchers a.badge, .dark-skin #theme-settings a.badge {
  color: #f6f6f6 !important;
}

.dark-skin #switcher .theme-starter li a.badge, .dark-skin #switcher .theme-skin li a.badge, .dark-skin #switcher .bg-img li a.badge, .dark-skin #switchers .theme-starter li a.badge, .dark-skin #switchers .theme-skin li a.badge, .dark-skin #switchers .bg-img li a.badge, .dark-skin #theme-settings .theme-starter li a.badge, .dark-skin #theme-settings .theme-skin li a.badge, .dark-skin #theme-settings .bg-img li a.badge {
  color: #f6f6f6 !important;
  background: #1b1b1b !important;
}

.dark-skin #switcher .theme-starter li a:hover, .dark-skin #switcher .theme-skin li a:hover, .dark-skin #switcher .bg-img li a:hover, .dark-skin #switchers .theme-starter li a:hover, .dark-skin #switchers .theme-skin li a:hover, .dark-skin #switchers .bg-img li a:hover, .dark-skin #theme-settings .theme-starter li a:hover, .dark-skin #theme-settings .theme-skin li a:hover, .dark-skin #theme-settings .bg-img li a:hover {
  color: #f6f6f6 !important;
  background: #000000 !important;
}

.dark-skin #switcher.full-width, .dark-skin #switchers.full-width, .dark-skin #theme-settings.full-width {
  background: #1b1b1b;
}

.dark-skin #theme-settings .fa-cog {
  color: #2196f3;
}

/*------------------------------------
	BACKGROUND SAMPLE
------------------------------------*/
.bg-section {
  background: #83cbf1;
  background: -webkit-linear-gradient(to right, #3b79ce, #83cbf1);
  background: linear-gradient(to right, #3b79ce, #83cbf1);
}

.border-back:after {
  content: "";
  background: #212529;
  width: 4rem;
  height: 2px;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  margin-left: -30px;
}

/*------------------------------------
	Primary color
------------------------------------*/
.color-blue .bg-red, .color-blue .bg-primary, .color-blue .progress-bar, .color-blue .badge-primary {
  background-color: #2196f3 !important;
}

.color-blue .center-backline:before, .color-blue .center-backline-lg:before, .color-blue .title-arrow:before {
  background-color: #2196f3 !important;
}

.color-blue .box-title:before {
  border-top-color: #2196f3;
}

.color-blue .badge-primary {
  color: #ffffff !important;
}

.color-blue .fa-stack-sea {
  border: #2196f3 3px solid;
}

.color-blue .form-control:focus {
  border-color: #2196f3;
}

.color-blue .prev-post-left > a, .color-blue .next-post-right > a {
  color: #2f2e41;
}

.color-blue .prev-post-left > a:hover, .color-blue .next-post-right > a:hover {
  color: #2196f3;
}

.color-blue .hover-a:hover .fa-play-circle {
  background: #2196f3;
  border: #2196f3 2px solid;
}

.color-blue .page-link, .color-blue .btn-link {
  color: #2196f3;
}

.color-blue a.btn {
  color: #ffffff;
}

.color-blue a.btn.back-top {
  color: #000000;
}

.color-blue a.btn.btn-outline-primary {
  color: #2196f3;
}

.color-blue a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-blue a:hover {
  color: #2196f3;
}

.color-blue h1 > a:hover, .color-blue h2 > a:hover, .color-blue h3 > a:hover, .color-blue h4 > a:hover, .color-blue h5 > a:hover, .color-blue h6 > a:hover, .color-blue .h1 > a:hover, .color-blue .h2 > a:hover, .color-blue .h3 > a:hover, .color-blue .h4 > a:hover, .color-blue .h5 > a:hover, .color-blue .h6 > a:hover {
  color: #2196f3;
}

.color-blue .hover-a:hover h1 > a, .color-blue .hover-a:hover h2 > a, .color-blue .hover-a:hover h3 > a, .color-blue .hover-a:hover h4 > a, .color-blue .hover-a:hover h5 > a, .color-blue .hover-a:hover h6 > a, .color-blue .hover-a:hover .h1 > a, .color-blue .hover-a:hover .h2 > a, .color-blue .hover-a:hover .h3 > a, .color-blue .hover-a:hover .h4 > a, .color-blue .hover-a:hover .h5 > a, .color-blue .hover-a:hover .h6 > a, .color-blue .hover-a:hover p > a {
  color: #2196f3;
}

.color-blue .hover-mode .main-nav li:hover > a {
  color: #2196f3;
}

.color-blue .nav-tabs .nav-link.active {
  color: #2196f3;
}

.color-blue .nav-tabs .nav-item.show .nav-link {
  color: #2196f3;
}

.color-blue .nav-item:hover .nav-link, .color-blue .navbar-nav .dropdown-item:hover, .color-blue #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-blue .dropdown-item.active, .color-blue .dropdown-item:active, .color-blue a {
  color: #2196f3;
}

.color-blue .navbar-light .navbar-nav .show > .nav-link, .color-blue .navbar-light .navbar-nav .active > .nav-link, .color-blue .navbar-light .navbar-nav .nav-link.show, .color-blue .navbar-light .navbar-nav .nav-link.active, .color-blue .navbar-light .navbar-nav .nav-link:hover, .color-blue .navbar-light .navbar-nav .nav-link:focus, .color-blue .navbar-light .nav-item:hover .nav-link, .color-blue .navbar-nav .dropdown-item:hover, .color-blue .text-primary {
  color: #2196f3 !important;
}

.color-blue .nav-pills .nav-link.active, .color-blue .nav-pills .show > .nav-link, .color-blue .custom-range::-ms-thumb, .color-blue .custom-range::-moz-range-thumb, .color-blue .custom-range::-webkit-slider-thumb, .color-blue .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-blue .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-blue .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-blue .custom-control-input:checked ~ .custom-control-label::before, .color-blue .dropdown-item.active, .color-blue .dropdown-item:active {
  background-color: #2196f3;
}

.color-blue .dropdown-menu.mega .container, .color-blue .dropdown-menu, .color-blue .border-primary {
  border-color: #2196f3 !important;
}

.color-blue .list-group-item.active, .color-blue .btn-outline-primary {
  color: #2196f3;
  border-color: #2196f3;
}

.color-blue .page-link:hover, .color-blue .page-item.active .page-link, .color-blue .btn-primary, .color-blue .btn-outline-primary:hover, .color-blue .btn-primary:not(:disabled):not(.disabled):active, .color-blue .btn-primary:not(:disabled):not(.disabled).active, .color-blue .show > .btn-primary.dropdown-toggle {
  background-color: #2196f3;
  border-color: #2196f3;
}

.color-blue .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.color-blue .big-nav.carousel-indicators li:hover {
  background-color: #2196f3;
  color: #ffffff;
}

.color-blue .hover-a li:hover a {
  color: #2196f3;
}

.color-blue li:hover a.h5 {
  color: #2196f3;
}

.color-blue .nav-tabs .nav-link.active, .color-blue .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.color-blue .sidetabs .nav-link.active:hover {
  border-color: #2196f3;
}

.color-blue .card-list li:hover a {
  color: #2196f3;
}

.color-blue .nav-block-link li a.nav-link.active, .color-blue .nav-block-link1 li a.nav-link.active, .color-blue .nav-block-link2 li a.nav-link.active, .color-blue .nav-block-link3 li a.nav-link.active, .color-blue .nav-block-link4 li a.nav-link.active, .color-blue .nav-block-link5 li a.nav-link.active, .color-blue .nav-block-link6 li a.nav-link.active, .color-blue .nav-block-link7 li a.nav-link.active, .color-blue .nav-block-more li a.nav-link.active {
  color: #2196f3 !important;
  background-color: transparent;
}

.color-blue .side-link li a:hover, .color-blue .side-link li a.active, .color-blue .sidebar-link li a:hover, .color-blue .sidebar-link li a.active {
  background-color: transparent;
  color: #2196f3;
}

.color-blue .side-link li ul li a:hover, .color-blue .sidebar-link li ul li a:hover {
  color: #2196f3;
}

.color-blue .side-link li a.caret-collapse, .color-blue .sidebar-link li a.caret-collapse {
  color: #2196f3;
}

.color-blue .side-link li a.caret-collapse.collapsed:hover, .color-blue .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #2196f3;
}

.color-blue .side-link li a.caret-collapse.collapsed, .color-blue .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-blue .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-blue .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #2196f3;
}

.color-blue.dark-skin {
  color: #ffffff !important;
}

.color-blue.dark-skin a {
  color: #2196f3;
}

.color-blue.dark-skin a.btn {
  color: #ffffff;
}

.color-blue.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-blue .post-number li:hover a::before {
  color: #2196f3 !important;
}

.color-blue .timeline-post > li a .timeline-date:hover, .color-blue .timeline-post > li a .timeline-title:hover {
  color: #2196f3 !important;
}

.color-blue .timeline-post > li:hover:before {
  background: #2196f3 !important;
}

.color-blue .widget ol li a:hover, .color-blue .widget ul li a:hover {
  color: #2196f3;
}

.color-purple .bg-red, .color-purple .bg-primary, .color-purple .progress-bar, .color-purple .badge-primary {
  background-color: #7952B3 !important;
}

.color-purple .center-backline:before, .color-purple .center-backline-lg:before, .color-purple .title-arrow:before {
  background-color: #7952B3 !important;
}

.color-purple .box-title:before {
  border-top-color: #7952B3;
}

.color-purple .badge-primary {
  color: #ffffff !important;
}

.color-purple .fa-stack-sea {
  border: #7952B3 3px solid;
}

.color-purple .form-control:focus {
  border-color: #7952B3;
}

.color-purple .prev-post-left > a, .color-purple .next-post-right > a {
  color: #2f2e41;
}

.color-purple .prev-post-left > a:hover, .color-purple .next-post-right > a:hover {
  color: #7952B3;
}

.color-purple .hover-a:hover .fa-play-circle {
  background: #7952B3;
  border: #7952B3 2px solid;
}

.color-purple .page-link, .color-purple .btn-link {
  color: #7952B3;
}

.color-purple a.btn {
  color: #ffffff;
}

.color-purple a.btn.back-top {
  color: #000000;
}

.color-purple a.btn.btn-outline-primary {
  color: #7952B3;
}

.color-purple a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-purple a:hover {
  color: #7952B3;
}

.color-purple h1 > a:hover, .color-purple h2 > a:hover, .color-purple h3 > a:hover, .color-purple h4 > a:hover, .color-purple h5 > a:hover, .color-purple h6 > a:hover, .color-purple .h1 > a:hover, .color-purple .h2 > a:hover, .color-purple .h3 > a:hover, .color-purple .h4 > a:hover, .color-purple .h5 > a:hover, .color-purple .h6 > a:hover {
  color: #7952B3;
}

.color-purple .hover-a:hover h1 > a, .color-purple .hover-a:hover h2 > a, .color-purple .hover-a:hover h3 > a, .color-purple .hover-a:hover h4 > a, .color-purple .hover-a:hover h5 > a, .color-purple .hover-a:hover h6 > a, .color-purple .hover-a:hover .h1 > a, .color-purple .hover-a:hover .h2 > a, .color-purple .hover-a:hover .h3 > a, .color-purple .hover-a:hover .h4 > a, .color-purple .hover-a:hover .h5 > a, .color-purple .hover-a:hover .h6 > a, .color-purple .hover-a:hover p > a {
  color: #7952B3;
}

.color-purple .hover-mode .main-nav li:hover > a {
  color: #7952B3;
}

.color-purple .nav-tabs .nav-link.active {
  color: #7952B3;
}

.color-purple .nav-tabs .nav-item.show .nav-link {
  color: #7952B3;
}

.color-purple .nav-item:hover .nav-link, .color-purple .navbar-nav .dropdown-item:hover, .color-purple #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-purple .dropdown-item.active, .color-purple .dropdown-item:active, .color-purple a {
  color: #7952B3;
}

.color-purple .navbar-light .navbar-nav .show > .nav-link, .color-purple .navbar-light .navbar-nav .active > .nav-link, .color-purple .navbar-light .navbar-nav .nav-link.show, .color-purple .navbar-light .navbar-nav .nav-link.active, .color-purple .navbar-light .navbar-nav .nav-link:hover, .color-purple .navbar-light .navbar-nav .nav-link:focus, .color-purple .navbar-light .nav-item:hover .nav-link, .color-purple .navbar-nav .dropdown-item:hover, .color-purple .text-primary {
  color: #7952B3 !important;
}

.color-purple .nav-pills .nav-link.active, .color-purple .nav-pills .show > .nav-link, .color-purple .custom-range::-ms-thumb, .color-purple .custom-range::-moz-range-thumb, .color-purple .custom-range::-webkit-slider-thumb, .color-purple .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-purple .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-purple .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-purple .custom-control-input:checked ~ .custom-control-label::before, .color-purple .dropdown-item.active, .color-purple .dropdown-item:active {
  background-color: #7952B3;
}

.color-purple .dropdown-menu.mega .container, .color-purple .dropdown-menu, .color-purple .border-primary {
  border-color: #7952B3 !important;
}

.color-purple .list-group-item.active, .color-purple .btn-outline-primary {
  color: #7952B3;
  border-color: #7952B3;
}

.color-purple .page-link:hover, .color-purple .page-item.active .page-link, .color-purple .btn-primary, .color-purple .btn-outline-primary:hover, .color-purple .btn-primary:not(:disabled):not(.disabled):active, .color-purple .btn-primary:not(:disabled):not(.disabled).active, .color-purple .show > .btn-primary.dropdown-toggle {
  background-color: #7952B3;
  border-color: #7952B3;
}

.color-purple .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #7952B3;
  border-color: #7952B3;
}

.color-purple .big-nav.carousel-indicators li:hover {
  background-color: #7952B3;
  color: #ffffff;
}

.color-purple .hover-a li:hover a {
  color: #7952B3;
}

.color-purple li:hover a.h5 {
  color: #7952B3;
}

.color-purple .nav-tabs .nav-link.active, .color-purple .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #7952B3;
  border-color: #7952B3;
}

.color-purple .sidetabs .nav-link.active:hover {
  border-color: #7952B3;
}

.color-purple .card-list li:hover a {
  color: #7952B3;
}

.color-purple .nav-block-link li a.nav-link.active, .color-purple .nav-block-link1 li a.nav-link.active, .color-purple .nav-block-link2 li a.nav-link.active, .color-purple .nav-block-link3 li a.nav-link.active, .color-purple .nav-block-link4 li a.nav-link.active, .color-purple .nav-block-link5 li a.nav-link.active, .color-purple .nav-block-link6 li a.nav-link.active, .color-purple .nav-block-link7 li a.nav-link.active, .color-purple .nav-block-more li a.nav-link.active {
  color: #7952B3 !important;
  background-color: transparent;
}

.color-purple .side-link li a:hover, .color-purple .side-link li a.active, .color-purple .sidebar-link li a:hover, .color-purple .sidebar-link li a.active {
  background-color: transparent;
  color: #7952B3;
}

.color-purple .side-link li ul li a:hover, .color-purple .sidebar-link li ul li a:hover {
  color: #7952B3;
}

.color-purple .side-link li a.caret-collapse, .color-purple .sidebar-link li a.caret-collapse {
  color: #7952B3;
}

.color-purple .side-link li a.caret-collapse.collapsed:hover, .color-purple .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #7952B3;
}

.color-purple .side-link li a.caret-collapse.collapsed, .color-purple .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-purple .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-purple .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #7952B3;
}

.color-purple.dark-skin {
  color: #ffffff !important;
}

.color-purple.dark-skin a {
  color: #7952B3;
}

.color-purple.dark-skin a.btn {
  color: #ffffff;
}

.color-purple.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-purple .post-number li:hover a::before {
  color: #7952B3 !important;
}

.color-purple .timeline-post > li a .timeline-date:hover, .color-purple .timeline-post > li a .timeline-title:hover {
  color: #7952B3 !important;
}

.color-purple .timeline-post > li:hover:before {
  background: #7952B3 !important;
}

.color-purple .widget ol li a:hover, .color-purple .widget ul li a:hover {
  color: #7952B3;
}

.color-pink .bg-red, .color-pink .bg-primary, .color-pink .progress-bar, .color-pink .badge-primary {
  background-color: #e83e8c !important;
}

.color-pink .center-backline:before, .color-pink .center-backline-lg:before, .color-pink .title-arrow:before {
  background-color: #e83e8c !important;
}

.color-pink .box-title:before {
  border-top-color: #e83e8c;
}

.color-pink .badge-primary {
  color: #ffffff !important;
}

.color-pink .fa-stack-sea {
  border: #e83e8c 3px solid;
}

.color-pink .form-control:focus {
  border-color: #e83e8c;
}

.color-pink .prev-post-left > a, .color-pink .next-post-right > a {
  color: #2f2e41;
}

.color-pink .prev-post-left > a:hover, .color-pink .next-post-right > a:hover {
  color: #e83e8c;
}

.color-pink .hover-a:hover .fa-play-circle {
  background: #e83e8c;
  border: #e83e8c 2px solid;
}

.color-pink .page-link, .color-pink .btn-link {
  color: #e83e8c;
}

.color-pink a.btn {
  color: #ffffff;
}

.color-pink a.btn.back-top {
  color: #000000;
}

.color-pink a.btn.btn-outline-primary {
  color: #e83e8c;
}

.color-pink a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-pink a:hover {
  color: #e83e8c;
}

.color-pink h1 > a:hover, .color-pink h2 > a:hover, .color-pink h3 > a:hover, .color-pink h4 > a:hover, .color-pink h5 > a:hover, .color-pink h6 > a:hover, .color-pink .h1 > a:hover, .color-pink .h2 > a:hover, .color-pink .h3 > a:hover, .color-pink .h4 > a:hover, .color-pink .h5 > a:hover, .color-pink .h6 > a:hover {
  color: #e83e8c;
}

.color-pink .hover-a:hover h1 > a, .color-pink .hover-a:hover h2 > a, .color-pink .hover-a:hover h3 > a, .color-pink .hover-a:hover h4 > a, .color-pink .hover-a:hover h5 > a, .color-pink .hover-a:hover h6 > a, .color-pink .hover-a:hover .h1 > a, .color-pink .hover-a:hover .h2 > a, .color-pink .hover-a:hover .h3 > a, .color-pink .hover-a:hover .h4 > a, .color-pink .hover-a:hover .h5 > a, .color-pink .hover-a:hover .h6 > a, .color-pink .hover-a:hover p > a {
  color: #e83e8c;
}

.color-pink .hover-mode .main-nav li:hover > a {
  color: #e83e8c;
}

.color-pink .nav-tabs .nav-link.active {
  color: #e83e8c;
}

.color-pink .nav-tabs .nav-item.show .nav-link {
  color: #e83e8c;
}

.color-pink .nav-item:hover .nav-link, .color-pink .navbar-nav .dropdown-item:hover, .color-pink #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-pink .dropdown-item.active, .color-pink .dropdown-item:active, .color-pink a {
  color: #e83e8c;
}

.color-pink .navbar-light .navbar-nav .show > .nav-link, .color-pink .navbar-light .navbar-nav .active > .nav-link, .color-pink .navbar-light .navbar-nav .nav-link.show, .color-pink .navbar-light .navbar-nav .nav-link.active, .color-pink .navbar-light .navbar-nav .nav-link:hover, .color-pink .navbar-light .navbar-nav .nav-link:focus, .color-pink .navbar-light .nav-item:hover .nav-link, .color-pink .navbar-nav .dropdown-item:hover, .color-pink .text-primary {
  color: #e83e8c !important;
}

.color-pink .nav-pills .nav-link.active, .color-pink .nav-pills .show > .nav-link, .color-pink .custom-range::-ms-thumb, .color-pink .custom-range::-moz-range-thumb, .color-pink .custom-range::-webkit-slider-thumb, .color-pink .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-pink .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-pink .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-pink .custom-control-input:checked ~ .custom-control-label::before, .color-pink .dropdown-item.active, .color-pink .dropdown-item:active {
  background-color: #e83e8c;
}

.color-pink .dropdown-menu.mega .container, .color-pink .dropdown-menu, .color-pink .border-primary {
  border-color: #e83e8c !important;
}

.color-pink .list-group-item.active, .color-pink .btn-outline-primary {
  color: #e83e8c;
  border-color: #e83e8c;
}

.color-pink .page-link:hover, .color-pink .page-item.active .page-link, .color-pink .btn-primary, .color-pink .btn-outline-primary:hover, .color-pink .btn-primary:not(:disabled):not(.disabled):active, .color-pink .btn-primary:not(:disabled):not(.disabled).active, .color-pink .show > .btn-primary.dropdown-toggle {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.color-pink .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.color-pink .big-nav.carousel-indicators li:hover {
  background-color: #e83e8c;
  color: #ffffff;
}

.color-pink .hover-a li:hover a {
  color: #e83e8c;
}

.color-pink li:hover a.h5 {
  color: #e83e8c;
}

.color-pink .nav-tabs .nav-link.active, .color-pink .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.color-pink .sidetabs .nav-link.active:hover {
  border-color: #e83e8c;
}

.color-pink .card-list li:hover a {
  color: #e83e8c;
}

.color-pink .nav-block-link li a.nav-link.active, .color-pink .nav-block-link1 li a.nav-link.active, .color-pink .nav-block-link2 li a.nav-link.active, .color-pink .nav-block-link3 li a.nav-link.active, .color-pink .nav-block-link4 li a.nav-link.active, .color-pink .nav-block-link5 li a.nav-link.active, .color-pink .nav-block-link6 li a.nav-link.active, .color-pink .nav-block-link7 li a.nav-link.active, .color-pink .nav-block-more li a.nav-link.active {
  color: #e83e8c !important;
  background-color: transparent;
}

.color-pink .side-link li a:hover, .color-pink .side-link li a.active, .color-pink .sidebar-link li a:hover, .color-pink .sidebar-link li a.active {
  background-color: transparent;
  color: #e83e8c;
}

.color-pink .side-link li ul li a:hover, .color-pink .sidebar-link li ul li a:hover {
  color: #e83e8c;
}

.color-pink .side-link li a.caret-collapse, .color-pink .sidebar-link li a.caret-collapse {
  color: #e83e8c;
}

.color-pink .side-link li a.caret-collapse.collapsed:hover, .color-pink .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #e83e8c;
}

.color-pink .side-link li a.caret-collapse.collapsed, .color-pink .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-pink .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-pink .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #e83e8c;
}

.color-pink.dark-skin {
  color: #ffffff !important;
}

.color-pink.dark-skin a {
  color: #e83e8c;
}

.color-pink.dark-skin a.btn {
  color: #ffffff;
}

.color-pink.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-pink .post-number li:hover a::before {
  color: #e83e8c !important;
}

.color-pink .timeline-post > li a .timeline-date:hover, .color-pink .timeline-post > li a .timeline-title:hover {
  color: #e83e8c !important;
}

.color-pink .timeline-post > li:hover:before {
  background: #e83e8c !important;
}

.color-pink .widget ol li a:hover, .color-pink .widget ul li a:hover {
  color: #e83e8c;
}

.color-red .bg-red, .color-red .bg-primary, .color-red .progress-bar, .color-red .badge-primary {
  background-color: #d72924 !important;
}

.color-red .center-backline:before, .color-red .center-backline-lg:before, .color-red .title-arrow:before {
  background-color: #d72924 !important;
}

.color-red .box-title:before {
  border-top-color: #d72924;
}

.color-red .badge-primary {
  color: #ffffff !important;
}

.color-red .fa-stack-sea {
  border: #d72924 3px solid;
}

.color-red .form-control:focus {
  border-color: #d72924;
}

.color-red .prev-post-left > a, .color-red .next-post-right > a {
  color: #2f2e41;
}

.color-red .prev-post-left > a:hover, .color-red .next-post-right > a:hover {
  color: #d72924;
}

.color-red .hover-a:hover .fa-play-circle {
  background: #d72924;
  border: #d72924 2px solid;
}

.color-red .page-link, .color-red .btn-link {
  color: #d72924;
}

.color-red a.btn {
  color: #ffffff;
}

.color-red a.btn.back-top {
  color: #000000;
}

.color-red a.btn.btn-outline-primary {
  color: #d72924;
}

.color-red a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-red a:hover {
  color: #d72924;
}

.color-red h1 > a:hover, .color-red h2 > a:hover, .color-red h3 > a:hover, .color-red h4 > a:hover, .color-red h5 > a:hover, .color-red h6 > a:hover, .color-red .h1 > a:hover, .color-red .h2 > a:hover, .color-red .h3 > a:hover, .color-red .h4 > a:hover, .color-red .h5 > a:hover, .color-red .h6 > a:hover {
  color: #d72924;
}

.color-red .hover-a:hover h1 > a, .color-red .hover-a:hover h2 > a, .color-red .hover-a:hover h3 > a, .color-red .hover-a:hover h4 > a, .color-red .hover-a:hover h5 > a, .color-red .hover-a:hover h6 > a, .color-red .hover-a:hover .h1 > a, .color-red .hover-a:hover .h2 > a, .color-red .hover-a:hover .h3 > a, .color-red .hover-a:hover .h4 > a, .color-red .hover-a:hover .h5 > a, .color-red .hover-a:hover .h6 > a, .color-red .hover-a:hover p > a {
  color: #d72924;
}

.color-red .hover-mode .main-nav li:hover > a {
  color: #d72924;
}

.color-red .nav-tabs .nav-link.active {
  color: #d72924;
}

.color-red .nav-tabs .nav-item.show .nav-link {
  color: #d72924;
}

.color-red .nav-item:hover .nav-link, .color-red .navbar-nav .dropdown-item:hover, .color-red #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-red .dropdown-item.active, .color-red .dropdown-item:active, .color-red a {
  color: #d72924;
}

.color-red .navbar-light .navbar-nav .show > .nav-link, .color-red .navbar-light .navbar-nav .active > .nav-link, .color-red .navbar-light .navbar-nav .nav-link.show, .color-red .navbar-light .navbar-nav .nav-link.active, .color-red .navbar-light .navbar-nav .nav-link:hover, .color-red .navbar-light .navbar-nav .nav-link:focus, .color-red .navbar-light .nav-item:hover .nav-link, .color-red .navbar-nav .dropdown-item:hover, .color-red .text-primary {
  color: #d72924 !important;
}

.color-red .nav-pills .nav-link.active, .color-red .nav-pills .show > .nav-link, .color-red .custom-range::-ms-thumb, .color-red .custom-range::-moz-range-thumb, .color-red .custom-range::-webkit-slider-thumb, .color-red .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-red .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-red .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-red .custom-control-input:checked ~ .custom-control-label::before, .color-red .dropdown-item.active, .color-red .dropdown-item:active {
  background-color: #d72924;
}

.color-red .dropdown-menu.mega .container, .color-red .dropdown-menu, .color-red .border-primary {
  border-color: #d72924 !important;
}

.color-red .list-group-item.active, .color-red .btn-outline-primary {
  color: #d72924;
  border-color: #d72924;
}

.color-red .page-link:hover, .color-red .page-item.active .page-link, .color-red .btn-primary, .color-red .btn-outline-primary:hover, .color-red .btn-primary:not(:disabled):not(.disabled):active, .color-red .btn-primary:not(:disabled):not(.disabled).active, .color-red .show > .btn-primary.dropdown-toggle {
  background-color: #d72924;
  border-color: #d72924;
}

.color-red .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #d72924;
  border-color: #d72924;
}

.color-red .big-nav.carousel-indicators li:hover {
  background-color: #d72924;
  color: #ffffff;
}

.color-red .hover-a li:hover a {
  color: #d72924;
}

.color-red li:hover a.h5 {
  color: #d72924;
}

.color-red .nav-tabs .nav-link.active, .color-red .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #d72924;
  border-color: #d72924;
}

.color-red .sidetabs .nav-link.active:hover {
  border-color: #d72924;
}

.color-red .card-list li:hover a {
  color: #d72924;
}

.color-red .nav-block-link li a.nav-link.active, .color-red .nav-block-link1 li a.nav-link.active, .color-red .nav-block-link2 li a.nav-link.active, .color-red .nav-block-link3 li a.nav-link.active, .color-red .nav-block-link4 li a.nav-link.active, .color-red .nav-block-link5 li a.nav-link.active, .color-red .nav-block-link6 li a.nav-link.active, .color-red .nav-block-link7 li a.nav-link.active, .color-red .nav-block-more li a.nav-link.active {
  color: #d72924 !important;
  background-color: transparent;
}

.color-red .side-link li a:hover, .color-red .side-link li a.active, .color-red .sidebar-link li a:hover, .color-red .sidebar-link li a.active {
  background-color: transparent;
  color: #d72924;
}

.color-red .side-link li ul li a:hover, .color-red .sidebar-link li ul li a:hover {
  color: #d72924;
}

.color-red .side-link li a.caret-collapse, .color-red .sidebar-link li a.caret-collapse {
  color: #d72924;
}

.color-red .side-link li a.caret-collapse.collapsed:hover, .color-red .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #d72924;
}

.color-red .side-link li a.caret-collapse.collapsed, .color-red .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-red .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-red .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #d72924;
}

.color-red.dark-skin {
  color: #ffffff !important;
}

.color-red.dark-skin a {
  color: #d72924;
}

.color-red.dark-skin a.btn {
  color: #ffffff;
}

.color-red.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-red .post-number li:hover a::before {
  color: #d72924 !important;
}

.color-red .timeline-post > li a .timeline-date:hover, .color-red .timeline-post > li a .timeline-title:hover {
  color: #d72924 !important;
}

.color-red .timeline-post > li:hover:before {
  background: #d72924 !important;
}

.color-red .widget ol li a:hover, .color-red .widget ul li a:hover {
  color: #d72924;
}

.color-orange .bg-red, .color-orange .bg-primary, .color-orange .progress-bar, .color-orange .badge-primary {
  background-color: #fa983a !important;
}

.color-orange .center-backline:before, .color-orange .center-backline-lg:before, .color-orange .title-arrow:before {
  background-color: #fa983a !important;
}

.color-orange .box-title:before {
  border-top-color: #fa983a;
}

.color-orange .badge-primary {
  color: #ffffff !important;
}

.color-orange .fa-stack-sea {
  border: #fa983a 3px solid;
}

.color-orange .form-control:focus {
  border-color: #fa983a;
}

.color-orange .prev-post-left > a, .color-orange .next-post-right > a {
  color: #2f2e41;
}

.color-orange .prev-post-left > a:hover, .color-orange .next-post-right > a:hover {
  color: #fa983a;
}

.color-orange .hover-a:hover .fa-play-circle {
  background: #fa983a;
  border: #fa983a 2px solid;
}

.color-orange .page-link, .color-orange .btn-link {
  color: #fa983a;
}

.color-orange a.btn {
  color: #ffffff;
}

.color-orange a.btn.back-top {
  color: #000000;
}

.color-orange a.btn.btn-outline-primary {
  color: #fa983a;
}

.color-orange a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-orange a:hover {
  color: #fa983a;
}

.color-orange h1 > a:hover, .color-orange h2 > a:hover, .color-orange h3 > a:hover, .color-orange h4 > a:hover, .color-orange h5 > a:hover, .color-orange h6 > a:hover, .color-orange .h1 > a:hover, .color-orange .h2 > a:hover, .color-orange .h3 > a:hover, .color-orange .h4 > a:hover, .color-orange .h5 > a:hover, .color-orange .h6 > a:hover {
  color: #fa983a;
}

.color-orange .hover-a:hover h1 > a, .color-orange .hover-a:hover h2 > a, .color-orange .hover-a:hover h3 > a, .color-orange .hover-a:hover h4 > a, .color-orange .hover-a:hover h5 > a, .color-orange .hover-a:hover h6 > a, .color-orange .hover-a:hover .h1 > a, .color-orange .hover-a:hover .h2 > a, .color-orange .hover-a:hover .h3 > a, .color-orange .hover-a:hover .h4 > a, .color-orange .hover-a:hover .h5 > a, .color-orange .hover-a:hover .h6 > a, .color-orange .hover-a:hover p > a {
  color: #fa983a;
}

.color-orange .hover-mode .main-nav li:hover > a {
  color: #fa983a;
}

.color-orange .nav-tabs .nav-link.active {
  color: #fa983a;
}

.color-orange .nav-tabs .nav-item.show .nav-link {
  color: #fa983a;
}

.color-orange .nav-item:hover .nav-link, .color-orange .navbar-nav .dropdown-item:hover, .color-orange #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-orange .dropdown-item.active, .color-orange .dropdown-item:active, .color-orange a {
  color: #fa983a;
}

.color-orange .navbar-light .navbar-nav .show > .nav-link, .color-orange .navbar-light .navbar-nav .active > .nav-link, .color-orange .navbar-light .navbar-nav .nav-link.show, .color-orange .navbar-light .navbar-nav .nav-link.active, .color-orange .navbar-light .navbar-nav .nav-link:hover, .color-orange .navbar-light .navbar-nav .nav-link:focus, .color-orange .navbar-light .nav-item:hover .nav-link, .color-orange .navbar-nav .dropdown-item:hover, .color-orange .text-primary {
  color: #fa983a !important;
}

.color-orange .nav-pills .nav-link.active, .color-orange .nav-pills .show > .nav-link, .color-orange .custom-range::-ms-thumb, .color-orange .custom-range::-moz-range-thumb, .color-orange .custom-range::-webkit-slider-thumb, .color-orange .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-orange .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-orange .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-orange .custom-control-input:checked ~ .custom-control-label::before, .color-orange .dropdown-item.active, .color-orange .dropdown-item:active {
  background-color: #fa983a;
}

.color-orange .dropdown-menu.mega .container, .color-orange .dropdown-menu, .color-orange .border-primary {
  border-color: #fa983a !important;
}

.color-orange .list-group-item.active, .color-orange .btn-outline-primary {
  color: #fa983a;
  border-color: #fa983a;
}

.color-orange .page-link:hover, .color-orange .page-item.active .page-link, .color-orange .btn-primary, .color-orange .btn-outline-primary:hover, .color-orange .btn-primary:not(:disabled):not(.disabled):active, .color-orange .btn-primary:not(:disabled):not(.disabled).active, .color-orange .show > .btn-primary.dropdown-toggle {
  background-color: #fa983a;
  border-color: #fa983a;
}

.color-orange .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #fa983a;
  border-color: #fa983a;
}

.color-orange .big-nav.carousel-indicators li:hover {
  background-color: #fa983a;
  color: #ffffff;
}

.color-orange .hover-a li:hover a {
  color: #fa983a;
}

.color-orange li:hover a.h5 {
  color: #fa983a;
}

.color-orange .nav-tabs .nav-link.active, .color-orange .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #fa983a;
  border-color: #fa983a;
}

.color-orange .sidetabs .nav-link.active:hover {
  border-color: #fa983a;
}

.color-orange .card-list li:hover a {
  color: #fa983a;
}

.color-orange .nav-block-link li a.nav-link.active, .color-orange .nav-block-link1 li a.nav-link.active, .color-orange .nav-block-link2 li a.nav-link.active, .color-orange .nav-block-link3 li a.nav-link.active, .color-orange .nav-block-link4 li a.nav-link.active, .color-orange .nav-block-link5 li a.nav-link.active, .color-orange .nav-block-link6 li a.nav-link.active, .color-orange .nav-block-link7 li a.nav-link.active, .color-orange .nav-block-more li a.nav-link.active {
  color: #fa983a !important;
  background-color: transparent;
}

.color-orange .side-link li a:hover, .color-orange .side-link li a.active, .color-orange .sidebar-link li a:hover, .color-orange .sidebar-link li a.active {
  background-color: transparent;
  color: #fa983a;
}

.color-orange .side-link li ul li a:hover, .color-orange .sidebar-link li ul li a:hover {
  color: #fa983a;
}

.color-orange .side-link li a.caret-collapse, .color-orange .sidebar-link li a.caret-collapse {
  color: #fa983a;
}

.color-orange .side-link li a.caret-collapse.collapsed:hover, .color-orange .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #fa983a;
}

.color-orange .side-link li a.caret-collapse.collapsed, .color-orange .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-orange .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-orange .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #fa983a;
}

.color-orange.dark-skin {
  color: #ffffff !important;
}

.color-orange.dark-skin a {
  color: #fa983a;
}

.color-orange.dark-skin a.btn {
  color: #ffffff;
}

.color-orange.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-orange .post-number li:hover a::before {
  color: #fa983a !important;
}

.color-orange .timeline-post > li a .timeline-date:hover, .color-orange .timeline-post > li a .timeline-title:hover {
  color: #fa983a !important;
}

.color-orange .timeline-post > li:hover:before {
  background: #fa983a !important;
}

.color-orange .widget ol li a:hover, .color-orange .widget ul li a:hover {
  color: #fa983a;
}

.color-yellow .bg-red, .color-yellow .bg-primary, .color-yellow .progress-bar, .color-yellow .badge-primary {
  background-color: #f4b12c !important;
}

.color-yellow .center-backline:before, .color-yellow .center-backline-lg:before, .color-yellow .title-arrow:before {
  background-color: #f4b12c !important;
}

.color-yellow .box-title:before {
  border-top-color: #f4b12c;
}

.color-yellow .badge-primary {
  color: #ffffff !important;
}

.color-yellow .fa-stack-sea {
  border: #f4b12c 3px solid;
}

.color-yellow .form-control:focus {
  border-color: #f4b12c;
}

.color-yellow .prev-post-left > a, .color-yellow .next-post-right > a {
  color: #2f2e41;
}

.color-yellow .prev-post-left > a:hover, .color-yellow .next-post-right > a:hover {
  color: #f4b12c;
}

.color-yellow .hover-a:hover .fa-play-circle {
  background: #f4b12c;
  border: #f4b12c 2px solid;
}

.color-yellow .page-link, .color-yellow .btn-link {
  color: #f4b12c;
}

.color-yellow a.btn {
  color: #ffffff;
}

.color-yellow a.btn.back-top {
  color: #000000;
}

.color-yellow a.btn.btn-outline-primary {
  color: #f4b12c;
}

.color-yellow a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-yellow a:hover {
  color: #f4b12c;
}

.color-yellow h1 > a:hover, .color-yellow h2 > a:hover, .color-yellow h3 > a:hover, .color-yellow h4 > a:hover, .color-yellow h5 > a:hover, .color-yellow h6 > a:hover, .color-yellow .h1 > a:hover, .color-yellow .h2 > a:hover, .color-yellow .h3 > a:hover, .color-yellow .h4 > a:hover, .color-yellow .h5 > a:hover, .color-yellow .h6 > a:hover {
  color: #f4b12c;
}

.color-yellow .hover-a:hover h1 > a, .color-yellow .hover-a:hover h2 > a, .color-yellow .hover-a:hover h3 > a, .color-yellow .hover-a:hover h4 > a, .color-yellow .hover-a:hover h5 > a, .color-yellow .hover-a:hover h6 > a, .color-yellow .hover-a:hover .h1 > a, .color-yellow .hover-a:hover .h2 > a, .color-yellow .hover-a:hover .h3 > a, .color-yellow .hover-a:hover .h4 > a, .color-yellow .hover-a:hover .h5 > a, .color-yellow .hover-a:hover .h6 > a, .color-yellow .hover-a:hover p > a {
  color: #f4b12c;
}

.color-yellow .hover-mode .main-nav li:hover > a {
  color: #f4b12c;
}

.color-yellow .nav-tabs .nav-link.active {
  color: #f4b12c;
}

.color-yellow .nav-tabs .nav-item.show .nav-link {
  color: #f4b12c;
}

.color-yellow .nav-item:hover .nav-link, .color-yellow .navbar-nav .dropdown-item:hover, .color-yellow #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-yellow .dropdown-item.active, .color-yellow .dropdown-item:active, .color-yellow a {
  color: #f4b12c;
}

.color-yellow .navbar-light .navbar-nav .show > .nav-link, .color-yellow .navbar-light .navbar-nav .active > .nav-link, .color-yellow .navbar-light .navbar-nav .nav-link.show, .color-yellow .navbar-light .navbar-nav .nav-link.active, .color-yellow .navbar-light .navbar-nav .nav-link:hover, .color-yellow .navbar-light .navbar-nav .nav-link:focus, .color-yellow .navbar-light .nav-item:hover .nav-link, .color-yellow .navbar-nav .dropdown-item:hover, .color-yellow .text-primary {
  color: #f4b12c !important;
}

.color-yellow .nav-pills .nav-link.active, .color-yellow .nav-pills .show > .nav-link, .color-yellow .custom-range::-ms-thumb, .color-yellow .custom-range::-moz-range-thumb, .color-yellow .custom-range::-webkit-slider-thumb, .color-yellow .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-yellow .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-yellow .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-yellow .custom-control-input:checked ~ .custom-control-label::before, .color-yellow .dropdown-item.active, .color-yellow .dropdown-item:active {
  background-color: #f4b12c;
}

.color-yellow .dropdown-menu.mega .container, .color-yellow .dropdown-menu, .color-yellow .border-primary {
  border-color: #f4b12c !important;
}

.color-yellow .list-group-item.active, .color-yellow .btn-outline-primary {
  color: #f4b12c;
  border-color: #f4b12c;
}

.color-yellow .page-link:hover, .color-yellow .page-item.active .page-link, .color-yellow .btn-primary, .color-yellow .btn-outline-primary:hover, .color-yellow .btn-primary:not(:disabled):not(.disabled):active, .color-yellow .btn-primary:not(:disabled):not(.disabled).active, .color-yellow .show > .btn-primary.dropdown-toggle {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.color-yellow .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.color-yellow .big-nav.carousel-indicators li:hover {
  background-color: #f4b12c;
  color: #ffffff;
}

.color-yellow .hover-a li:hover a {
  color: #f4b12c;
}

.color-yellow li:hover a.h5 {
  color: #f4b12c;
}

.color-yellow .nav-tabs .nav-link.active, .color-yellow .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.color-yellow .sidetabs .nav-link.active:hover {
  border-color: #f4b12c;
}

.color-yellow .card-list li:hover a {
  color: #f4b12c;
}

.color-yellow .nav-block-link li a.nav-link.active, .color-yellow .nav-block-link1 li a.nav-link.active, .color-yellow .nav-block-link2 li a.nav-link.active, .color-yellow .nav-block-link3 li a.nav-link.active, .color-yellow .nav-block-link4 li a.nav-link.active, .color-yellow .nav-block-link5 li a.nav-link.active, .color-yellow .nav-block-link6 li a.nav-link.active, .color-yellow .nav-block-link7 li a.nav-link.active, .color-yellow .nav-block-more li a.nav-link.active {
  color: #f4b12c !important;
  background-color: transparent;
}

.color-yellow .side-link li a:hover, .color-yellow .side-link li a.active, .color-yellow .sidebar-link li a:hover, .color-yellow .sidebar-link li a.active {
  background-color: transparent;
  color: #f4b12c;
}

.color-yellow .side-link li ul li a:hover, .color-yellow .sidebar-link li ul li a:hover {
  color: #f4b12c;
}

.color-yellow .side-link li a.caret-collapse, .color-yellow .sidebar-link li a.caret-collapse {
  color: #f4b12c;
}

.color-yellow .side-link li a.caret-collapse.collapsed:hover, .color-yellow .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #f4b12c;
}

.color-yellow .side-link li a.caret-collapse.collapsed, .color-yellow .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-yellow .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-yellow .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #f4b12c;
}

.color-yellow.dark-skin {
  color: #ffffff !important;
}

.color-yellow.dark-skin a {
  color: #f4b12c;
}

.color-yellow.dark-skin a.btn {
  color: #ffffff;
}

.color-yellow.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-yellow .post-number li:hover a::before {
  color: #f4b12c !important;
}

.color-yellow .timeline-post > li a .timeline-date:hover, .color-yellow .timeline-post > li a .timeline-title:hover {
  color: #f4b12c !important;
}

.color-yellow .timeline-post > li:hover:before {
  background: #f4b12c !important;
}

.color-yellow .widget ol li a:hover, .color-yellow .widget ul li a:hover {
  color: #f4b12c;
}

.color-green .bg-red, .color-green .bg-primary, .color-green .progress-bar, .color-green .badge-primary {
  background-color: #00b191 !important;
}

.color-green .center-backline:before, .color-green .center-backline-lg:before, .color-green .title-arrow:before {
  background-color: #00b191 !important;
}

.color-green .box-title:before {
  border-top-color: #00b191;
}

.color-green .badge-primary {
  color: #ffffff !important;
}

.color-green .fa-stack-sea {
  border: #00b191 3px solid;
}

.color-green .form-control:focus {
  border-color: #00b191;
}

.color-green .prev-post-left > a, .color-green .next-post-right > a {
  color: #2f2e41;
}

.color-green .prev-post-left > a:hover, .color-green .next-post-right > a:hover {
  color: #00b191;
}

.color-green .hover-a:hover .fa-play-circle {
  background: #00b191;
  border: #00b191 2px solid;
}

.color-green .page-link, .color-green .btn-link {
  color: #00b191;
}

.color-green a.btn {
  color: #ffffff;
}

.color-green a.btn.back-top {
  color: #000000;
}

.color-green a.btn.btn-outline-primary {
  color: #00b191;
}

.color-green a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-green a:hover {
  color: #00b191;
}

.color-green h1 > a:hover, .color-green h2 > a:hover, .color-green h3 > a:hover, .color-green h4 > a:hover, .color-green h5 > a:hover, .color-green h6 > a:hover, .color-green .h1 > a:hover, .color-green .h2 > a:hover, .color-green .h3 > a:hover, .color-green .h4 > a:hover, .color-green .h5 > a:hover, .color-green .h6 > a:hover {
  color: #00b191;
}

.color-green .hover-a:hover h1 > a, .color-green .hover-a:hover h2 > a, .color-green .hover-a:hover h3 > a, .color-green .hover-a:hover h4 > a, .color-green .hover-a:hover h5 > a, .color-green .hover-a:hover h6 > a, .color-green .hover-a:hover .h1 > a, .color-green .hover-a:hover .h2 > a, .color-green .hover-a:hover .h3 > a, .color-green .hover-a:hover .h4 > a, .color-green .hover-a:hover .h5 > a, .color-green .hover-a:hover .h6 > a, .color-green .hover-a:hover p > a {
  color: #00b191;
}

.color-green .hover-mode .main-nav li:hover > a {
  color: #00b191;
}

.color-green .nav-tabs .nav-link.active {
  color: #00b191;
}

.color-green .nav-tabs .nav-item.show .nav-link {
  color: #00b191;
}

.color-green .nav-item:hover .nav-link, .color-green .navbar-nav .dropdown-item:hover, .color-green #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-green .dropdown-item.active, .color-green .dropdown-item:active, .color-green a {
  color: #00b191;
}

.color-green .navbar-light .navbar-nav .show > .nav-link, .color-green .navbar-light .navbar-nav .active > .nav-link, .color-green .navbar-light .navbar-nav .nav-link.show, .color-green .navbar-light .navbar-nav .nav-link.active, .color-green .navbar-light .navbar-nav .nav-link:hover, .color-green .navbar-light .navbar-nav .nav-link:focus, .color-green .navbar-light .nav-item:hover .nav-link, .color-green .navbar-nav .dropdown-item:hover, .color-green .text-primary {
  color: #00b191 !important;
}

.color-green .nav-pills .nav-link.active, .color-green .nav-pills .show > .nav-link, .color-green .custom-range::-ms-thumb, .color-green .custom-range::-moz-range-thumb, .color-green .custom-range::-webkit-slider-thumb, .color-green .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-green .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-green .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-green .custom-control-input:checked ~ .custom-control-label::before, .color-green .dropdown-item.active, .color-green .dropdown-item:active {
  background-color: #00b191;
}

.color-green .dropdown-menu.mega .container, .color-green .dropdown-menu, .color-green .border-primary {
  border-color: #00b191 !important;
}

.color-green .list-group-item.active, .color-green .btn-outline-primary {
  color: #00b191;
  border-color: #00b191;
}

.color-green .page-link:hover, .color-green .page-item.active .page-link, .color-green .btn-primary, .color-green .btn-outline-primary:hover, .color-green .btn-primary:not(:disabled):not(.disabled):active, .color-green .btn-primary:not(:disabled):not(.disabled).active, .color-green .show > .btn-primary.dropdown-toggle {
  background-color: #00b191;
  border-color: #00b191;
}

.color-green .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #00b191;
  border-color: #00b191;
}

.color-green .big-nav.carousel-indicators li:hover {
  background-color: #00b191;
  color: #ffffff;
}

.color-green .hover-a li:hover a {
  color: #00b191;
}

.color-green li:hover a.h5 {
  color: #00b191;
}

.color-green .nav-tabs .nav-link.active, .color-green .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #00b191;
  border-color: #00b191;
}

.color-green .sidetabs .nav-link.active:hover {
  border-color: #00b191;
}

.color-green .card-list li:hover a {
  color: #00b191;
}

.color-green .nav-block-link li a.nav-link.active, .color-green .nav-block-link1 li a.nav-link.active, .color-green .nav-block-link2 li a.nav-link.active, .color-green .nav-block-link3 li a.nav-link.active, .color-green .nav-block-link4 li a.nav-link.active, .color-green .nav-block-link5 li a.nav-link.active, .color-green .nav-block-link6 li a.nav-link.active, .color-green .nav-block-link7 li a.nav-link.active, .color-green .nav-block-more li a.nav-link.active {
  color: #00b191 !important;
  background-color: transparent;
}

.color-green .side-link li a:hover, .color-green .side-link li a.active, .color-green .sidebar-link li a:hover, .color-green .sidebar-link li a.active {
  background-color: transparent;
  color: #00b191;
}

.color-green .side-link li ul li a:hover, .color-green .sidebar-link li ul li a:hover {
  color: #00b191;
}

.color-green .side-link li a.caret-collapse, .color-green .sidebar-link li a.caret-collapse {
  color: #00b191;
}

.color-green .side-link li a.caret-collapse.collapsed:hover, .color-green .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #00b191;
}

.color-green .side-link li a.caret-collapse.collapsed, .color-green .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-green .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-green .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #00b191;
}

.color-green.dark-skin {
  color: #ffffff !important;
}

.color-green.dark-skin a {
  color: #00b191;
}

.color-green.dark-skin a.btn {
  color: #ffffff;
}

.color-green.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-green .post-number li:hover a::before {
  color: #00b191 !important;
}

.color-green .timeline-post > li a .timeline-date:hover, .color-green .timeline-post > li a .timeline-title:hover {
  color: #00b191 !important;
}

.color-green .timeline-post > li:hover:before {
  background: #00b191 !important;
}

.color-green .widget ol li a:hover, .color-green .widget ul li a:hover {
  color: #00b191;
}

.color-black .bg-red, .color-black .bg-primary, .color-black .progress-bar, .color-black .badge-primary {
  background-color: #000000 !important;
}

.color-black .center-backline:before, .color-black .center-backline-lg:before, .color-black .title-arrow:before {
  background-color: #000000 !important;
}

.color-black .box-title:before {
  border-top-color: #000000;
}

.color-black .badge-primary {
  color: #ffffff !important;
}

.color-black .fa-stack-sea {
  border: #000000 3px solid;
}

.color-black .form-control:focus {
  border-color: #000000;
}

.color-black .prev-post-left > a, .color-black .next-post-right > a {
  color: #2f2e41;
}

.color-black .prev-post-left > a:hover, .color-black .next-post-right > a:hover {
  color: #000000;
}

.color-black .hover-a:hover .fa-play-circle {
  background: #000000;
  border: #000000 2px solid;
}

.color-black .page-link, .color-black .btn-link {
  color: #000000;
}

.color-black a.btn {
  color: #ffffff;
}

.color-black a.btn.back-top {
  color: #000000;
}

.color-black a.btn.btn-outline-primary {
  color: #000000;
}

.color-black a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-black a:hover {
  color: #000000;
}

.color-black h1 > a:hover, .color-black h2 > a:hover, .color-black h3 > a:hover, .color-black h4 > a:hover, .color-black h5 > a:hover, .color-black h6 > a:hover, .color-black .h1 > a:hover, .color-black .h2 > a:hover, .color-black .h3 > a:hover, .color-black .h4 > a:hover, .color-black .h5 > a:hover, .color-black .h6 > a:hover {
  color: #000000;
}

.color-black .hover-a:hover h1 > a, .color-black .hover-a:hover h2 > a, .color-black .hover-a:hover h3 > a, .color-black .hover-a:hover h4 > a, .color-black .hover-a:hover h5 > a, .color-black .hover-a:hover h6 > a, .color-black .hover-a:hover .h1 > a, .color-black .hover-a:hover .h2 > a, .color-black .hover-a:hover .h3 > a, .color-black .hover-a:hover .h4 > a, .color-black .hover-a:hover .h5 > a, .color-black .hover-a:hover .h6 > a, .color-black .hover-a:hover p > a {
  color: #000000;
}

.color-black .hover-mode .main-nav li:hover > a {
  color: #000000;
}

.color-black .nav-tabs .nav-link.active {
  color: #000000;
}

.color-black .nav-tabs .nav-item.show .nav-link {
  color: #000000;
}

.color-black .nav-item:hover .nav-link, .color-black .navbar-nav .dropdown-item:hover, .color-black #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-black .dropdown-item.active, .color-black .dropdown-item:active, .color-black a {
  color: #000000;
}

.color-black .navbar-light .navbar-nav .show > .nav-link, .color-black .navbar-light .navbar-nav .active > .nav-link, .color-black .navbar-light .navbar-nav .nav-link.show, .color-black .navbar-light .navbar-nav .nav-link.active, .color-black .navbar-light .navbar-nav .nav-link:hover, .color-black .navbar-light .navbar-nav .nav-link:focus, .color-black .navbar-light .nav-item:hover .nav-link, .color-black .navbar-nav .dropdown-item:hover, .color-black .text-primary {
  color: #000000 !important;
}

.color-black .nav-pills .nav-link.active, .color-black .nav-pills .show > .nav-link, .color-black .custom-range::-ms-thumb, .color-black .custom-range::-moz-range-thumb, .color-black .custom-range::-webkit-slider-thumb, .color-black .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-black .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-black .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-black .custom-control-input:checked ~ .custom-control-label::before, .color-black .dropdown-item.active, .color-black .dropdown-item:active {
  background-color: #000000;
}

.color-black .dropdown-menu.mega .container, .color-black .dropdown-menu, .color-black .border-primary {
  border-color: #000000 !important;
}

.color-black .list-group-item.active, .color-black .btn-outline-primary {
  color: #000000;
  border-color: #000000;
}

.color-black .page-link:hover, .color-black .page-item.active .page-link, .color-black .btn-primary, .color-black .btn-outline-primary:hover, .color-black .btn-primary:not(:disabled):not(.disabled):active, .color-black .btn-primary:not(:disabled):not(.disabled).active, .color-black .show > .btn-primary.dropdown-toggle {
  background-color: #000000;
  border-color: #000000;
}

.color-black .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.color-black .big-nav.carousel-indicators li:hover {
  background-color: #000000;
  color: #ffffff;
}

.color-black .hover-a li:hover a {
  color: #000000;
}

.color-black li:hover a.h5 {
  color: #000000;
}

.color-black .nav-tabs .nav-link.active, .color-black .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.color-black .sidetabs .nav-link.active:hover {
  border-color: #000000;
}

.color-black .card-list li:hover a {
  color: #000000;
}

.color-black .nav-block-link li a.nav-link.active, .color-black .nav-block-link1 li a.nav-link.active, .color-black .nav-block-link2 li a.nav-link.active, .color-black .nav-block-link3 li a.nav-link.active, .color-black .nav-block-link4 li a.nav-link.active, .color-black .nav-block-link5 li a.nav-link.active, .color-black .nav-block-link6 li a.nav-link.active, .color-black .nav-block-link7 li a.nav-link.active, .color-black .nav-block-more li a.nav-link.active {
  color: #000000 !important;
  background-color: transparent;
}

.color-black .side-link li a:hover, .color-black .side-link li a.active, .color-black .sidebar-link li a:hover, .color-black .sidebar-link li a.active {
  background-color: transparent;
  color: #000000;
}

.color-black .side-link li ul li a:hover, .color-black .sidebar-link li ul li a:hover {
  color: #000000;
}

.color-black .side-link li a.caret-collapse, .color-black .sidebar-link li a.caret-collapse {
  color: #000000;
}

.color-black .side-link li a.caret-collapse.collapsed:hover, .color-black .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #000000;
}

.color-black .side-link li a.caret-collapse.collapsed, .color-black .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-black .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-black .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #000000;
}

.color-black.dark-skin {
  color: #ffffff !important;
}

.color-black.dark-skin a {
  color: #000000;
}

.color-black.dark-skin a.btn {
  color: #ffffff;
}

.color-black.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-black .post-number li:hover a::before {
  color: #000000 !important;
}

.color-black .timeline-post > li a .timeline-date:hover, .color-black .timeline-post > li a .timeline-title:hover {
  color: #000000 !important;
}

.color-black .timeline-post > li:hover:before {
  background: #000000 !important;
}

.color-black .widget ol li a:hover, .color-black .widget ul li a:hover {
  color: #000000;
}

.color-darkblue .bg-red, .color-darkblue .bg-primary, .color-darkblue .progress-bar, .color-darkblue .badge-primary {
  background-color: #07478f !important;
}

.color-darkblue .center-backline:before, .color-darkblue .center-backline-lg:before, .color-darkblue .title-arrow:before {
  background-color: #07478f !important;
}

.color-darkblue .box-title:before {
  border-top-color: #07478f;
}

.color-darkblue .badge-primary {
  color: #ffffff !important;
}

.color-darkblue .fa-stack-sea {
  border: #07478f 3px solid;
}

.color-darkblue .form-control:focus {
  border-color: #07478f;
}

.color-darkblue .prev-post-left > a, .color-darkblue .next-post-right > a {
  color: #2f2e41;
}

.color-darkblue .prev-post-left > a:hover, .color-darkblue .next-post-right > a:hover {
  color: #07478f;
}

.color-darkblue .hover-a:hover .fa-play-circle {
  background: #07478f;
  border: #07478f 2px solid;
}

.color-darkblue .page-link, .color-darkblue .btn-link {
  color: #07478f;
}

.color-darkblue a.btn {
  color: #ffffff;
}

.color-darkblue a.btn.back-top {
  color: #000000;
}

.color-darkblue a.btn.btn-outline-primary {
  color: #07478f;
}

.color-darkblue a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-darkblue a:hover {
  color: #07478f;
}

.color-darkblue h1 > a:hover, .color-darkblue h2 > a:hover, .color-darkblue h3 > a:hover, .color-darkblue h4 > a:hover, .color-darkblue h5 > a:hover, .color-darkblue h6 > a:hover, .color-darkblue .h1 > a:hover, .color-darkblue .h2 > a:hover, .color-darkblue .h3 > a:hover, .color-darkblue .h4 > a:hover, .color-darkblue .h5 > a:hover, .color-darkblue .h6 > a:hover {
  color: #07478f;
}

.color-darkblue .hover-a:hover h1 > a, .color-darkblue .hover-a:hover h2 > a, .color-darkblue .hover-a:hover h3 > a, .color-darkblue .hover-a:hover h4 > a, .color-darkblue .hover-a:hover h5 > a, .color-darkblue .hover-a:hover h6 > a, .color-darkblue .hover-a:hover .h1 > a, .color-darkblue .hover-a:hover .h2 > a, .color-darkblue .hover-a:hover .h3 > a, .color-darkblue .hover-a:hover .h4 > a, .color-darkblue .hover-a:hover .h5 > a, .color-darkblue .hover-a:hover .h6 > a, .color-darkblue .hover-a:hover p > a {
  color: #07478f;
}

.color-darkblue .hover-mode .main-nav li:hover > a {
  color: #07478f;
}

.color-darkblue .nav-tabs .nav-link.active {
  color: #07478f;
}

.color-darkblue .nav-tabs .nav-item.show .nav-link {
  color: #07478f;
}

.color-darkblue .nav-item:hover .nav-link, .color-darkblue .navbar-nav .dropdown-item:hover, .color-darkblue #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-darkblue .dropdown-item.active, .color-darkblue .dropdown-item:active, .color-darkblue a {
  color: #07478f;
}

.color-darkblue .navbar-light .navbar-nav .show > .nav-link, .color-darkblue .navbar-light .navbar-nav .active > .nav-link, .color-darkblue .navbar-light .navbar-nav .nav-link.show, .color-darkblue .navbar-light .navbar-nav .nav-link.active, .color-darkblue .navbar-light .navbar-nav .nav-link:hover, .color-darkblue .navbar-light .navbar-nav .nav-link:focus, .color-darkblue .navbar-light .nav-item:hover .nav-link, .color-darkblue .navbar-nav .dropdown-item:hover, .color-darkblue .text-primary {
  color: #07478f !important;
}

.color-darkblue .nav-pills .nav-link.active, .color-darkblue .nav-pills .show > .nav-link, .color-darkblue .custom-range::-ms-thumb, .color-darkblue .custom-range::-moz-range-thumb, .color-darkblue .custom-range::-webkit-slider-thumb, .color-darkblue .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-darkblue .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-darkblue .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-darkblue .custom-control-input:checked ~ .custom-control-label::before, .color-darkblue .dropdown-item.active, .color-darkblue .dropdown-item:active {
  background-color: #07478f;
}

.color-darkblue .dropdown-menu.mega .container, .color-darkblue .dropdown-menu, .color-darkblue .border-primary {
  border-color: #07478f !important;
}

.color-darkblue .list-group-item.active, .color-darkblue .btn-outline-primary {
  color: #07478f;
  border-color: #07478f;
}

.color-darkblue .page-link:hover, .color-darkblue .page-item.active .page-link, .color-darkblue .btn-primary, .color-darkblue .btn-outline-primary:hover, .color-darkblue .btn-primary:not(:disabled):not(.disabled):active, .color-darkblue .btn-primary:not(:disabled):not(.disabled).active, .color-darkblue .show > .btn-primary.dropdown-toggle {
  background-color: #07478f;
  border-color: #07478f;
}

.color-darkblue .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #07478f;
  border-color: #07478f;
}

.color-darkblue .big-nav.carousel-indicators li:hover {
  background-color: #07478f;
  color: #ffffff;
}

.color-darkblue .hover-a li:hover a {
  color: #07478f;
}

.color-darkblue li:hover a.h5 {
  color: #07478f;
}

.color-darkblue .nav-tabs .nav-link.active, .color-darkblue .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #07478f;
  border-color: #07478f;
}

.color-darkblue .sidetabs .nav-link.active:hover {
  border-color: #07478f;
}

.color-darkblue .card-list li:hover a {
  color: #07478f;
}

.color-darkblue .nav-block-link li a.nav-link.active, .color-darkblue .nav-block-link1 li a.nav-link.active, .color-darkblue .nav-block-link2 li a.nav-link.active, .color-darkblue .nav-block-link3 li a.nav-link.active, .color-darkblue .nav-block-link4 li a.nav-link.active, .color-darkblue .nav-block-link5 li a.nav-link.active, .color-darkblue .nav-block-link6 li a.nav-link.active, .color-darkblue .nav-block-link7 li a.nav-link.active, .color-darkblue .nav-block-more li a.nav-link.active {
  color: #07478f !important;
  background-color: transparent;
}

.color-darkblue .side-link li a:hover, .color-darkblue .side-link li a.active, .color-darkblue .sidebar-link li a:hover, .color-darkblue .sidebar-link li a.active {
  background-color: transparent;
  color: #07478f;
}

.color-darkblue .side-link li ul li a:hover, .color-darkblue .sidebar-link li ul li a:hover {
  color: #07478f;
}

.color-darkblue .side-link li a.caret-collapse, .color-darkblue .sidebar-link li a.caret-collapse {
  color: #07478f;
}

.color-darkblue .side-link li a.caret-collapse.collapsed:hover, .color-darkblue .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #07478f;
}

.color-darkblue .side-link li a.caret-collapse.collapsed, .color-darkblue .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #07478f;
}

.color-darkblue.dark-skin {
  color: #ffffff !important;
}

.color-darkblue.dark-skin a {
  color: #07478f;
}

.color-darkblue.dark-skin a.btn {
  color: #ffffff;
}

.color-darkblue.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-darkblue .post-number li:hover a::before {
  color: #07478f !important;
}

.color-darkblue .timeline-post > li a .timeline-date:hover, .color-darkblue .timeline-post > li a .timeline-title:hover {
  color: #07478f !important;
}

.color-darkblue .timeline-post > li:hover:before {
  background: #07478f !important;
}

.color-darkblue .widget ol li a:hover, .color-darkblue .widget ul li a:hover {
  color: #07478f;
}

.color-lightblue .bg-red, .color-lightblue .bg-primary, .color-lightblue .progress-bar, .color-lightblue .badge-primary {
  background-color: #1db8fa !important;
}

.color-lightblue .center-backline:before, .color-lightblue .center-backline-lg:before, .color-lightblue .title-arrow:before {
  background-color: #1db8fa !important;
}

.color-lightblue .box-title:before {
  border-top-color: #1db8fa;
}

.color-lightblue .badge-primary {
  color: #ffffff !important;
}

.color-lightblue .fa-stack-sea {
  border: #1db8fa 3px solid;
}

.color-lightblue .form-control:focus {
  border-color: #1db8fa;
}

.color-lightblue .prev-post-left > a, .color-lightblue .next-post-right > a {
  color: #2f2e41;
}

.color-lightblue .prev-post-left > a:hover, .color-lightblue .next-post-right > a:hover {
  color: #1db8fa;
}

.color-lightblue .hover-a:hover .fa-play-circle {
  background: #1db8fa;
  border: #1db8fa 2px solid;
}

.color-lightblue .page-link, .color-lightblue .btn-link {
  color: #1db8fa;
}

.color-lightblue a.btn {
  color: #ffffff;
}

.color-lightblue a.btn.back-top {
  color: #000000;
}

.color-lightblue a.btn.btn-outline-primary {
  color: #1db8fa;
}

.color-lightblue a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-lightblue a:hover {
  color: #1db8fa;
}

.color-lightblue h1 > a:hover, .color-lightblue h2 > a:hover, .color-lightblue h3 > a:hover, .color-lightblue h4 > a:hover, .color-lightblue h5 > a:hover, .color-lightblue h6 > a:hover, .color-lightblue .h1 > a:hover, .color-lightblue .h2 > a:hover, .color-lightblue .h3 > a:hover, .color-lightblue .h4 > a:hover, .color-lightblue .h5 > a:hover, .color-lightblue .h6 > a:hover {
  color: #1db8fa;
}

.color-lightblue .hover-a:hover h1 > a, .color-lightblue .hover-a:hover h2 > a, .color-lightblue .hover-a:hover h3 > a, .color-lightblue .hover-a:hover h4 > a, .color-lightblue .hover-a:hover h5 > a, .color-lightblue .hover-a:hover h6 > a, .color-lightblue .hover-a:hover .h1 > a, .color-lightblue .hover-a:hover .h2 > a, .color-lightblue .hover-a:hover .h3 > a, .color-lightblue .hover-a:hover .h4 > a, .color-lightblue .hover-a:hover .h5 > a, .color-lightblue .hover-a:hover .h6 > a, .color-lightblue .hover-a:hover p > a {
  color: #1db8fa;
}

.color-lightblue .hover-mode .main-nav li:hover > a {
  color: #1db8fa;
}

.color-lightblue .nav-tabs .nav-link.active {
  color: #1db8fa;
}

.color-lightblue .nav-tabs .nav-item.show .nav-link {
  color: #1db8fa;
}

.color-lightblue .nav-item:hover .nav-link, .color-lightblue .navbar-nav .dropdown-item:hover, .color-lightblue #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-lightblue .dropdown-item.active, .color-lightblue .dropdown-item:active, .color-lightblue a {
  color: #1db8fa;
}

.color-lightblue .navbar-light .navbar-nav .show > .nav-link, .color-lightblue .navbar-light .navbar-nav .active > .nav-link, .color-lightblue .navbar-light .navbar-nav .nav-link.show, .color-lightblue .navbar-light .navbar-nav .nav-link.active, .color-lightblue .navbar-light .navbar-nav .nav-link:hover, .color-lightblue .navbar-light .navbar-nav .nav-link:focus, .color-lightblue .navbar-light .nav-item:hover .nav-link, .color-lightblue .navbar-nav .dropdown-item:hover, .color-lightblue .text-primary {
  color: #1db8fa !important;
}

.color-lightblue .nav-pills .nav-link.active, .color-lightblue .nav-pills .show > .nav-link, .color-lightblue .custom-range::-ms-thumb, .color-lightblue .custom-range::-moz-range-thumb, .color-lightblue .custom-range::-webkit-slider-thumb, .color-lightblue .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-lightblue .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-lightblue .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-lightblue .custom-control-input:checked ~ .custom-control-label::before, .color-lightblue .dropdown-item.active, .color-lightblue .dropdown-item:active {
  background-color: #1db8fa;
}

.color-lightblue .dropdown-menu.mega .container, .color-lightblue .dropdown-menu, .color-lightblue .border-primary {
  border-color: #1db8fa !important;
}

.color-lightblue .list-group-item.active, .color-lightblue .btn-outline-primary {
  color: #1db8fa;
  border-color: #1db8fa;
}

.color-lightblue .page-link:hover, .color-lightblue .page-item.active .page-link, .color-lightblue .btn-primary, .color-lightblue .btn-outline-primary:hover, .color-lightblue .btn-primary:not(:disabled):not(.disabled):active, .color-lightblue .btn-primary:not(:disabled):not(.disabled).active, .color-lightblue .show > .btn-primary.dropdown-toggle {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.color-lightblue .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.color-lightblue .big-nav.carousel-indicators li:hover {
  background-color: #1db8fa;
  color: #ffffff;
}

.color-lightblue .hover-a li:hover a {
  color: #1db8fa;
}

.color-lightblue li:hover a.h5 {
  color: #1db8fa;
}

.color-lightblue .nav-tabs .nav-link.active, .color-lightblue .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.color-lightblue .sidetabs .nav-link.active:hover {
  border-color: #1db8fa;
}

.color-lightblue .card-list li:hover a {
  color: #1db8fa;
}

.color-lightblue .nav-block-link li a.nav-link.active, .color-lightblue .nav-block-link1 li a.nav-link.active, .color-lightblue .nav-block-link2 li a.nav-link.active, .color-lightblue .nav-block-link3 li a.nav-link.active, .color-lightblue .nav-block-link4 li a.nav-link.active, .color-lightblue .nav-block-link5 li a.nav-link.active, .color-lightblue .nav-block-link6 li a.nav-link.active, .color-lightblue .nav-block-link7 li a.nav-link.active, .color-lightblue .nav-block-more li a.nav-link.active {
  color: #1db8fa !important;
  background-color: transparent;
}

.color-lightblue .side-link li a:hover, .color-lightblue .side-link li a.active, .color-lightblue .sidebar-link li a:hover, .color-lightblue .sidebar-link li a.active {
  background-color: transparent;
  color: #1db8fa;
}

.color-lightblue .side-link li ul li a:hover, .color-lightblue .sidebar-link li ul li a:hover {
  color: #1db8fa;
}

.color-lightblue .side-link li a.caret-collapse, .color-lightblue .sidebar-link li a.caret-collapse {
  color: #1db8fa;
}

.color-lightblue .side-link li a.caret-collapse.collapsed:hover, .color-lightblue .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #1db8fa;
}

.color-lightblue .side-link li a.caret-collapse.collapsed, .color-lightblue .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #1db8fa;
}

.color-lightblue.dark-skin {
  color: #ffffff !important;
}

.color-lightblue.dark-skin a {
  color: #1db8fa;
}

.color-lightblue.dark-skin a.btn {
  color: #ffffff;
}

.color-lightblue.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-lightblue .post-number li:hover a::before {
  color: #1db8fa !important;
}

.color-lightblue .timeline-post > li a .timeline-date:hover, .color-lightblue .timeline-post > li a .timeline-title:hover {
  color: #1db8fa !important;
}

.color-lightblue .timeline-post > li:hover:before {
  background: #1db8fa !important;
}

.color-lightblue .widget ol li a:hover, .color-lightblue .widget ul li a:hover {
  color: #1db8fa;
}

.color-blacksoft .bg-red, .color-blacksoft .bg-primary, .color-blacksoft .progress-bar, .color-blacksoft .badge-primary {
  background-color: #131313 !important;
}

.color-blacksoft .center-backline:before, .color-blacksoft .center-backline-lg:before, .color-blacksoft .title-arrow:before {
  background-color: #131313 !important;
}

.color-blacksoft .box-title:before {
  border-top-color: #131313;
}

.color-blacksoft .badge-primary {
  color: #ffffff !important;
}

.color-blacksoft .fa-stack-sea {
  border: #131313 3px solid;
}

.color-blacksoft .form-control:focus {
  border-color: #131313;
}

.color-blacksoft .prev-post-left > a, .color-blacksoft .next-post-right > a {
  color: #2f2e41;
}

.color-blacksoft .prev-post-left > a:hover, .color-blacksoft .next-post-right > a:hover {
  color: #131313;
}

.color-blacksoft .hover-a:hover .fa-play-circle {
  background: #131313;
  border: #131313 2px solid;
}

.color-blacksoft .page-link, .color-blacksoft .btn-link {
  color: #131313;
}

.color-blacksoft a.btn {
  color: #ffffff;
}

.color-blacksoft a.btn.back-top {
  color: #000000;
}

.color-blacksoft a.btn.btn-outline-primary {
  color: #131313;
}

.color-blacksoft a.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.color-blacksoft a:hover {
  color: #131313;
}

.color-blacksoft h1 > a:hover, .color-blacksoft h2 > a:hover, .color-blacksoft h3 > a:hover, .color-blacksoft h4 > a:hover, .color-blacksoft h5 > a:hover, .color-blacksoft h6 > a:hover, .color-blacksoft .h1 > a:hover, .color-blacksoft .h2 > a:hover, .color-blacksoft .h3 > a:hover, .color-blacksoft .h4 > a:hover, .color-blacksoft .h5 > a:hover, .color-blacksoft .h6 > a:hover {
  color: #131313;
}

.color-blacksoft .hover-a:hover h1 > a, .color-blacksoft .hover-a:hover h2 > a, .color-blacksoft .hover-a:hover h3 > a, .color-blacksoft .hover-a:hover h4 > a, .color-blacksoft .hover-a:hover h5 > a, .color-blacksoft .hover-a:hover h6 > a, .color-blacksoft .hover-a:hover .h1 > a, .color-blacksoft .hover-a:hover .h2 > a, .color-blacksoft .hover-a:hover .h3 > a, .color-blacksoft .hover-a:hover .h4 > a, .color-blacksoft .hover-a:hover .h5 > a, .color-blacksoft .hover-a:hover .h6 > a, .color-blacksoft .hover-a:hover p > a {
  color: #131313;
}

.color-blacksoft .hover-mode .main-nav li:hover > a {
  color: #131313;
}

.color-blacksoft .nav-tabs .nav-link.active {
  color: #131313;
}

.color-blacksoft .nav-tabs .nav-item.show .nav-link {
  color: #131313;
}

.color-blacksoft .nav-item:hover .nav-link, .color-blacksoft .navbar-nav .dropdown-item:hover, .color-blacksoft #main-menu .vertical-tabs .nav-tabs .nav-link.active, .color-blacksoft .dropdown-item.active, .color-blacksoft .dropdown-item:active, .color-blacksoft a {
  color: #131313;
}

.color-blacksoft .navbar-light .navbar-nav .show > .nav-link, .color-blacksoft .navbar-light .navbar-nav .active > .nav-link, .color-blacksoft .navbar-light .navbar-nav .nav-link.show, .color-blacksoft .navbar-light .navbar-nav .nav-link.active, .color-blacksoft .navbar-light .navbar-nav .nav-link:hover, .color-blacksoft .navbar-light .navbar-nav .nav-link:focus, .color-blacksoft .navbar-light .nav-item:hover .nav-link, .color-blacksoft .navbar-nav .dropdown-item:hover, .color-blacksoft .text-primary {
  color: #131313 !important;
}

.color-blacksoft .nav-pills .nav-link.active, .color-blacksoft .nav-pills .show > .nav-link, .color-blacksoft .custom-range::-ms-thumb, .color-blacksoft .custom-range::-moz-range-thumb, .color-blacksoft .custom-range::-webkit-slider-thumb, .color-blacksoft .custom-radio .custom-control-input:checked ~ .custom-control-label::before, .color-blacksoft .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .color-blacksoft .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .color-blacksoft .custom-control-input:checked ~ .custom-control-label::before, .color-blacksoft .dropdown-item.active, .color-blacksoft .dropdown-item:active {
  background-color: #131313;
}

.color-blacksoft .dropdown-menu.mega .container, .color-blacksoft .dropdown-menu, .color-blacksoft .border-primary {
  border-color: #131313 !important;
}

.color-blacksoft .list-group-item.active, .color-blacksoft .btn-outline-primary {
  color: #131313;
  border-color: #131313;
}

.color-blacksoft .page-link:hover, .color-blacksoft .page-item.active .page-link, .color-blacksoft .btn-primary, .color-blacksoft .btn-outline-primary:hover, .color-blacksoft .btn-primary:not(:disabled):not(.disabled):active, .color-blacksoft .btn-primary:not(:disabled):not(.disabled).active, .color-blacksoft .show > .btn-primary.dropdown-toggle {
  background-color: #131313;
  border-color: #131313;
}

.color-blacksoft .big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #131313;
  border-color: #131313;
}

.color-blacksoft .big-nav.carousel-indicators li:hover {
  background-color: #131313;
  color: #ffffff;
}

.color-blacksoft .hover-a li:hover a {
  color: #131313;
}

.color-blacksoft li:hover a.h5 {
  color: #131313;
}

.color-blacksoft .nav-tabs .nav-link.active, .color-blacksoft .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #131313;
  border-color: #131313;
}

.color-blacksoft .sidetabs .nav-link.active:hover {
  border-color: #131313;
}

.color-blacksoft .card-list li:hover a {
  color: #131313;
}

.color-blacksoft .nav-block-link li a.nav-link.active, .color-blacksoft .nav-block-link1 li a.nav-link.active, .color-blacksoft .nav-block-link2 li a.nav-link.active, .color-blacksoft .nav-block-link3 li a.nav-link.active, .color-blacksoft .nav-block-link4 li a.nav-link.active, .color-blacksoft .nav-block-link5 li a.nav-link.active, .color-blacksoft .nav-block-link6 li a.nav-link.active, .color-blacksoft .nav-block-link7 li a.nav-link.active, .color-blacksoft .nav-block-more li a.nav-link.active {
  color: #131313 !important;
  background-color: transparent;
}

.color-blacksoft .side-link li a:hover, .color-blacksoft .side-link li a.active, .color-blacksoft .sidebar-link li a:hover, .color-blacksoft .sidebar-link li a.active {
  background-color: transparent;
  color: #131313;
}

.color-blacksoft .side-link li ul li a:hover, .color-blacksoft .sidebar-link li ul li a:hover {
  color: #131313;
}

.color-blacksoft .side-link li a.caret-collapse, .color-blacksoft .sidebar-link li a.caret-collapse {
  color: #131313;
}

.color-blacksoft .side-link li a.caret-collapse.collapsed:hover, .color-blacksoft .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #131313;
}

.color-blacksoft .side-link li a.caret-collapse.collapsed, .color-blacksoft .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item.active, .color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item:hover {
  background: #131313;
}

.color-blacksoft.dark-skin {
  color: #ffffff !important;
}

.color-blacksoft.dark-skin a {
  color: #131313;
}

.color-blacksoft.dark-skin a.btn {
  color: #ffffff;
}

.color-blacksoft.dark-skin a .text-white {
  color: #ffffff !important;
}

.color-blacksoft .post-number li:hover a::before {
  color: #131313 !important;
}

.color-blacksoft .timeline-post > li a .timeline-date:hover, .color-blacksoft .timeline-post > li a .timeline-title:hover {
  color: #131313 !important;
}

.color-blacksoft .timeline-post > li:hover:before {
  background: #131313 !important;
}

.color-blacksoft .widget ol li a:hover, .color-blacksoft .widget ul li a:hover {
  color: #131313;
}

/*------------------------------------
	Secondary color
------------------------------------*/
.scolor-blue .bg-secondary {
  background-color: #2196f3 !important;
}

.scolor-blue .btn-secondary {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .btn-secondary:hover {
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .btn-secondary.disabled, .scolor-blue .btn-secondary:disabled {
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .btn-secondary:not(:disabled):not(.disabled):active, .scolor-blue .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-blue .show > .btn-secondary.dropdown-toggle {
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .btn-outline-secondary {
  color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .btn-outline-secondary:hover {
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .btn-outline-secondary.disabled, .scolor-blue .btn-outline-secondary:disabled {
  color: #2196f3;
}

.scolor-blue .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-blue .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-blue .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue .badge-secondary {
  color: #ffffff;
  background-color: #2196f3;
}

.scolor-blue .badge-secondary[href]:hover, .scolor-blue .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #2196f3;
}

.scolor-blue .alert-secondary .alert-link {
  color: #2196f3;
}

.scolor-blue .list-group-item-secondary {
  color: #2196f3;
}

.scolor-blue .list-group-item-secondary.list-group-item-action:hover, .scolor-blue .list-group-item-secondary.list-group-item-action:focus {
  color: #2196f3;
}

.scolor-blue .list-group-item-secondary.list-group-item-action.active {
  background-color: #2196f3;
  border-color: #2196f3;
}

.scolor-blue a.bg-secondary:hover, .scolor-blue a.bg-secondary:focus,
.scolor-blue button.bg-secondary:hover,
.scolor-blue button.bg-secondary:focus {
  background-color: #2196f3 !important;
}

.scolor-blue .border-secondary {
  border-color: #2196f3 !important;
}

.scolor-blue .text-secondary {
  color: #2196f3 !important;
}

.scolor-blue a.text-secondary:hover, .scolor-blue a.text-secondary:focus {
  color: #2196f3 !important;
}

.scolor-purple .bg-secondary {
  background-color: #7952B3 !important;
}

.scolor-purple .btn-secondary {
  color: #ffffff;
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .btn-secondary:hover {
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .btn-secondary.disabled, .scolor-purple .btn-secondary:disabled {
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .btn-secondary:not(:disabled):not(.disabled):active, .scolor-purple .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-purple .show > .btn-secondary.dropdown-toggle {
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .btn-outline-secondary {
  color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .btn-outline-secondary:hover {
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .btn-outline-secondary.disabled, .scolor-purple .btn-outline-secondary:disabled {
  color: #7952B3;
}

.scolor-purple .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-purple .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-purple .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple .badge-secondary {
  color: #ffffff;
  background-color: #7952B3;
}

.scolor-purple .badge-secondary[href]:hover, .scolor-purple .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #7952B3;
}

.scolor-purple .alert-secondary .alert-link {
  color: #7952B3;
}

.scolor-purple .list-group-item-secondary {
  color: #7952B3;
}

.scolor-purple .list-group-item-secondary.list-group-item-action:hover, .scolor-purple .list-group-item-secondary.list-group-item-action:focus {
  color: #7952B3;
}

.scolor-purple .list-group-item-secondary.list-group-item-action.active {
  background-color: #7952B3;
  border-color: #7952B3;
}

.scolor-purple a.bg-secondary:hover, .scolor-purple a.bg-secondary:focus,
.scolor-purple button.bg-secondary:hover,
.scolor-purple button.bg-secondary:focus {
  background-color: #7952B3 !important;
}

.scolor-purple .border-secondary {
  border-color: #7952B3 !important;
}

.scolor-purple .text-secondary {
  color: #7952B3 !important;
}

.scolor-purple a.text-secondary:hover, .scolor-purple a.text-secondary:focus {
  color: #7952B3 !important;
}

.scolor-pink .bg-secondary {
  background-color: #e83e8c !important;
}

.scolor-pink .btn-secondary {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .btn-secondary:hover {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .btn-secondary.disabled, .scolor-pink .btn-secondary:disabled {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .btn-secondary:not(:disabled):not(.disabled):active, .scolor-pink .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-pink .show > .btn-secondary.dropdown-toggle {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .btn-outline-secondary {
  color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .btn-outline-secondary:hover {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .btn-outline-secondary.disabled, .scolor-pink .btn-outline-secondary:disabled {
  color: #e83e8c;
}

.scolor-pink .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-pink .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-pink .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink .badge-secondary {
  color: #ffffff;
  background-color: #e83e8c;
}

.scolor-pink .badge-secondary[href]:hover, .scolor-pink .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #e83e8c;
}

.scolor-pink .alert-secondary .alert-link {
  color: #e83e8c;
}

.scolor-pink .list-group-item-secondary {
  color: #e83e8c;
}

.scolor-pink .list-group-item-secondary.list-group-item-action:hover, .scolor-pink .list-group-item-secondary.list-group-item-action:focus {
  color: #e83e8c;
}

.scolor-pink .list-group-item-secondary.list-group-item-action.active {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.scolor-pink a.bg-secondary:hover, .scolor-pink a.bg-secondary:focus,
.scolor-pink button.bg-secondary:hover,
.scolor-pink button.bg-secondary:focus {
  background-color: #e83e8c !important;
}

.scolor-pink .border-secondary {
  border-color: #e83e8c !important;
}

.scolor-pink .text-secondary {
  color: #e83e8c !important;
}

.scolor-pink a.text-secondary:hover, .scolor-pink a.text-secondary:focus {
  color: #e83e8c !important;
}

.scolor-red .bg-secondary {
  background-color: #d72924 !important;
}

.scolor-red .btn-secondary {
  color: #ffffff;
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red .btn-secondary:hover {
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red .btn-secondary.disabled, .scolor-red .btn-secondary:disabled {
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red .btn-secondary:not(:disabled):not(.disabled):active, .scolor-red .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-red .show > .btn-secondary.dropdown-toggle {
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red .btn-outline-secondary {
  color: #d72924;
  border-color: #d72924;
}

.scolor-red .btn-outline-secondary:hover {
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red .btn-outline-secondary.disabled, .scolor-red .btn-outline-secondary:disabled {
  color: #d72924;
}

.scolor-red .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-red .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-red .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red .badge-secondary {
  color: #ffffff;
  background-color: #d72924;
}

.scolor-red .badge-secondary[href]:hover, .scolor-red .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #d72924;
}

.scolor-red .alert-secondary .alert-link {
  color: #d72924;
}

.scolor-red .list-group-item-secondary {
  color: #d72924;
}

.scolor-red .list-group-item-secondary.list-group-item-action:hover, .scolor-red .list-group-item-secondary.list-group-item-action:focus {
  color: #d72924;
}

.scolor-red .list-group-item-secondary.list-group-item-action.active {
  background-color: #d72924;
  border-color: #d72924;
}

.scolor-red a.bg-secondary:hover, .scolor-red a.bg-secondary:focus,
.scolor-red button.bg-secondary:hover,
.scolor-red button.bg-secondary:focus {
  background-color: #d72924 !important;
}

.scolor-red .border-secondary {
  border-color: #d72924 !important;
}

.scolor-red .text-secondary {
  color: #d72924 !important;
}

.scolor-red a.text-secondary:hover, .scolor-red a.text-secondary:focus {
  color: #d72924 !important;
}

.scolor-orange .bg-secondary {
  background-color: #fa983a !important;
}

.scolor-orange .btn-secondary {
  color: #ffffff;
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .btn-secondary:hover {
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .btn-secondary.disabled, .scolor-orange .btn-secondary:disabled {
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .btn-secondary:not(:disabled):not(.disabled):active, .scolor-orange .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-orange .show > .btn-secondary.dropdown-toggle {
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .btn-outline-secondary {
  color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .btn-outline-secondary:hover {
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .btn-outline-secondary.disabled, .scolor-orange .btn-outline-secondary:disabled {
  color: #fa983a;
}

.scolor-orange .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-orange .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-orange .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange .badge-secondary {
  color: #ffffff;
  background-color: #fa983a;
}

.scolor-orange .badge-secondary[href]:hover, .scolor-orange .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #fa983a;
}

.scolor-orange .alert-secondary .alert-link {
  color: #fa983a;
}

.scolor-orange .list-group-item-secondary {
  color: #fa983a;
}

.scolor-orange .list-group-item-secondary.list-group-item-action:hover, .scolor-orange .list-group-item-secondary.list-group-item-action:focus {
  color: #fa983a;
}

.scolor-orange .list-group-item-secondary.list-group-item-action.active {
  background-color: #fa983a;
  border-color: #fa983a;
}

.scolor-orange a.bg-secondary:hover, .scolor-orange a.bg-secondary:focus,
.scolor-orange button.bg-secondary:hover,
.scolor-orange button.bg-secondary:focus {
  background-color: #fa983a !important;
}

.scolor-orange .border-secondary {
  border-color: #fa983a !important;
}

.scolor-orange .text-secondary {
  color: #fa983a !important;
}

.scolor-orange a.text-secondary:hover, .scolor-orange a.text-secondary:focus {
  color: #fa983a !important;
}

.scolor-yellow .bg-secondary {
  background-color: #f4b12c !important;
}

.scolor-yellow .btn-secondary {
  color: #ffffff;
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .btn-secondary:hover {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .btn-secondary.disabled, .scolor-yellow .btn-secondary:disabled {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .btn-secondary:not(:disabled):not(.disabled):active, .scolor-yellow .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-yellow .show > .btn-secondary.dropdown-toggle {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .btn-outline-secondary {
  color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .btn-outline-secondary:hover {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .btn-outline-secondary.disabled, .scolor-yellow .btn-outline-secondary:disabled {
  color: #f4b12c;
}

.scolor-yellow .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-yellow .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-yellow .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow .badge-secondary {
  color: #ffffff;
  background-color: #f4b12c;
}

.scolor-yellow .badge-secondary[href]:hover, .scolor-yellow .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #f4b12c;
}

.scolor-yellow .alert-secondary .alert-link {
  color: #f4b12c;
}

.scolor-yellow .list-group-item-secondary {
  color: #f4b12c;
}

.scolor-yellow .list-group-item-secondary.list-group-item-action:hover, .scolor-yellow .list-group-item-secondary.list-group-item-action:focus {
  color: #f4b12c;
}

.scolor-yellow .list-group-item-secondary.list-group-item-action.active {
  background-color: #f4b12c;
  border-color: #f4b12c;
}

.scolor-yellow a.bg-secondary:hover, .scolor-yellow a.bg-secondary:focus,
.scolor-yellow button.bg-secondary:hover,
.scolor-yellow button.bg-secondary:focus {
  background-color: #f4b12c !important;
}

.scolor-yellow .border-secondary {
  border-color: #f4b12c !important;
}

.scolor-yellow .text-secondary {
  color: #f4b12c !important;
}

.scolor-yellow a.text-secondary:hover, .scolor-yellow a.text-secondary:focus {
  color: #f4b12c !important;
}

.scolor-green .bg-secondary {
  background-color: #00b191 !important;
}

.scolor-green .btn-secondary {
  color: #ffffff;
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green .btn-secondary:hover {
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green .btn-secondary.disabled, .scolor-green .btn-secondary:disabled {
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green .btn-secondary:not(:disabled):not(.disabled):active, .scolor-green .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-green .show > .btn-secondary.dropdown-toggle {
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green .btn-outline-secondary {
  color: #00b191;
  border-color: #00b191;
}

.scolor-green .btn-outline-secondary:hover {
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green .btn-outline-secondary.disabled, .scolor-green .btn-outline-secondary:disabled {
  color: #00b191;
}

.scolor-green .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-green .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-green .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green .badge-secondary {
  color: #ffffff;
  background-color: #00b191;
}

.scolor-green .badge-secondary[href]:hover, .scolor-green .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #00b191;
}

.scolor-green .alert-secondary .alert-link {
  color: #00b191;
}

.scolor-green .list-group-item-secondary {
  color: #00b191;
}

.scolor-green .list-group-item-secondary.list-group-item-action:hover, .scolor-green .list-group-item-secondary.list-group-item-action:focus {
  color: #00b191;
}

.scolor-green .list-group-item-secondary.list-group-item-action.active {
  background-color: #00b191;
  border-color: #00b191;
}

.scolor-green a.bg-secondary:hover, .scolor-green a.bg-secondary:focus,
.scolor-green button.bg-secondary:hover,
.scolor-green button.bg-secondary:focus {
  background-color: #00b191 !important;
}

.scolor-green .border-secondary {
  border-color: #00b191 !important;
}

.scolor-green .text-secondary {
  color: #00b191 !important;
}

.scolor-green a.text-secondary:hover, .scolor-green a.text-secondary:focus {
  color: #00b191 !important;
}

.scolor-black .bg-secondary {
  background-color: #000000 !important;
}

.scolor-black .btn-secondary {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.scolor-black .btn-secondary:hover {
  background-color: #000000;
  border-color: #000000;
}

.scolor-black .btn-secondary.disabled, .scolor-black .btn-secondary:disabled {
  background-color: #000000;
  border-color: #000000;
}

.scolor-black .btn-secondary:not(:disabled):not(.disabled):active, .scolor-black .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-black .show > .btn-secondary.dropdown-toggle {
  background-color: #000000;
  border-color: #000000;
}

.scolor-black .btn-outline-secondary {
  color: #000000;
  border-color: #000000;
}

.scolor-black .btn-outline-secondary:hover {
  background-color: #000000;
  border-color: #000000;
}

.scolor-black .btn-outline-secondary.disabled, .scolor-black .btn-outline-secondary:disabled {
  color: #000000;
}

.scolor-black .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-black .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-black .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #000000;
  border-color: #000000;
}

.scolor-black .badge-secondary {
  color: #ffffff;
  background-color: #000000;
}

.scolor-black .badge-secondary[href]:hover, .scolor-black .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #000000;
}

.scolor-black .alert-secondary .alert-link {
  color: #000000;
}

.scolor-black .list-group-item-secondary {
  color: #000000;
}

.scolor-black .list-group-item-secondary.list-group-item-action:hover, .scolor-black .list-group-item-secondary.list-group-item-action:focus {
  color: #000000;
}

.scolor-black .list-group-item-secondary.list-group-item-action.active {
  background-color: #000000;
  border-color: #000000;
}

.scolor-black a.bg-secondary:hover, .scolor-black a.bg-secondary:focus,
.scolor-black button.bg-secondary:hover,
.scolor-black button.bg-secondary:focus {
  background-color: #000000 !important;
}

.scolor-black .border-secondary {
  border-color: #000000 !important;
}

.scolor-black .text-secondary {
  color: #000000 !important;
}

.scolor-black a.text-secondary:hover, .scolor-black a.text-secondary:focus {
  color: #000000 !important;
}

.scolor-darkblue .bg-secondary {
  background-color: #07478f !important;
}

.scolor-darkblue .btn-secondary {
  color: #ffffff;
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .btn-secondary:hover {
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .btn-secondary.disabled, .scolor-darkblue .btn-secondary:disabled {
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .btn-secondary:not(:disabled):not(.disabled):active, .scolor-darkblue .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-darkblue .show > .btn-secondary.dropdown-toggle {
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .btn-outline-secondary {
  color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .btn-outline-secondary:hover {
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .btn-outline-secondary.disabled, .scolor-darkblue .btn-outline-secondary:disabled {
  color: #07478f;
}

.scolor-darkblue .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-darkblue .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-darkblue .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue .badge-secondary {
  color: #ffffff;
  background-color: #07478f;
}

.scolor-darkblue .badge-secondary[href]:hover, .scolor-darkblue .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #07478f;
}

.scolor-darkblue .alert-secondary .alert-link {
  color: #07478f;
}

.scolor-darkblue .list-group-item-secondary {
  color: #07478f;
}

.scolor-darkblue .list-group-item-secondary.list-group-item-action:hover, .scolor-darkblue .list-group-item-secondary.list-group-item-action:focus {
  color: #07478f;
}

.scolor-darkblue .list-group-item-secondary.list-group-item-action.active {
  background-color: #07478f;
  border-color: #07478f;
}

.scolor-darkblue a.bg-secondary:hover, .scolor-darkblue a.bg-secondary:focus,
.scolor-darkblue button.bg-secondary:hover,
.scolor-darkblue button.bg-secondary:focus {
  background-color: #07478f !important;
}

.scolor-darkblue .border-secondary {
  border-color: #07478f !important;
}

.scolor-darkblue .text-secondary {
  color: #07478f !important;
}

.scolor-darkblue a.text-secondary:hover, .scolor-darkblue a.text-secondary:focus {
  color: #07478f !important;
}

.scolor-lightblue .bg-secondary {
  background-color: #1db8fa !important;
}

.scolor-lightblue .btn-secondary {
  color: #ffffff;
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .btn-secondary:hover {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .btn-secondary.disabled, .scolor-lightblue .btn-secondary:disabled {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .btn-secondary:not(:disabled):not(.disabled):active, .scolor-lightblue .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-lightblue .show > .btn-secondary.dropdown-toggle {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .btn-outline-secondary {
  color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .btn-outline-secondary:hover {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .btn-outline-secondary.disabled, .scolor-lightblue .btn-outline-secondary:disabled {
  color: #1db8fa;
}

.scolor-lightblue .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-lightblue .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-lightblue .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue .badge-secondary {
  color: #ffffff;
  background-color: #1db8fa;
}

.scolor-lightblue .badge-secondary[href]:hover, .scolor-lightblue .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #1db8fa;
}

.scolor-lightblue .alert-secondary .alert-link {
  color: #1db8fa;
}

.scolor-lightblue .list-group-item-secondary {
  color: #1db8fa;
}

.scolor-lightblue .list-group-item-secondary.list-group-item-action:hover, .scolor-lightblue .list-group-item-secondary.list-group-item-action:focus {
  color: #1db8fa;
}

.scolor-lightblue .list-group-item-secondary.list-group-item-action.active {
  background-color: #1db8fa;
  border-color: #1db8fa;
}

.scolor-lightblue a.bg-secondary:hover, .scolor-lightblue a.bg-secondary:focus,
.scolor-lightblue button.bg-secondary:hover,
.scolor-lightblue button.bg-secondary:focus {
  background-color: #1db8fa !important;
}

.scolor-lightblue .border-secondary {
  border-color: #1db8fa !important;
}

.scolor-lightblue .text-secondary {
  color: #1db8fa !important;
}

.scolor-lightblue a.text-secondary:hover, .scolor-lightblue a.text-secondary:focus {
  color: #1db8fa !important;
}

.scolor-blacksoft .bg-secondary {
  background-color: #131313 !important;
}

.scolor-blacksoft .btn-secondary {
  color: #ffffff;
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .btn-secondary:hover {
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .btn-secondary.disabled, .scolor-blacksoft .btn-secondary:disabled {
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .btn-secondary:not(:disabled):not(.disabled):active, .scolor-blacksoft .btn-secondary:not(:disabled):not(.disabled).active,
.scolor-blacksoft .show > .btn-secondary.dropdown-toggle {
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .btn-outline-secondary {
  color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .btn-outline-secondary:hover {
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .btn-outline-secondary.disabled, .scolor-blacksoft .btn-outline-secondary:disabled {
  color: #131313;
}

.scolor-blacksoft .btn-outline-secondary:not(:disabled):not(.disabled):active, .scolor-blacksoft .btn-outline-secondary:not(:disabled):not(.disabled).active,
.scolor-blacksoft .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft .badge-secondary {
  color: #ffffff;
  background-color: #131313;
}

.scolor-blacksoft .badge-secondary[href]:hover, .scolor-blacksoft .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #131313;
}

.scolor-blacksoft .alert-secondary .alert-link {
  color: #131313;
}

.scolor-blacksoft .list-group-item-secondary {
  color: #131313;
}

.scolor-blacksoft .list-group-item-secondary.list-group-item-action:hover, .scolor-blacksoft .list-group-item-secondary.list-group-item-action:focus {
  color: #131313;
}

.scolor-blacksoft .list-group-item-secondary.list-group-item-action.active {
  background-color: #131313;
  border-color: #131313;
}

.scolor-blacksoft a.bg-secondary:hover, .scolor-blacksoft a.bg-secondary:focus,
.scolor-blacksoft button.bg-secondary:hover,
.scolor-blacksoft button.bg-secondary:focus {
  background-color: #131313 !important;
}

.scolor-blacksoft .border-secondary {
  border-color: #131313 !important;
}

.scolor-blacksoft .text-secondary {
  color: #131313 !important;
}

.scolor-blacksoft a.text-secondary:hover, .scolor-blacksoft a.text-secondary:focus {
  color: #131313 !important;
}

/*------------------------------------
	Default color
------------------------------------*/
.bg-black {
  background: #000000;
}

/*------------------------------------
	Default color
------------------------------------*/
.color-blue .h6, .color-blue h1 > a, .color-blue h2 > a, .color-blue h3 > a, .color-blue h4 > a, .color-blue h5 > a, .color-blue h6 > a, .color-blue .h1 > a, .color-blue .h2 > a, .color-blue .h3 > a, .color-blue .h4 > a, .color-blue .h5 > a, .color-blue .h6 > a, .color-blue p > a {
  color: #212121;
}

.color-blue .page-link:hover, .color-blue .btn-success, .color-blue .btn-primary, .color-blue .btn-secondary, .color-blue .btn-info, .color-blue .btn-danger, .color-blue .btn-warning, .color-blue .btn-dark, .color-blue .btn-success:hover, .color-blue .btn-primary:hover, .color-blue .btn-secondary:hover, .color-blue .btn-info:hover, .color-blue .btn-danger:hover, .color-blue .btn-warning:hover, .color-blue .btn-dark:hover {
  color: #ffffff;
}

.color-blue .btn-outline-light {
  color: #ffffff;
}

.color-blue .dropdown-item {
  color: #212529;
}

.color-blue .bg-light {
  background-color: #f6f6f6 !important;
}

.color-blue .badge-light {
  color: #212529;
}

.color-blue .btn-light {
  color: #212529;
}

.color-blue .btn-outline-primary:hover {
  color: #ffffff;
}

.color-blue a.h5 {
  color: #212529;
}

.color-blue .navbar-dark .navbar-nav .nav-link:hover, .color-blue .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-blue .footer-dark h1 > a, .color-blue .footer-dark h2 > a, .color-blue .footer-dark h3 > a, .color-blue .footer-dark h4 > a, .color-blue .footer-dark h5 > a, .color-blue .footer-dark h6 > a, .color-blue .footer-dark .h1 > a, .color-blue .footer-dark .h2 > a, .color-blue .footer-dark .h3 > a, .color-blue .footer-dark .h4 > a, .color-blue .footer-dark .h5 > a, .color-blue .footer-dark .h6 > a, .color-blue .footer-dark p > a {
  color: #dfdfdf;
}

.color-blue .footer-dark .hover-a:hover h1 > a, .color-blue .footer-dark .hover-a:hover h2 > a, .color-blue .footer-dark .hover-a:hover h3 > a, .color-blue .footer-dark .hover-a:hover h4 > a, .color-blue .footer-dark .hover-a:hover h5 > a, .color-blue .footer-dark .hover-a:hover h6 > a, .color-blue .footer-dark .hover-a:hover .h1 > a, .color-blue .footer-dark .hover-a:hover .h2 > a, .color-blue .footer-dark .hover-a:hover .h3 > a, .color-blue .footer-dark .hover-a:hover .h4 > a, .color-blue .footer-dark .hover-a:hover .h5 > a, .color-blue .footer-dark .hover-a:hover .h6 > a, .color-blue .footer-dark .hover-a:hover p > a {
  color: #2196f3;
}

.color-blue .footer-dark .fab {
  color: #dfdfdf;
}

.color-blue .nav-tabs .nav-link {
  color: #212529;
}

.color-blue .main-menu.navbar-dark .hover-a:hover h1 > a, .color-blue .main-menu.navbar-dark .hover-a:hover h2 > a, .color-blue .main-menu.navbar-dark .hover-a:hover h3 > a, .color-blue .main-menu.navbar-dark .hover-a:hover h4 > a, .color-blue .main-menu.navbar-dark .hover-a:hover h5 > a, .color-blue .main-menu.navbar-dark .hover-a:hover h6 > a, .color-blue .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-blue .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-blue .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-blue .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-blue .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-blue .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #2196f3 !important;
}

.color-blue .owl-carousel button.owl-dot.active {
  background: #2196f3;
}

.color-purple .h6, .color-purple h1 > a, .color-purple h2 > a, .color-purple h3 > a, .color-purple h4 > a, .color-purple h5 > a, .color-purple h6 > a, .color-purple .h1 > a, .color-purple .h2 > a, .color-purple .h3 > a, .color-purple .h4 > a, .color-purple .h5 > a, .color-purple .h6 > a, .color-purple p > a {
  color: #212121;
}

.color-purple .page-link:hover, .color-purple .btn-success, .color-purple .btn-primary, .color-purple .btn-secondary, .color-purple .btn-info, .color-purple .btn-danger, .color-purple .btn-warning, .color-purple .btn-dark, .color-purple .btn-success:hover, .color-purple .btn-primary:hover, .color-purple .btn-secondary:hover, .color-purple .btn-info:hover, .color-purple .btn-danger:hover, .color-purple .btn-warning:hover, .color-purple .btn-dark:hover {
  color: #ffffff;
}

.color-purple .btn-outline-light {
  color: #ffffff;
}

.color-purple .dropdown-item {
  color: #212529;
}

.color-purple .bg-light {
  background-color: #f6f6f6 !important;
}

.color-purple .badge-light {
  color: #212529;
}

.color-purple .btn-light {
  color: #212529;
}

.color-purple .btn-outline-primary:hover {
  color: #ffffff;
}

.color-purple a.h5 {
  color: #212529;
}

.color-purple .navbar-dark .navbar-nav .nav-link:hover, .color-purple .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-purple .footer-dark h1 > a, .color-purple .footer-dark h2 > a, .color-purple .footer-dark h3 > a, .color-purple .footer-dark h4 > a, .color-purple .footer-dark h5 > a, .color-purple .footer-dark h6 > a, .color-purple .footer-dark .h1 > a, .color-purple .footer-dark .h2 > a, .color-purple .footer-dark .h3 > a, .color-purple .footer-dark .h4 > a, .color-purple .footer-dark .h5 > a, .color-purple .footer-dark .h6 > a, .color-purple .footer-dark p > a {
  color: #dfdfdf;
}

.color-purple .footer-dark .hover-a:hover h1 > a, .color-purple .footer-dark .hover-a:hover h2 > a, .color-purple .footer-dark .hover-a:hover h3 > a, .color-purple .footer-dark .hover-a:hover h4 > a, .color-purple .footer-dark .hover-a:hover h5 > a, .color-purple .footer-dark .hover-a:hover h6 > a, .color-purple .footer-dark .hover-a:hover .h1 > a, .color-purple .footer-dark .hover-a:hover .h2 > a, .color-purple .footer-dark .hover-a:hover .h3 > a, .color-purple .footer-dark .hover-a:hover .h4 > a, .color-purple .footer-dark .hover-a:hover .h5 > a, .color-purple .footer-dark .hover-a:hover .h6 > a, .color-purple .footer-dark .hover-a:hover p > a {
  color: #7952B3;
}

.color-purple .footer-dark .fab {
  color: #dfdfdf;
}

.color-purple .nav-tabs .nav-link {
  color: #212529;
}

.color-purple .main-menu.navbar-dark .hover-a:hover h1 > a, .color-purple .main-menu.navbar-dark .hover-a:hover h2 > a, .color-purple .main-menu.navbar-dark .hover-a:hover h3 > a, .color-purple .main-menu.navbar-dark .hover-a:hover h4 > a, .color-purple .main-menu.navbar-dark .hover-a:hover h5 > a, .color-purple .main-menu.navbar-dark .hover-a:hover h6 > a, .color-purple .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-purple .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-purple .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-purple .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-purple .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-purple .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #7952B3 !important;
}

.color-purple .owl-carousel button.owl-dot.active {
  background: #7952B3;
}

.color-pink .h6, .color-pink h1 > a, .color-pink h2 > a, .color-pink h3 > a, .color-pink h4 > a, .color-pink h5 > a, .color-pink h6 > a, .color-pink .h1 > a, .color-pink .h2 > a, .color-pink .h3 > a, .color-pink .h4 > a, .color-pink .h5 > a, .color-pink .h6 > a, .color-pink p > a {
  color: #212121;
}

.color-pink .page-link:hover, .color-pink .btn-success, .color-pink .btn-primary, .color-pink .btn-secondary, .color-pink .btn-info, .color-pink .btn-danger, .color-pink .btn-warning, .color-pink .btn-dark, .color-pink .btn-success:hover, .color-pink .btn-primary:hover, .color-pink .btn-secondary:hover, .color-pink .btn-info:hover, .color-pink .btn-danger:hover, .color-pink .btn-warning:hover, .color-pink .btn-dark:hover {
  color: #ffffff;
}

.color-pink .btn-outline-light {
  color: #ffffff;
}

.color-pink .dropdown-item {
  color: #212529;
}

.color-pink .bg-light {
  background-color: #f6f6f6 !important;
}

.color-pink .badge-light {
  color: #212529;
}

.color-pink .btn-light {
  color: #212529;
}

.color-pink .btn-outline-primary:hover {
  color: #ffffff;
}

.color-pink a.h5 {
  color: #212529;
}

.color-pink .navbar-dark .navbar-nav .nav-link:hover, .color-pink .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-pink .footer-dark h1 > a, .color-pink .footer-dark h2 > a, .color-pink .footer-dark h3 > a, .color-pink .footer-dark h4 > a, .color-pink .footer-dark h5 > a, .color-pink .footer-dark h6 > a, .color-pink .footer-dark .h1 > a, .color-pink .footer-dark .h2 > a, .color-pink .footer-dark .h3 > a, .color-pink .footer-dark .h4 > a, .color-pink .footer-dark .h5 > a, .color-pink .footer-dark .h6 > a, .color-pink .footer-dark p > a {
  color: #dfdfdf;
}

.color-pink .footer-dark .hover-a:hover h1 > a, .color-pink .footer-dark .hover-a:hover h2 > a, .color-pink .footer-dark .hover-a:hover h3 > a, .color-pink .footer-dark .hover-a:hover h4 > a, .color-pink .footer-dark .hover-a:hover h5 > a, .color-pink .footer-dark .hover-a:hover h6 > a, .color-pink .footer-dark .hover-a:hover .h1 > a, .color-pink .footer-dark .hover-a:hover .h2 > a, .color-pink .footer-dark .hover-a:hover .h3 > a, .color-pink .footer-dark .hover-a:hover .h4 > a, .color-pink .footer-dark .hover-a:hover .h5 > a, .color-pink .footer-dark .hover-a:hover .h6 > a, .color-pink .footer-dark .hover-a:hover p > a {
  color: #e83e8c;
}

.color-pink .footer-dark .fab {
  color: #dfdfdf;
}

.color-pink .nav-tabs .nav-link {
  color: #212529;
}

.color-pink .main-menu.navbar-dark .hover-a:hover h1 > a, .color-pink .main-menu.navbar-dark .hover-a:hover h2 > a, .color-pink .main-menu.navbar-dark .hover-a:hover h3 > a, .color-pink .main-menu.navbar-dark .hover-a:hover h4 > a, .color-pink .main-menu.navbar-dark .hover-a:hover h5 > a, .color-pink .main-menu.navbar-dark .hover-a:hover h6 > a, .color-pink .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-pink .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-pink .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-pink .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-pink .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-pink .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #e83e8c !important;
}

.color-pink .owl-carousel button.owl-dot.active {
  background: #e83e8c;
}

.color-red .h6, .color-red h1 > a, .color-red h2 > a, .color-red h3 > a, .color-red h4 > a, .color-red h5 > a, .color-red h6 > a, .color-red .h1 > a, .color-red .h2 > a, .color-red .h3 > a, .color-red .h4 > a, .color-red .h5 > a, .color-red .h6 > a, .color-red p > a {
  color: #212121;
}

.color-red .page-link:hover, .color-red .btn-success, .color-red .btn-primary, .color-red .btn-secondary, .color-red .btn-info, .color-red .btn-danger, .color-red .btn-warning, .color-red .btn-dark, .color-red .btn-success:hover, .color-red .btn-primary:hover, .color-red .btn-secondary:hover, .color-red .btn-info:hover, .color-red .btn-danger:hover, .color-red .btn-warning:hover, .color-red .btn-dark:hover {
  color: #ffffff;
}

.color-red .btn-outline-light {
  color: #ffffff;
}

.color-red .dropdown-item {
  color: #212529;
}

.color-red .bg-light {
  background-color: #f6f6f6 !important;
}

.color-red .badge-light {
  color: #212529;
}

.color-red .btn-light {
  color: #212529;
}

.color-red .btn-outline-primary:hover {
  color: #ffffff;
}

.color-red a.h5 {
  color: #212529;
}

.color-red .navbar-dark .navbar-nav .nav-link:hover, .color-red .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-red .footer-dark h1 > a, .color-red .footer-dark h2 > a, .color-red .footer-dark h3 > a, .color-red .footer-dark h4 > a, .color-red .footer-dark h5 > a, .color-red .footer-dark h6 > a, .color-red .footer-dark .h1 > a, .color-red .footer-dark .h2 > a, .color-red .footer-dark .h3 > a, .color-red .footer-dark .h4 > a, .color-red .footer-dark .h5 > a, .color-red .footer-dark .h6 > a, .color-red .footer-dark p > a {
  color: #dfdfdf;
}

.color-red .footer-dark .hover-a:hover h1 > a, .color-red .footer-dark .hover-a:hover h2 > a, .color-red .footer-dark .hover-a:hover h3 > a, .color-red .footer-dark .hover-a:hover h4 > a, .color-red .footer-dark .hover-a:hover h5 > a, .color-red .footer-dark .hover-a:hover h6 > a, .color-red .footer-dark .hover-a:hover .h1 > a, .color-red .footer-dark .hover-a:hover .h2 > a, .color-red .footer-dark .hover-a:hover .h3 > a, .color-red .footer-dark .hover-a:hover .h4 > a, .color-red .footer-dark .hover-a:hover .h5 > a, .color-red .footer-dark .hover-a:hover .h6 > a, .color-red .footer-dark .hover-a:hover p > a {
  color: #d72924;
}

.color-red .footer-dark .fab {
  color: #dfdfdf;
}

.color-red .nav-tabs .nav-link {
  color: #212529;
}

.color-red .main-menu.navbar-dark .hover-a:hover h1 > a, .color-red .main-menu.navbar-dark .hover-a:hover h2 > a, .color-red .main-menu.navbar-dark .hover-a:hover h3 > a, .color-red .main-menu.navbar-dark .hover-a:hover h4 > a, .color-red .main-menu.navbar-dark .hover-a:hover h5 > a, .color-red .main-menu.navbar-dark .hover-a:hover h6 > a, .color-red .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-red .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-red .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-red .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-red .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-red .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #d72924 !important;
}

.color-red .owl-carousel button.owl-dot.active {
  background: #d72924;
}

.color-orange .h6, .color-orange h1 > a, .color-orange h2 > a, .color-orange h3 > a, .color-orange h4 > a, .color-orange h5 > a, .color-orange h6 > a, .color-orange .h1 > a, .color-orange .h2 > a, .color-orange .h3 > a, .color-orange .h4 > a, .color-orange .h5 > a, .color-orange .h6 > a, .color-orange p > a {
  color: #212121;
}

.color-orange .page-link:hover, .color-orange .btn-success, .color-orange .btn-primary, .color-orange .btn-secondary, .color-orange .btn-info, .color-orange .btn-danger, .color-orange .btn-warning, .color-orange .btn-dark, .color-orange .btn-success:hover, .color-orange .btn-primary:hover, .color-orange .btn-secondary:hover, .color-orange .btn-info:hover, .color-orange .btn-danger:hover, .color-orange .btn-warning:hover, .color-orange .btn-dark:hover {
  color: #ffffff;
}

.color-orange .btn-outline-light {
  color: #ffffff;
}

.color-orange .dropdown-item {
  color: #212529;
}

.color-orange .bg-light {
  background-color: #f6f6f6 !important;
}

.color-orange .badge-light {
  color: #212529;
}

.color-orange .btn-light {
  color: #212529;
}

.color-orange .btn-outline-primary:hover {
  color: #ffffff;
}

.color-orange a.h5 {
  color: #212529;
}

.color-orange .navbar-dark .navbar-nav .nav-link:hover, .color-orange .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-orange .footer-dark h1 > a, .color-orange .footer-dark h2 > a, .color-orange .footer-dark h3 > a, .color-orange .footer-dark h4 > a, .color-orange .footer-dark h5 > a, .color-orange .footer-dark h6 > a, .color-orange .footer-dark .h1 > a, .color-orange .footer-dark .h2 > a, .color-orange .footer-dark .h3 > a, .color-orange .footer-dark .h4 > a, .color-orange .footer-dark .h5 > a, .color-orange .footer-dark .h6 > a, .color-orange .footer-dark p > a {
  color: #dfdfdf;
}

.color-orange .footer-dark .hover-a:hover h1 > a, .color-orange .footer-dark .hover-a:hover h2 > a, .color-orange .footer-dark .hover-a:hover h3 > a, .color-orange .footer-dark .hover-a:hover h4 > a, .color-orange .footer-dark .hover-a:hover h5 > a, .color-orange .footer-dark .hover-a:hover h6 > a, .color-orange .footer-dark .hover-a:hover .h1 > a, .color-orange .footer-dark .hover-a:hover .h2 > a, .color-orange .footer-dark .hover-a:hover .h3 > a, .color-orange .footer-dark .hover-a:hover .h4 > a, .color-orange .footer-dark .hover-a:hover .h5 > a, .color-orange .footer-dark .hover-a:hover .h6 > a, .color-orange .footer-dark .hover-a:hover p > a {
  color: #fa983a;
}

.color-orange .footer-dark .fab {
  color: #dfdfdf;
}

.color-orange .nav-tabs .nav-link {
  color: #212529;
}

.color-orange .main-menu.navbar-dark .hover-a:hover h1 > a, .color-orange .main-menu.navbar-dark .hover-a:hover h2 > a, .color-orange .main-menu.navbar-dark .hover-a:hover h3 > a, .color-orange .main-menu.navbar-dark .hover-a:hover h4 > a, .color-orange .main-menu.navbar-dark .hover-a:hover h5 > a, .color-orange .main-menu.navbar-dark .hover-a:hover h6 > a, .color-orange .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-orange .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-orange .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-orange .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-orange .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-orange .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #fa983a !important;
}

.color-orange .owl-carousel button.owl-dot.active {
  background: #fa983a;
}

.color-yellow .h6, .color-yellow h1 > a, .color-yellow h2 > a, .color-yellow h3 > a, .color-yellow h4 > a, .color-yellow h5 > a, .color-yellow h6 > a, .color-yellow .h1 > a, .color-yellow .h2 > a, .color-yellow .h3 > a, .color-yellow .h4 > a, .color-yellow .h5 > a, .color-yellow .h6 > a, .color-yellow p > a {
  color: #212121;
}

.color-yellow .page-link:hover, .color-yellow .btn-success, .color-yellow .btn-primary, .color-yellow .btn-secondary, .color-yellow .btn-info, .color-yellow .btn-danger, .color-yellow .btn-warning, .color-yellow .btn-dark, .color-yellow .btn-success:hover, .color-yellow .btn-primary:hover, .color-yellow .btn-secondary:hover, .color-yellow .btn-info:hover, .color-yellow .btn-danger:hover, .color-yellow .btn-warning:hover, .color-yellow .btn-dark:hover {
  color: #ffffff;
}

.color-yellow .btn-outline-light {
  color: #ffffff;
}

.color-yellow .dropdown-item {
  color: #212529;
}

.color-yellow .bg-light {
  background-color: #f6f6f6 !important;
}

.color-yellow .badge-light {
  color: #212529;
}

.color-yellow .btn-light {
  color: #212529;
}

.color-yellow .btn-outline-primary:hover {
  color: #ffffff;
}

.color-yellow a.h5 {
  color: #212529;
}

.color-yellow .navbar-dark .navbar-nav .nav-link:hover, .color-yellow .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-yellow .footer-dark h1 > a, .color-yellow .footer-dark h2 > a, .color-yellow .footer-dark h3 > a, .color-yellow .footer-dark h4 > a, .color-yellow .footer-dark h5 > a, .color-yellow .footer-dark h6 > a, .color-yellow .footer-dark .h1 > a, .color-yellow .footer-dark .h2 > a, .color-yellow .footer-dark .h3 > a, .color-yellow .footer-dark .h4 > a, .color-yellow .footer-dark .h5 > a, .color-yellow .footer-dark .h6 > a, .color-yellow .footer-dark p > a {
  color: #dfdfdf;
}

.color-yellow .footer-dark .hover-a:hover h1 > a, .color-yellow .footer-dark .hover-a:hover h2 > a, .color-yellow .footer-dark .hover-a:hover h3 > a, .color-yellow .footer-dark .hover-a:hover h4 > a, .color-yellow .footer-dark .hover-a:hover h5 > a, .color-yellow .footer-dark .hover-a:hover h6 > a, .color-yellow .footer-dark .hover-a:hover .h1 > a, .color-yellow .footer-dark .hover-a:hover .h2 > a, .color-yellow .footer-dark .hover-a:hover .h3 > a, .color-yellow .footer-dark .hover-a:hover .h4 > a, .color-yellow .footer-dark .hover-a:hover .h5 > a, .color-yellow .footer-dark .hover-a:hover .h6 > a, .color-yellow .footer-dark .hover-a:hover p > a {
  color: #f4b12c;
}

.color-yellow .footer-dark .fab {
  color: #dfdfdf;
}

.color-yellow .nav-tabs .nav-link {
  color: #212529;
}

.color-yellow .main-menu.navbar-dark .hover-a:hover h1 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover h2 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover h3 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover h4 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover h5 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover h6 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-yellow .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #f4b12c !important;
}

.color-yellow .owl-carousel button.owl-dot.active {
  background: #f4b12c;
}

.color-green .h6, .color-green h1 > a, .color-green h2 > a, .color-green h3 > a, .color-green h4 > a, .color-green h5 > a, .color-green h6 > a, .color-green .h1 > a, .color-green .h2 > a, .color-green .h3 > a, .color-green .h4 > a, .color-green .h5 > a, .color-green .h6 > a, .color-green p > a {
  color: #212121;
}

.color-green .page-link:hover, .color-green .btn-success, .color-green .btn-primary, .color-green .btn-secondary, .color-green .btn-info, .color-green .btn-danger, .color-green .btn-warning, .color-green .btn-dark, .color-green .btn-success:hover, .color-green .btn-primary:hover, .color-green .btn-secondary:hover, .color-green .btn-info:hover, .color-green .btn-danger:hover, .color-green .btn-warning:hover, .color-green .btn-dark:hover {
  color: #ffffff;
}

.color-green .btn-outline-light {
  color: #ffffff;
}

.color-green .dropdown-item {
  color: #212529;
}

.color-green .bg-light {
  background-color: #f6f6f6 !important;
}

.color-green .badge-light {
  color: #212529;
}

.color-green .btn-light {
  color: #212529;
}

.color-green .btn-outline-primary:hover {
  color: #ffffff;
}

.color-green a.h5 {
  color: #212529;
}

.color-green .navbar-dark .navbar-nav .nav-link:hover, .color-green .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-green .footer-dark h1 > a, .color-green .footer-dark h2 > a, .color-green .footer-dark h3 > a, .color-green .footer-dark h4 > a, .color-green .footer-dark h5 > a, .color-green .footer-dark h6 > a, .color-green .footer-dark .h1 > a, .color-green .footer-dark .h2 > a, .color-green .footer-dark .h3 > a, .color-green .footer-dark .h4 > a, .color-green .footer-dark .h5 > a, .color-green .footer-dark .h6 > a, .color-green .footer-dark p > a {
  color: #dfdfdf;
}

.color-green .footer-dark .hover-a:hover h1 > a, .color-green .footer-dark .hover-a:hover h2 > a, .color-green .footer-dark .hover-a:hover h3 > a, .color-green .footer-dark .hover-a:hover h4 > a, .color-green .footer-dark .hover-a:hover h5 > a, .color-green .footer-dark .hover-a:hover h6 > a, .color-green .footer-dark .hover-a:hover .h1 > a, .color-green .footer-dark .hover-a:hover .h2 > a, .color-green .footer-dark .hover-a:hover .h3 > a, .color-green .footer-dark .hover-a:hover .h4 > a, .color-green .footer-dark .hover-a:hover .h5 > a, .color-green .footer-dark .hover-a:hover .h6 > a, .color-green .footer-dark .hover-a:hover p > a {
  color: #00b191;
}

.color-green .footer-dark .fab {
  color: #dfdfdf;
}

.color-green .nav-tabs .nav-link {
  color: #212529;
}

.color-green .main-menu.navbar-dark .hover-a:hover h1 > a, .color-green .main-menu.navbar-dark .hover-a:hover h2 > a, .color-green .main-menu.navbar-dark .hover-a:hover h3 > a, .color-green .main-menu.navbar-dark .hover-a:hover h4 > a, .color-green .main-menu.navbar-dark .hover-a:hover h5 > a, .color-green .main-menu.navbar-dark .hover-a:hover h6 > a, .color-green .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-green .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-green .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-green .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-green .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-green .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #00b191 !important;
}

.color-green .owl-carousel button.owl-dot.active {
  background: #00b191;
}

.color-black .h6, .color-black h1 > a, .color-black h2 > a, .color-black h3 > a, .color-black h4 > a, .color-black h5 > a, .color-black h6 > a, .color-black .h1 > a, .color-black .h2 > a, .color-black .h3 > a, .color-black .h4 > a, .color-black .h5 > a, .color-black .h6 > a, .color-black p > a {
  color: #212121;
}

.color-black .page-link:hover, .color-black .btn-success, .color-black .btn-primary, .color-black .btn-secondary, .color-black .btn-info, .color-black .btn-danger, .color-black .btn-warning, .color-black .btn-dark, .color-black .btn-success:hover, .color-black .btn-primary:hover, .color-black .btn-secondary:hover, .color-black .btn-info:hover, .color-black .btn-danger:hover, .color-black .btn-warning:hover, .color-black .btn-dark:hover {
  color: #ffffff;
}

.color-black .btn-outline-light {
  color: #ffffff;
}

.color-black .dropdown-item {
  color: #212529;
}

.color-black .bg-light {
  background-color: #f6f6f6 !important;
}

.color-black .badge-light {
  color: #212529;
}

.color-black .btn-light {
  color: #212529;
}

.color-black .btn-outline-primary:hover {
  color: #ffffff;
}

.color-black a.h5 {
  color: #212529;
}

.color-black .navbar-dark .navbar-nav .nav-link:hover, .color-black .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-black .footer-dark h1 > a, .color-black .footer-dark h2 > a, .color-black .footer-dark h3 > a, .color-black .footer-dark h4 > a, .color-black .footer-dark h5 > a, .color-black .footer-dark h6 > a, .color-black .footer-dark .h1 > a, .color-black .footer-dark .h2 > a, .color-black .footer-dark .h3 > a, .color-black .footer-dark .h4 > a, .color-black .footer-dark .h5 > a, .color-black .footer-dark .h6 > a, .color-black .footer-dark p > a {
  color: #dfdfdf;
}

.color-black .footer-dark .hover-a:hover h1 > a, .color-black .footer-dark .hover-a:hover h2 > a, .color-black .footer-dark .hover-a:hover h3 > a, .color-black .footer-dark .hover-a:hover h4 > a, .color-black .footer-dark .hover-a:hover h5 > a, .color-black .footer-dark .hover-a:hover h6 > a, .color-black .footer-dark .hover-a:hover .h1 > a, .color-black .footer-dark .hover-a:hover .h2 > a, .color-black .footer-dark .hover-a:hover .h3 > a, .color-black .footer-dark .hover-a:hover .h4 > a, .color-black .footer-dark .hover-a:hover .h5 > a, .color-black .footer-dark .hover-a:hover .h6 > a, .color-black .footer-dark .hover-a:hover p > a {
  color: #000000;
}

.color-black .footer-dark .fab {
  color: #dfdfdf;
}

.color-black .nav-tabs .nav-link {
  color: #212529;
}

.color-black .main-menu.navbar-dark .hover-a:hover h1 > a, .color-black .main-menu.navbar-dark .hover-a:hover h2 > a, .color-black .main-menu.navbar-dark .hover-a:hover h3 > a, .color-black .main-menu.navbar-dark .hover-a:hover h4 > a, .color-black .main-menu.navbar-dark .hover-a:hover h5 > a, .color-black .main-menu.navbar-dark .hover-a:hover h6 > a, .color-black .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-black .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-black .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-black .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-black .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-black .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #000000 !important;
}

.color-black .owl-carousel button.owl-dot.active {
  background: #000000;
}

.color-darkblue .h6, .color-darkblue h1 > a, .color-darkblue h2 > a, .color-darkblue h3 > a, .color-darkblue h4 > a, .color-darkblue h5 > a, .color-darkblue h6 > a, .color-darkblue .h1 > a, .color-darkblue .h2 > a, .color-darkblue .h3 > a, .color-darkblue .h4 > a, .color-darkblue .h5 > a, .color-darkblue .h6 > a, .color-darkblue p > a {
  color: #212121;
}

.color-darkblue .page-link:hover, .color-darkblue .btn-success, .color-darkblue .btn-primary, .color-darkblue .btn-secondary, .color-darkblue .btn-info, .color-darkblue .btn-danger, .color-darkblue .btn-warning, .color-darkblue .btn-dark, .color-darkblue .btn-success:hover, .color-darkblue .btn-primary:hover, .color-darkblue .btn-secondary:hover, .color-darkblue .btn-info:hover, .color-darkblue .btn-danger:hover, .color-darkblue .btn-warning:hover, .color-darkblue .btn-dark:hover {
  color: #ffffff;
}

.color-darkblue .btn-outline-light {
  color: #ffffff;
}

.color-darkblue .dropdown-item {
  color: #212529;
}

.color-darkblue .bg-light {
  background-color: #f6f6f6 !important;
}

.color-darkblue .badge-light {
  color: #212529;
}

.color-darkblue .btn-light {
  color: #212529;
}

.color-darkblue .btn-outline-primary:hover {
  color: #ffffff;
}

.color-darkblue a.h5 {
  color: #212529;
}

.color-darkblue .navbar-dark .navbar-nav .nav-link:hover, .color-darkblue .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-darkblue .footer-dark h1 > a, .color-darkblue .footer-dark h2 > a, .color-darkblue .footer-dark h3 > a, .color-darkblue .footer-dark h4 > a, .color-darkblue .footer-dark h5 > a, .color-darkblue .footer-dark h6 > a, .color-darkblue .footer-dark .h1 > a, .color-darkblue .footer-dark .h2 > a, .color-darkblue .footer-dark .h3 > a, .color-darkblue .footer-dark .h4 > a, .color-darkblue .footer-dark .h5 > a, .color-darkblue .footer-dark .h6 > a, .color-darkblue .footer-dark p > a {
  color: #dfdfdf;
}

.color-darkblue .footer-dark .hover-a:hover h1 > a, .color-darkblue .footer-dark .hover-a:hover h2 > a, .color-darkblue .footer-dark .hover-a:hover h3 > a, .color-darkblue .footer-dark .hover-a:hover h4 > a, .color-darkblue .footer-dark .hover-a:hover h5 > a, .color-darkblue .footer-dark .hover-a:hover h6 > a, .color-darkblue .footer-dark .hover-a:hover .h1 > a, .color-darkblue .footer-dark .hover-a:hover .h2 > a, .color-darkblue .footer-dark .hover-a:hover .h3 > a, .color-darkblue .footer-dark .hover-a:hover .h4 > a, .color-darkblue .footer-dark .hover-a:hover .h5 > a, .color-darkblue .footer-dark .hover-a:hover .h6 > a, .color-darkblue .footer-dark .hover-a:hover p > a {
  color: #07478f;
}

.color-darkblue .footer-dark .fab {
  color: #dfdfdf;
}

.color-darkblue .nav-tabs .nav-link {
  color: #212529;
}

.color-darkblue .main-menu.navbar-dark .hover-a:hover h1 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover h2 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover h3 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover h4 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover h5 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover h6 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-darkblue .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #07478f !important;
}

.color-darkblue .owl-carousel button.owl-dot.active {
  background: #07478f;
}

.color-lightblue .h6, .color-lightblue h1 > a, .color-lightblue h2 > a, .color-lightblue h3 > a, .color-lightblue h4 > a, .color-lightblue h5 > a, .color-lightblue h6 > a, .color-lightblue .h1 > a, .color-lightblue .h2 > a, .color-lightblue .h3 > a, .color-lightblue .h4 > a, .color-lightblue .h5 > a, .color-lightblue .h6 > a, .color-lightblue p > a {
  color: #212121;
}

.color-lightblue .page-link:hover, .color-lightblue .btn-success, .color-lightblue .btn-primary, .color-lightblue .btn-secondary, .color-lightblue .btn-info, .color-lightblue .btn-danger, .color-lightblue .btn-warning, .color-lightblue .btn-dark, .color-lightblue .btn-success:hover, .color-lightblue .btn-primary:hover, .color-lightblue .btn-secondary:hover, .color-lightblue .btn-info:hover, .color-lightblue .btn-danger:hover, .color-lightblue .btn-warning:hover, .color-lightblue .btn-dark:hover {
  color: #ffffff;
}

.color-lightblue .btn-outline-light {
  color: #ffffff;
}

.color-lightblue .dropdown-item {
  color: #212529;
}

.color-lightblue .bg-light {
  background-color: #f6f6f6 !important;
}

.color-lightblue .badge-light {
  color: #212529;
}

.color-lightblue .btn-light {
  color: #212529;
}

.color-lightblue .btn-outline-primary:hover {
  color: #ffffff;
}

.color-lightblue a.h5 {
  color: #212529;
}

.color-lightblue .navbar-dark .navbar-nav .nav-link:hover, .color-lightblue .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-lightblue .footer-dark h1 > a, .color-lightblue .footer-dark h2 > a, .color-lightblue .footer-dark h3 > a, .color-lightblue .footer-dark h4 > a, .color-lightblue .footer-dark h5 > a, .color-lightblue .footer-dark h6 > a, .color-lightblue .footer-dark .h1 > a, .color-lightblue .footer-dark .h2 > a, .color-lightblue .footer-dark .h3 > a, .color-lightblue .footer-dark .h4 > a, .color-lightblue .footer-dark .h5 > a, .color-lightblue .footer-dark .h6 > a, .color-lightblue .footer-dark p > a {
  color: #dfdfdf;
}

.color-lightblue .footer-dark .hover-a:hover h1 > a, .color-lightblue .footer-dark .hover-a:hover h2 > a, .color-lightblue .footer-dark .hover-a:hover h3 > a, .color-lightblue .footer-dark .hover-a:hover h4 > a, .color-lightblue .footer-dark .hover-a:hover h5 > a, .color-lightblue .footer-dark .hover-a:hover h6 > a, .color-lightblue .footer-dark .hover-a:hover .h1 > a, .color-lightblue .footer-dark .hover-a:hover .h2 > a, .color-lightblue .footer-dark .hover-a:hover .h3 > a, .color-lightblue .footer-dark .hover-a:hover .h4 > a, .color-lightblue .footer-dark .hover-a:hover .h5 > a, .color-lightblue .footer-dark .hover-a:hover .h6 > a, .color-lightblue .footer-dark .hover-a:hover p > a {
  color: #1db8fa;
}

.color-lightblue .footer-dark .fab {
  color: #dfdfdf;
}

.color-lightblue .nav-tabs .nav-link {
  color: #212529;
}

.color-lightblue .main-menu.navbar-dark .hover-a:hover h1 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover h2 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover h3 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover h4 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover h5 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover h6 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-lightblue .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #1db8fa !important;
}

.color-lightblue .owl-carousel button.owl-dot.active {
  background: #1db8fa;
}

.color-blacksoft .h6, .color-blacksoft h1 > a, .color-blacksoft h2 > a, .color-blacksoft h3 > a, .color-blacksoft h4 > a, .color-blacksoft h5 > a, .color-blacksoft h6 > a, .color-blacksoft .h1 > a, .color-blacksoft .h2 > a, .color-blacksoft .h3 > a, .color-blacksoft .h4 > a, .color-blacksoft .h5 > a, .color-blacksoft .h6 > a, .color-blacksoft p > a {
  color: #212121;
}

.color-blacksoft .page-link:hover, .color-blacksoft .btn-success, .color-blacksoft .btn-primary, .color-blacksoft .btn-secondary, .color-blacksoft .btn-info, .color-blacksoft .btn-danger, .color-blacksoft .btn-warning, .color-blacksoft .btn-dark, .color-blacksoft .btn-success:hover, .color-blacksoft .btn-primary:hover, .color-blacksoft .btn-secondary:hover, .color-blacksoft .btn-info:hover, .color-blacksoft .btn-danger:hover, .color-blacksoft .btn-warning:hover, .color-blacksoft .btn-dark:hover {
  color: #ffffff;
}

.color-blacksoft .btn-outline-light {
  color: #ffffff;
}

.color-blacksoft .dropdown-item {
  color: #212529;
}

.color-blacksoft .bg-light {
  background-color: #f6f6f6 !important;
}

.color-blacksoft .badge-light {
  color: #212529;
}

.color-blacksoft .btn-light {
  color: #212529;
}

.color-blacksoft .btn-outline-primary:hover {
  color: #ffffff;
}

.color-blacksoft a.h5 {
  color: #212529;
}

.color-blacksoft .navbar-dark .navbar-nav .nav-link:hover, .color-blacksoft .color-red .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.color-blacksoft .footer-dark h1 > a, .color-blacksoft .footer-dark h2 > a, .color-blacksoft .footer-dark h3 > a, .color-blacksoft .footer-dark h4 > a, .color-blacksoft .footer-dark h5 > a, .color-blacksoft .footer-dark h6 > a, .color-blacksoft .footer-dark .h1 > a, .color-blacksoft .footer-dark .h2 > a, .color-blacksoft .footer-dark .h3 > a, .color-blacksoft .footer-dark .h4 > a, .color-blacksoft .footer-dark .h5 > a, .color-blacksoft .footer-dark .h6 > a, .color-blacksoft .footer-dark p > a {
  color: #dfdfdf;
}

.color-blacksoft .footer-dark .hover-a:hover h1 > a, .color-blacksoft .footer-dark .hover-a:hover h2 > a, .color-blacksoft .footer-dark .hover-a:hover h3 > a, .color-blacksoft .footer-dark .hover-a:hover h4 > a, .color-blacksoft .footer-dark .hover-a:hover h5 > a, .color-blacksoft .footer-dark .hover-a:hover h6 > a, .color-blacksoft .footer-dark .hover-a:hover .h1 > a, .color-blacksoft .footer-dark .hover-a:hover .h2 > a, .color-blacksoft .footer-dark .hover-a:hover .h3 > a, .color-blacksoft .footer-dark .hover-a:hover .h4 > a, .color-blacksoft .footer-dark .hover-a:hover .h5 > a, .color-blacksoft .footer-dark .hover-a:hover .h6 > a, .color-blacksoft .footer-dark .hover-a:hover p > a {
  color: #131313;
}

.color-blacksoft .footer-dark .fab {
  color: #dfdfdf;
}

.color-blacksoft .nav-tabs .nav-link {
  color: #212529;
}

.color-blacksoft .main-menu.navbar-dark .hover-a:hover h1 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover h2 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover h3 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover h4 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover h5 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover h6 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover .h1 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover .h2 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover .h3 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover .h4 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover .h5 > a, .color-blacksoft .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #131313 !important;
}

.color-blacksoft .owl-carousel button.owl-dot.active {
  background: #131313;
}

/*------------------------------------
	Dark Skin color
------------------------------------*/
.dark-skin #switcher #theme-settings, .dark-skin #switcher #theme-setting {
  background: #1b1b1b;
}

.dark-skin.color-blue h1, .dark-skin.color-blue h2, .dark-skin.color-blue h3, .dark-skin.color-blue h4, .dark-skin.color-blue h5, .dark-skin.color-blue h6, .dark-skin.color-blue .h1, .dark-skin.color-blue .h2, .dark-skin.color-blue .h3, .dark-skin.color-blue .h4, .dark-skin.color-blue .h5, .dark-skin.color-blue .h6 {
  color: #f6f6f6;
}

.dark-skin.color-blue h1 > a, .dark-skin.color-blue h2 > a, .dark-skin.color-blue h3 > a, .dark-skin.color-blue h4 > a, .dark-skin.color-blue h5 > a, .dark-skin.color-blue h6 > a, .dark-skin.color-blue .h1 > a, .dark-skin.color-blue .h2 > a, .dark-skin.color-blue .h3 > a, .dark-skin.color-blue .h4 > a, .dark-skin.color-blue .h5 > a, .dark-skin.color-blue .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-blue h1 > a:hover, .dark-skin.color-blue h2 > a:hover, .dark-skin.color-blue h3 > a:hover, .dark-skin.color-blue h4 > a:hover, .dark-skin.color-blue h5 > a:hover, .dark-skin.color-blue h6 > a:hover, .dark-skin.color-blue .h1 > a:hover, .dark-skin.color-blue .h2 > a:hover, .dark-skin.color-blue .h3 > a:hover, .dark-skin.color-blue .h4 > a:hover, .dark-skin.color-blue .h5 > a:hover, .dark-skin.color-blue .h6 > a:hover {
  color: #2196f3 !important;
}

.dark-skin.color-blue h1 > a.text-white, .dark-skin.color-blue h2 > a.text-white, .dark-skin.color-blue h3 > a.text-white, .dark-skin.color-blue h4 > a.text-white, .dark-skin.color-blue h5 > a.text-white, .dark-skin.color-blue h6 > a.text-white, .dark-skin.color-blue .h1 > a.text-white, .dark-skin.color-blue .h2 > a.text-white, .dark-skin.color-blue .h3 > a.text-white, .dark-skin.color-blue .h4 > a.text-white, .dark-skin.color-blue .h5 > a.text-white, .dark-skin.color-blue .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-blue .hover-a:hover h1 > a, .dark-skin.color-blue .hover-a:hover h2 > a, .dark-skin.color-blue .hover-a:hover h3 > a, .dark-skin.color-blue .hover-a:hover h4 > a, .dark-skin.color-blue .hover-a:hover h5 > a, .dark-skin.color-blue .hover-a:hover h6 > a, .dark-skin.color-blue .hover-a:hover .h1 > a, .dark-skin.color-blue .hover-a:hover .h2 > a, .dark-skin.color-blue .hover-a:hover .h3 > a, .dark-skin.color-blue .hover-a:hover .h4 > a, .dark-skin.color-blue .hover-a:hover .h5 > a, .dark-skin.color-blue .hover-a:hover .h6 > a, .dark-skin.color-blue .hover-a:hover p > a {
  color: #2196f3 !important;
}

.dark-skin.color-blue .hover-a:hover h1 > a.text-white, .dark-skin.color-blue .hover-a:hover h2 > a.text-white, .dark-skin.color-blue .hover-a:hover h3 > a.text-white, .dark-skin.color-blue .hover-a:hover h4 > a.text-white, .dark-skin.color-blue .hover-a:hover h5 > a.text-white, .dark-skin.color-blue .hover-a:hover h6 > a.text-white, .dark-skin.color-blue .hover-a:hover .h1 > a.text-white, .dark-skin.color-blue .hover-a:hover .h2 > a.text-white, .dark-skin.color-blue .hover-a:hover .h3 > a.text-white, .dark-skin.color-blue .hover-a:hover .h4 > a.text-white, .dark-skin.color-blue .hover-a:hover .h5 > a.text-white, .dark-skin.color-blue .hover-a:hover .h6 > a.text-white, .dark-skin.color-blue .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-blue #theme-settings .fa-cog {
  color: #2196f3;
}

.dark-skin.color-blue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-blue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-blue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #2196f3 !important;
}

.dark-skin.color-blue .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #2196f3;
}

.dark-skin.color-blue .nav-block-link li a.nav-link, .dark-skin.color-blue .nav-block-link1 li a.nav-link, .dark-skin.color-blue .nav-block-link2 li a.nav-link, .dark-skin.color-blue .nav-block-link3 li a.nav-link, .dark-skin.color-blue .nav-block-link4 li a.nav-link, .dark-skin.color-blue .nav-block-link5 li a.nav-link, .dark-skin.color-blue .nav-block-link6 li a.nav-link, .dark-skin.color-blue .nav-block-link7 li a.nav-link, .dark-skin.color-blue .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-blue .side-link li a.caret-collapse.collapsed, .dark-skin.color-blue .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-blue .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-blue .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #2196f3;
}

.dark-skin.color-purple h1, .dark-skin.color-purple h2, .dark-skin.color-purple h3, .dark-skin.color-purple h4, .dark-skin.color-purple h5, .dark-skin.color-purple h6, .dark-skin.color-purple .h1, .dark-skin.color-purple .h2, .dark-skin.color-purple .h3, .dark-skin.color-purple .h4, .dark-skin.color-purple .h5, .dark-skin.color-purple .h6 {
  color: #f6f6f6;
}

.dark-skin.color-purple h1 > a, .dark-skin.color-purple h2 > a, .dark-skin.color-purple h3 > a, .dark-skin.color-purple h4 > a, .dark-skin.color-purple h5 > a, .dark-skin.color-purple h6 > a, .dark-skin.color-purple .h1 > a, .dark-skin.color-purple .h2 > a, .dark-skin.color-purple .h3 > a, .dark-skin.color-purple .h4 > a, .dark-skin.color-purple .h5 > a, .dark-skin.color-purple .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-purple h1 > a:hover, .dark-skin.color-purple h2 > a:hover, .dark-skin.color-purple h3 > a:hover, .dark-skin.color-purple h4 > a:hover, .dark-skin.color-purple h5 > a:hover, .dark-skin.color-purple h6 > a:hover, .dark-skin.color-purple .h1 > a:hover, .dark-skin.color-purple .h2 > a:hover, .dark-skin.color-purple .h3 > a:hover, .dark-skin.color-purple .h4 > a:hover, .dark-skin.color-purple .h5 > a:hover, .dark-skin.color-purple .h6 > a:hover {
  color: #7952B3 !important;
}

.dark-skin.color-purple h1 > a.text-white, .dark-skin.color-purple h2 > a.text-white, .dark-skin.color-purple h3 > a.text-white, .dark-skin.color-purple h4 > a.text-white, .dark-skin.color-purple h5 > a.text-white, .dark-skin.color-purple h6 > a.text-white, .dark-skin.color-purple .h1 > a.text-white, .dark-skin.color-purple .h2 > a.text-white, .dark-skin.color-purple .h3 > a.text-white, .dark-skin.color-purple .h4 > a.text-white, .dark-skin.color-purple .h5 > a.text-white, .dark-skin.color-purple .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-purple .hover-a:hover h1 > a, .dark-skin.color-purple .hover-a:hover h2 > a, .dark-skin.color-purple .hover-a:hover h3 > a, .dark-skin.color-purple .hover-a:hover h4 > a, .dark-skin.color-purple .hover-a:hover h5 > a, .dark-skin.color-purple .hover-a:hover h6 > a, .dark-skin.color-purple .hover-a:hover .h1 > a, .dark-skin.color-purple .hover-a:hover .h2 > a, .dark-skin.color-purple .hover-a:hover .h3 > a, .dark-skin.color-purple .hover-a:hover .h4 > a, .dark-skin.color-purple .hover-a:hover .h5 > a, .dark-skin.color-purple .hover-a:hover .h6 > a, .dark-skin.color-purple .hover-a:hover p > a {
  color: #7952B3 !important;
}

.dark-skin.color-purple .hover-a:hover h1 > a.text-white, .dark-skin.color-purple .hover-a:hover h2 > a.text-white, .dark-skin.color-purple .hover-a:hover h3 > a.text-white, .dark-skin.color-purple .hover-a:hover h4 > a.text-white, .dark-skin.color-purple .hover-a:hover h5 > a.text-white, .dark-skin.color-purple .hover-a:hover h6 > a.text-white, .dark-skin.color-purple .hover-a:hover .h1 > a.text-white, .dark-skin.color-purple .hover-a:hover .h2 > a.text-white, .dark-skin.color-purple .hover-a:hover .h3 > a.text-white, .dark-skin.color-purple .hover-a:hover .h4 > a.text-white, .dark-skin.color-purple .hover-a:hover .h5 > a.text-white, .dark-skin.color-purple .hover-a:hover .h6 > a.text-white, .dark-skin.color-purple .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-purple #theme-settings .fa-cog {
  color: #7952B3;
}

.dark-skin.color-purple .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-purple .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-purple .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #7952B3 !important;
}

.dark-skin.color-purple .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #7952B3;
}

.dark-skin.color-purple .nav-block-link li a.nav-link, .dark-skin.color-purple .nav-block-link1 li a.nav-link, .dark-skin.color-purple .nav-block-link2 li a.nav-link, .dark-skin.color-purple .nav-block-link3 li a.nav-link, .dark-skin.color-purple .nav-block-link4 li a.nav-link, .dark-skin.color-purple .nav-block-link5 li a.nav-link, .dark-skin.color-purple .nav-block-link6 li a.nav-link, .dark-skin.color-purple .nav-block-link7 li a.nav-link, .dark-skin.color-purple .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-purple .side-link li a.caret-collapse.collapsed, .dark-skin.color-purple .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-purple .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-purple .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #7952B3;
}

.dark-skin.color-pink h1, .dark-skin.color-pink h2, .dark-skin.color-pink h3, .dark-skin.color-pink h4, .dark-skin.color-pink h5, .dark-skin.color-pink h6, .dark-skin.color-pink .h1, .dark-skin.color-pink .h2, .dark-skin.color-pink .h3, .dark-skin.color-pink .h4, .dark-skin.color-pink .h5, .dark-skin.color-pink .h6 {
  color: #f6f6f6;
}

.dark-skin.color-pink h1 > a, .dark-skin.color-pink h2 > a, .dark-skin.color-pink h3 > a, .dark-skin.color-pink h4 > a, .dark-skin.color-pink h5 > a, .dark-skin.color-pink h6 > a, .dark-skin.color-pink .h1 > a, .dark-skin.color-pink .h2 > a, .dark-skin.color-pink .h3 > a, .dark-skin.color-pink .h4 > a, .dark-skin.color-pink .h5 > a, .dark-skin.color-pink .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-pink h1 > a:hover, .dark-skin.color-pink h2 > a:hover, .dark-skin.color-pink h3 > a:hover, .dark-skin.color-pink h4 > a:hover, .dark-skin.color-pink h5 > a:hover, .dark-skin.color-pink h6 > a:hover, .dark-skin.color-pink .h1 > a:hover, .dark-skin.color-pink .h2 > a:hover, .dark-skin.color-pink .h3 > a:hover, .dark-skin.color-pink .h4 > a:hover, .dark-skin.color-pink .h5 > a:hover, .dark-skin.color-pink .h6 > a:hover {
  color: #e83e8c !important;
}

.dark-skin.color-pink h1 > a.text-white, .dark-skin.color-pink h2 > a.text-white, .dark-skin.color-pink h3 > a.text-white, .dark-skin.color-pink h4 > a.text-white, .dark-skin.color-pink h5 > a.text-white, .dark-skin.color-pink h6 > a.text-white, .dark-skin.color-pink .h1 > a.text-white, .dark-skin.color-pink .h2 > a.text-white, .dark-skin.color-pink .h3 > a.text-white, .dark-skin.color-pink .h4 > a.text-white, .dark-skin.color-pink .h5 > a.text-white, .dark-skin.color-pink .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-pink .hover-a:hover h1 > a, .dark-skin.color-pink .hover-a:hover h2 > a, .dark-skin.color-pink .hover-a:hover h3 > a, .dark-skin.color-pink .hover-a:hover h4 > a, .dark-skin.color-pink .hover-a:hover h5 > a, .dark-skin.color-pink .hover-a:hover h6 > a, .dark-skin.color-pink .hover-a:hover .h1 > a, .dark-skin.color-pink .hover-a:hover .h2 > a, .dark-skin.color-pink .hover-a:hover .h3 > a, .dark-skin.color-pink .hover-a:hover .h4 > a, .dark-skin.color-pink .hover-a:hover .h5 > a, .dark-skin.color-pink .hover-a:hover .h6 > a, .dark-skin.color-pink .hover-a:hover p > a {
  color: #e83e8c !important;
}

.dark-skin.color-pink .hover-a:hover h1 > a.text-white, .dark-skin.color-pink .hover-a:hover h2 > a.text-white, .dark-skin.color-pink .hover-a:hover h3 > a.text-white, .dark-skin.color-pink .hover-a:hover h4 > a.text-white, .dark-skin.color-pink .hover-a:hover h5 > a.text-white, .dark-skin.color-pink .hover-a:hover h6 > a.text-white, .dark-skin.color-pink .hover-a:hover .h1 > a.text-white, .dark-skin.color-pink .hover-a:hover .h2 > a.text-white, .dark-skin.color-pink .hover-a:hover .h3 > a.text-white, .dark-skin.color-pink .hover-a:hover .h4 > a.text-white, .dark-skin.color-pink .hover-a:hover .h5 > a.text-white, .dark-skin.color-pink .hover-a:hover .h6 > a.text-white, .dark-skin.color-pink .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-pink #theme-settings .fa-cog {
  color: #e83e8c;
}

.dark-skin.color-pink .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-pink .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-pink .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #e83e8c !important;
}

.dark-skin.color-pink .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #e83e8c;
}

.dark-skin.color-pink .nav-block-link li a.nav-link, .dark-skin.color-pink .nav-block-link1 li a.nav-link, .dark-skin.color-pink .nav-block-link2 li a.nav-link, .dark-skin.color-pink .nav-block-link3 li a.nav-link, .dark-skin.color-pink .nav-block-link4 li a.nav-link, .dark-skin.color-pink .nav-block-link5 li a.nav-link, .dark-skin.color-pink .nav-block-link6 li a.nav-link, .dark-skin.color-pink .nav-block-link7 li a.nav-link, .dark-skin.color-pink .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-pink .side-link li a.caret-collapse.collapsed, .dark-skin.color-pink .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-pink .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-pink .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #e83e8c;
}

.dark-skin.color-red h1, .dark-skin.color-red h2, .dark-skin.color-red h3, .dark-skin.color-red h4, .dark-skin.color-red h5, .dark-skin.color-red h6, .dark-skin.color-red .h1, .dark-skin.color-red .h2, .dark-skin.color-red .h3, .dark-skin.color-red .h4, .dark-skin.color-red .h5, .dark-skin.color-red .h6 {
  color: #f6f6f6;
}

.dark-skin.color-red h1 > a, .dark-skin.color-red h2 > a, .dark-skin.color-red h3 > a, .dark-skin.color-red h4 > a, .dark-skin.color-red h5 > a, .dark-skin.color-red h6 > a, .dark-skin.color-red .h1 > a, .dark-skin.color-red .h2 > a, .dark-skin.color-red .h3 > a, .dark-skin.color-red .h4 > a, .dark-skin.color-red .h5 > a, .dark-skin.color-red .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-red h1 > a:hover, .dark-skin.color-red h2 > a:hover, .dark-skin.color-red h3 > a:hover, .dark-skin.color-red h4 > a:hover, .dark-skin.color-red h5 > a:hover, .dark-skin.color-red h6 > a:hover, .dark-skin.color-red .h1 > a:hover, .dark-skin.color-red .h2 > a:hover, .dark-skin.color-red .h3 > a:hover, .dark-skin.color-red .h4 > a:hover, .dark-skin.color-red .h5 > a:hover, .dark-skin.color-red .h6 > a:hover {
  color: #d72924 !important;
}

.dark-skin.color-red h1 > a.text-white, .dark-skin.color-red h2 > a.text-white, .dark-skin.color-red h3 > a.text-white, .dark-skin.color-red h4 > a.text-white, .dark-skin.color-red h5 > a.text-white, .dark-skin.color-red h6 > a.text-white, .dark-skin.color-red .h1 > a.text-white, .dark-skin.color-red .h2 > a.text-white, .dark-skin.color-red .h3 > a.text-white, .dark-skin.color-red .h4 > a.text-white, .dark-skin.color-red .h5 > a.text-white, .dark-skin.color-red .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-red .hover-a:hover h1 > a, .dark-skin.color-red .hover-a:hover h2 > a, .dark-skin.color-red .hover-a:hover h3 > a, .dark-skin.color-red .hover-a:hover h4 > a, .dark-skin.color-red .hover-a:hover h5 > a, .dark-skin.color-red .hover-a:hover h6 > a, .dark-skin.color-red .hover-a:hover .h1 > a, .dark-skin.color-red .hover-a:hover .h2 > a, .dark-skin.color-red .hover-a:hover .h3 > a, .dark-skin.color-red .hover-a:hover .h4 > a, .dark-skin.color-red .hover-a:hover .h5 > a, .dark-skin.color-red .hover-a:hover .h6 > a, .dark-skin.color-red .hover-a:hover p > a {
  color: #d72924 !important;
}

.dark-skin.color-red .hover-a:hover h1 > a.text-white, .dark-skin.color-red .hover-a:hover h2 > a.text-white, .dark-skin.color-red .hover-a:hover h3 > a.text-white, .dark-skin.color-red .hover-a:hover h4 > a.text-white, .dark-skin.color-red .hover-a:hover h5 > a.text-white, .dark-skin.color-red .hover-a:hover h6 > a.text-white, .dark-skin.color-red .hover-a:hover .h1 > a.text-white, .dark-skin.color-red .hover-a:hover .h2 > a.text-white, .dark-skin.color-red .hover-a:hover .h3 > a.text-white, .dark-skin.color-red .hover-a:hover .h4 > a.text-white, .dark-skin.color-red .hover-a:hover .h5 > a.text-white, .dark-skin.color-red .hover-a:hover .h6 > a.text-white, .dark-skin.color-red .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-red #theme-settings .fa-cog {
  color: #d72924;
}

.dark-skin.color-red .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-red .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-red .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #d72924 !important;
}

.dark-skin.color-red .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #d72924;
}

.dark-skin.color-red .nav-block-link li a.nav-link, .dark-skin.color-red .nav-block-link1 li a.nav-link, .dark-skin.color-red .nav-block-link2 li a.nav-link, .dark-skin.color-red .nav-block-link3 li a.nav-link, .dark-skin.color-red .nav-block-link4 li a.nav-link, .dark-skin.color-red .nav-block-link5 li a.nav-link, .dark-skin.color-red .nav-block-link6 li a.nav-link, .dark-skin.color-red .nav-block-link7 li a.nav-link, .dark-skin.color-red .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-red .side-link li a.caret-collapse.collapsed, .dark-skin.color-red .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-red .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-red .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #d72924;
}

.dark-skin.color-orange h1, .dark-skin.color-orange h2, .dark-skin.color-orange h3, .dark-skin.color-orange h4, .dark-skin.color-orange h5, .dark-skin.color-orange h6, .dark-skin.color-orange .h1, .dark-skin.color-orange .h2, .dark-skin.color-orange .h3, .dark-skin.color-orange .h4, .dark-skin.color-orange .h5, .dark-skin.color-orange .h6 {
  color: #f6f6f6;
}

.dark-skin.color-orange h1 > a, .dark-skin.color-orange h2 > a, .dark-skin.color-orange h3 > a, .dark-skin.color-orange h4 > a, .dark-skin.color-orange h5 > a, .dark-skin.color-orange h6 > a, .dark-skin.color-orange .h1 > a, .dark-skin.color-orange .h2 > a, .dark-skin.color-orange .h3 > a, .dark-skin.color-orange .h4 > a, .dark-skin.color-orange .h5 > a, .dark-skin.color-orange .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-orange h1 > a:hover, .dark-skin.color-orange h2 > a:hover, .dark-skin.color-orange h3 > a:hover, .dark-skin.color-orange h4 > a:hover, .dark-skin.color-orange h5 > a:hover, .dark-skin.color-orange h6 > a:hover, .dark-skin.color-orange .h1 > a:hover, .dark-skin.color-orange .h2 > a:hover, .dark-skin.color-orange .h3 > a:hover, .dark-skin.color-orange .h4 > a:hover, .dark-skin.color-orange .h5 > a:hover, .dark-skin.color-orange .h6 > a:hover {
  color: #fa983a !important;
}

.dark-skin.color-orange h1 > a.text-white, .dark-skin.color-orange h2 > a.text-white, .dark-skin.color-orange h3 > a.text-white, .dark-skin.color-orange h4 > a.text-white, .dark-skin.color-orange h5 > a.text-white, .dark-skin.color-orange h6 > a.text-white, .dark-skin.color-orange .h1 > a.text-white, .dark-skin.color-orange .h2 > a.text-white, .dark-skin.color-orange .h3 > a.text-white, .dark-skin.color-orange .h4 > a.text-white, .dark-skin.color-orange .h5 > a.text-white, .dark-skin.color-orange .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-orange .hover-a:hover h1 > a, .dark-skin.color-orange .hover-a:hover h2 > a, .dark-skin.color-orange .hover-a:hover h3 > a, .dark-skin.color-orange .hover-a:hover h4 > a, .dark-skin.color-orange .hover-a:hover h5 > a, .dark-skin.color-orange .hover-a:hover h6 > a, .dark-skin.color-orange .hover-a:hover .h1 > a, .dark-skin.color-orange .hover-a:hover .h2 > a, .dark-skin.color-orange .hover-a:hover .h3 > a, .dark-skin.color-orange .hover-a:hover .h4 > a, .dark-skin.color-orange .hover-a:hover .h5 > a, .dark-skin.color-orange .hover-a:hover .h6 > a, .dark-skin.color-orange .hover-a:hover p > a {
  color: #fa983a !important;
}

.dark-skin.color-orange .hover-a:hover h1 > a.text-white, .dark-skin.color-orange .hover-a:hover h2 > a.text-white, .dark-skin.color-orange .hover-a:hover h3 > a.text-white, .dark-skin.color-orange .hover-a:hover h4 > a.text-white, .dark-skin.color-orange .hover-a:hover h5 > a.text-white, .dark-skin.color-orange .hover-a:hover h6 > a.text-white, .dark-skin.color-orange .hover-a:hover .h1 > a.text-white, .dark-skin.color-orange .hover-a:hover .h2 > a.text-white, .dark-skin.color-orange .hover-a:hover .h3 > a.text-white, .dark-skin.color-orange .hover-a:hover .h4 > a.text-white, .dark-skin.color-orange .hover-a:hover .h5 > a.text-white, .dark-skin.color-orange .hover-a:hover .h6 > a.text-white, .dark-skin.color-orange .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-orange #theme-settings .fa-cog {
  color: #fa983a;
}

.dark-skin.color-orange .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-orange .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-orange .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #fa983a !important;
}

.dark-skin.color-orange .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #fa983a;
}

.dark-skin.color-orange .nav-block-link li a.nav-link, .dark-skin.color-orange .nav-block-link1 li a.nav-link, .dark-skin.color-orange .nav-block-link2 li a.nav-link, .dark-skin.color-orange .nav-block-link3 li a.nav-link, .dark-skin.color-orange .nav-block-link4 li a.nav-link, .dark-skin.color-orange .nav-block-link5 li a.nav-link, .dark-skin.color-orange .nav-block-link6 li a.nav-link, .dark-skin.color-orange .nav-block-link7 li a.nav-link, .dark-skin.color-orange .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-orange .side-link li a.caret-collapse.collapsed, .dark-skin.color-orange .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-orange .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-orange .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #fa983a;
}

.dark-skin.color-yellow h1, .dark-skin.color-yellow h2, .dark-skin.color-yellow h3, .dark-skin.color-yellow h4, .dark-skin.color-yellow h5, .dark-skin.color-yellow h6, .dark-skin.color-yellow .h1, .dark-skin.color-yellow .h2, .dark-skin.color-yellow .h3, .dark-skin.color-yellow .h4, .dark-skin.color-yellow .h5, .dark-skin.color-yellow .h6 {
  color: #f6f6f6;
}

.dark-skin.color-yellow h1 > a, .dark-skin.color-yellow h2 > a, .dark-skin.color-yellow h3 > a, .dark-skin.color-yellow h4 > a, .dark-skin.color-yellow h5 > a, .dark-skin.color-yellow h6 > a, .dark-skin.color-yellow .h1 > a, .dark-skin.color-yellow .h2 > a, .dark-skin.color-yellow .h3 > a, .dark-skin.color-yellow .h4 > a, .dark-skin.color-yellow .h5 > a, .dark-skin.color-yellow .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-yellow h1 > a:hover, .dark-skin.color-yellow h2 > a:hover, .dark-skin.color-yellow h3 > a:hover, .dark-skin.color-yellow h4 > a:hover, .dark-skin.color-yellow h5 > a:hover, .dark-skin.color-yellow h6 > a:hover, .dark-skin.color-yellow .h1 > a:hover, .dark-skin.color-yellow .h2 > a:hover, .dark-skin.color-yellow .h3 > a:hover, .dark-skin.color-yellow .h4 > a:hover, .dark-skin.color-yellow .h5 > a:hover, .dark-skin.color-yellow .h6 > a:hover {
  color: #f4b12c !important;
}

.dark-skin.color-yellow h1 > a.text-white, .dark-skin.color-yellow h2 > a.text-white, .dark-skin.color-yellow h3 > a.text-white, .dark-skin.color-yellow h4 > a.text-white, .dark-skin.color-yellow h5 > a.text-white, .dark-skin.color-yellow h6 > a.text-white, .dark-skin.color-yellow .h1 > a.text-white, .dark-skin.color-yellow .h2 > a.text-white, .dark-skin.color-yellow .h3 > a.text-white, .dark-skin.color-yellow .h4 > a.text-white, .dark-skin.color-yellow .h5 > a.text-white, .dark-skin.color-yellow .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-yellow .hover-a:hover h1 > a, .dark-skin.color-yellow .hover-a:hover h2 > a, .dark-skin.color-yellow .hover-a:hover h3 > a, .dark-skin.color-yellow .hover-a:hover h4 > a, .dark-skin.color-yellow .hover-a:hover h5 > a, .dark-skin.color-yellow .hover-a:hover h6 > a, .dark-skin.color-yellow .hover-a:hover .h1 > a, .dark-skin.color-yellow .hover-a:hover .h2 > a, .dark-skin.color-yellow .hover-a:hover .h3 > a, .dark-skin.color-yellow .hover-a:hover .h4 > a, .dark-skin.color-yellow .hover-a:hover .h5 > a, .dark-skin.color-yellow .hover-a:hover .h6 > a, .dark-skin.color-yellow .hover-a:hover p > a {
  color: #f4b12c !important;
}

.dark-skin.color-yellow .hover-a:hover h1 > a.text-white, .dark-skin.color-yellow .hover-a:hover h2 > a.text-white, .dark-skin.color-yellow .hover-a:hover h3 > a.text-white, .dark-skin.color-yellow .hover-a:hover h4 > a.text-white, .dark-skin.color-yellow .hover-a:hover h5 > a.text-white, .dark-skin.color-yellow .hover-a:hover h6 > a.text-white, .dark-skin.color-yellow .hover-a:hover .h1 > a.text-white, .dark-skin.color-yellow .hover-a:hover .h2 > a.text-white, .dark-skin.color-yellow .hover-a:hover .h3 > a.text-white, .dark-skin.color-yellow .hover-a:hover .h4 > a.text-white, .dark-skin.color-yellow .hover-a:hover .h5 > a.text-white, .dark-skin.color-yellow .hover-a:hover .h6 > a.text-white, .dark-skin.color-yellow .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-yellow #theme-settings .fa-cog {
  color: #f4b12c;
}

.dark-skin.color-yellow .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-yellow .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-yellow .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #f4b12c !important;
}

.dark-skin.color-yellow .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #f4b12c;
}

.dark-skin.color-yellow .nav-block-link li a.nav-link, .dark-skin.color-yellow .nav-block-link1 li a.nav-link, .dark-skin.color-yellow .nav-block-link2 li a.nav-link, .dark-skin.color-yellow .nav-block-link3 li a.nav-link, .dark-skin.color-yellow .nav-block-link4 li a.nav-link, .dark-skin.color-yellow .nav-block-link5 li a.nav-link, .dark-skin.color-yellow .nav-block-link6 li a.nav-link, .dark-skin.color-yellow .nav-block-link7 li a.nav-link, .dark-skin.color-yellow .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-yellow .side-link li a.caret-collapse.collapsed, .dark-skin.color-yellow .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-yellow .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-yellow .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #f4b12c;
}

.dark-skin.color-green h1, .dark-skin.color-green h2, .dark-skin.color-green h3, .dark-skin.color-green h4, .dark-skin.color-green h5, .dark-skin.color-green h6, .dark-skin.color-green .h1, .dark-skin.color-green .h2, .dark-skin.color-green .h3, .dark-skin.color-green .h4, .dark-skin.color-green .h5, .dark-skin.color-green .h6 {
  color: #f6f6f6;
}

.dark-skin.color-green h1 > a, .dark-skin.color-green h2 > a, .dark-skin.color-green h3 > a, .dark-skin.color-green h4 > a, .dark-skin.color-green h5 > a, .dark-skin.color-green h6 > a, .dark-skin.color-green .h1 > a, .dark-skin.color-green .h2 > a, .dark-skin.color-green .h3 > a, .dark-skin.color-green .h4 > a, .dark-skin.color-green .h5 > a, .dark-skin.color-green .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-green h1 > a:hover, .dark-skin.color-green h2 > a:hover, .dark-skin.color-green h3 > a:hover, .dark-skin.color-green h4 > a:hover, .dark-skin.color-green h5 > a:hover, .dark-skin.color-green h6 > a:hover, .dark-skin.color-green .h1 > a:hover, .dark-skin.color-green .h2 > a:hover, .dark-skin.color-green .h3 > a:hover, .dark-skin.color-green .h4 > a:hover, .dark-skin.color-green .h5 > a:hover, .dark-skin.color-green .h6 > a:hover {
  color: #00b191 !important;
}

.dark-skin.color-green h1 > a.text-white, .dark-skin.color-green h2 > a.text-white, .dark-skin.color-green h3 > a.text-white, .dark-skin.color-green h4 > a.text-white, .dark-skin.color-green h5 > a.text-white, .dark-skin.color-green h6 > a.text-white, .dark-skin.color-green .h1 > a.text-white, .dark-skin.color-green .h2 > a.text-white, .dark-skin.color-green .h3 > a.text-white, .dark-skin.color-green .h4 > a.text-white, .dark-skin.color-green .h5 > a.text-white, .dark-skin.color-green .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-green .hover-a:hover h1 > a, .dark-skin.color-green .hover-a:hover h2 > a, .dark-skin.color-green .hover-a:hover h3 > a, .dark-skin.color-green .hover-a:hover h4 > a, .dark-skin.color-green .hover-a:hover h5 > a, .dark-skin.color-green .hover-a:hover h6 > a, .dark-skin.color-green .hover-a:hover .h1 > a, .dark-skin.color-green .hover-a:hover .h2 > a, .dark-skin.color-green .hover-a:hover .h3 > a, .dark-skin.color-green .hover-a:hover .h4 > a, .dark-skin.color-green .hover-a:hover .h5 > a, .dark-skin.color-green .hover-a:hover .h6 > a, .dark-skin.color-green .hover-a:hover p > a {
  color: #00b191 !important;
}

.dark-skin.color-green .hover-a:hover h1 > a.text-white, .dark-skin.color-green .hover-a:hover h2 > a.text-white, .dark-skin.color-green .hover-a:hover h3 > a.text-white, .dark-skin.color-green .hover-a:hover h4 > a.text-white, .dark-skin.color-green .hover-a:hover h5 > a.text-white, .dark-skin.color-green .hover-a:hover h6 > a.text-white, .dark-skin.color-green .hover-a:hover .h1 > a.text-white, .dark-skin.color-green .hover-a:hover .h2 > a.text-white, .dark-skin.color-green .hover-a:hover .h3 > a.text-white, .dark-skin.color-green .hover-a:hover .h4 > a.text-white, .dark-skin.color-green .hover-a:hover .h5 > a.text-white, .dark-skin.color-green .hover-a:hover .h6 > a.text-white, .dark-skin.color-green .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-green #theme-settings .fa-cog {
  color: #00b191;
}

.dark-skin.color-green .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-green .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-green .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #00b191 !important;
}

.dark-skin.color-green .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #00b191;
}

.dark-skin.color-green .nav-block-link li a.nav-link, .dark-skin.color-green .nav-block-link1 li a.nav-link, .dark-skin.color-green .nav-block-link2 li a.nav-link, .dark-skin.color-green .nav-block-link3 li a.nav-link, .dark-skin.color-green .nav-block-link4 li a.nav-link, .dark-skin.color-green .nav-block-link5 li a.nav-link, .dark-skin.color-green .nav-block-link6 li a.nav-link, .dark-skin.color-green .nav-block-link7 li a.nav-link, .dark-skin.color-green .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-green .side-link li a.caret-collapse.collapsed, .dark-skin.color-green .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-green .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-green .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #00b191;
}

.dark-skin.color-black h1, .dark-skin.color-black h2, .dark-skin.color-black h3, .dark-skin.color-black h4, .dark-skin.color-black h5, .dark-skin.color-black h6, .dark-skin.color-black .h1, .dark-skin.color-black .h2, .dark-skin.color-black .h3, .dark-skin.color-black .h4, .dark-skin.color-black .h5, .dark-skin.color-black .h6 {
  color: #f6f6f6;
}

.dark-skin.color-black h1 > a, .dark-skin.color-black h2 > a, .dark-skin.color-black h3 > a, .dark-skin.color-black h4 > a, .dark-skin.color-black h5 > a, .dark-skin.color-black h6 > a, .dark-skin.color-black .h1 > a, .dark-skin.color-black .h2 > a, .dark-skin.color-black .h3 > a, .dark-skin.color-black .h4 > a, .dark-skin.color-black .h5 > a, .dark-skin.color-black .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-black h1 > a:hover, .dark-skin.color-black h2 > a:hover, .dark-skin.color-black h3 > a:hover, .dark-skin.color-black h4 > a:hover, .dark-skin.color-black h5 > a:hover, .dark-skin.color-black h6 > a:hover, .dark-skin.color-black .h1 > a:hover, .dark-skin.color-black .h2 > a:hover, .dark-skin.color-black .h3 > a:hover, .dark-skin.color-black .h4 > a:hover, .dark-skin.color-black .h5 > a:hover, .dark-skin.color-black .h6 > a:hover {
  color: #000000 !important;
}

.dark-skin.color-black h1 > a.text-white, .dark-skin.color-black h2 > a.text-white, .dark-skin.color-black h3 > a.text-white, .dark-skin.color-black h4 > a.text-white, .dark-skin.color-black h5 > a.text-white, .dark-skin.color-black h6 > a.text-white, .dark-skin.color-black .h1 > a.text-white, .dark-skin.color-black .h2 > a.text-white, .dark-skin.color-black .h3 > a.text-white, .dark-skin.color-black .h4 > a.text-white, .dark-skin.color-black .h5 > a.text-white, .dark-skin.color-black .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-black .hover-a:hover h1 > a, .dark-skin.color-black .hover-a:hover h2 > a, .dark-skin.color-black .hover-a:hover h3 > a, .dark-skin.color-black .hover-a:hover h4 > a, .dark-skin.color-black .hover-a:hover h5 > a, .dark-skin.color-black .hover-a:hover h6 > a, .dark-skin.color-black .hover-a:hover .h1 > a, .dark-skin.color-black .hover-a:hover .h2 > a, .dark-skin.color-black .hover-a:hover .h3 > a, .dark-skin.color-black .hover-a:hover .h4 > a, .dark-skin.color-black .hover-a:hover .h5 > a, .dark-skin.color-black .hover-a:hover .h6 > a, .dark-skin.color-black .hover-a:hover p > a {
  color: #000000 !important;
}

.dark-skin.color-black .hover-a:hover h1 > a.text-white, .dark-skin.color-black .hover-a:hover h2 > a.text-white, .dark-skin.color-black .hover-a:hover h3 > a.text-white, .dark-skin.color-black .hover-a:hover h4 > a.text-white, .dark-skin.color-black .hover-a:hover h5 > a.text-white, .dark-skin.color-black .hover-a:hover h6 > a.text-white, .dark-skin.color-black .hover-a:hover .h1 > a.text-white, .dark-skin.color-black .hover-a:hover .h2 > a.text-white, .dark-skin.color-black .hover-a:hover .h3 > a.text-white, .dark-skin.color-black .hover-a:hover .h4 > a.text-white, .dark-skin.color-black .hover-a:hover .h5 > a.text-white, .dark-skin.color-black .hover-a:hover .h6 > a.text-white, .dark-skin.color-black .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-black #theme-settings .fa-cog {
  color: #000000;
}

.dark-skin.color-black .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-black .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-black .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #000000 !important;
}

.dark-skin.color-black .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #000000;
}

.dark-skin.color-black .nav-block-link li a.nav-link, .dark-skin.color-black .nav-block-link1 li a.nav-link, .dark-skin.color-black .nav-block-link2 li a.nav-link, .dark-skin.color-black .nav-block-link3 li a.nav-link, .dark-skin.color-black .nav-block-link4 li a.nav-link, .dark-skin.color-black .nav-block-link5 li a.nav-link, .dark-skin.color-black .nav-block-link6 li a.nav-link, .dark-skin.color-black .nav-block-link7 li a.nav-link, .dark-skin.color-black .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-black .side-link li a.caret-collapse.collapsed, .dark-skin.color-black .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-black .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-black .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #000000;
}

.dark-skin.color-darkblue h1, .dark-skin.color-darkblue h2, .dark-skin.color-darkblue h3, .dark-skin.color-darkblue h4, .dark-skin.color-darkblue h5, .dark-skin.color-darkblue h6, .dark-skin.color-darkblue .h1, .dark-skin.color-darkblue .h2, .dark-skin.color-darkblue .h3, .dark-skin.color-darkblue .h4, .dark-skin.color-darkblue .h5, .dark-skin.color-darkblue .h6 {
  color: #f6f6f6;
}

.dark-skin.color-darkblue h1 > a, .dark-skin.color-darkblue h2 > a, .dark-skin.color-darkblue h3 > a, .dark-skin.color-darkblue h4 > a, .dark-skin.color-darkblue h5 > a, .dark-skin.color-darkblue h6 > a, .dark-skin.color-darkblue .h1 > a, .dark-skin.color-darkblue .h2 > a, .dark-skin.color-darkblue .h3 > a, .dark-skin.color-darkblue .h4 > a, .dark-skin.color-darkblue .h5 > a, .dark-skin.color-darkblue .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-darkblue h1 > a:hover, .dark-skin.color-darkblue h2 > a:hover, .dark-skin.color-darkblue h3 > a:hover, .dark-skin.color-darkblue h4 > a:hover, .dark-skin.color-darkblue h5 > a:hover, .dark-skin.color-darkblue h6 > a:hover, .dark-skin.color-darkblue .h1 > a:hover, .dark-skin.color-darkblue .h2 > a:hover, .dark-skin.color-darkblue .h3 > a:hover, .dark-skin.color-darkblue .h4 > a:hover, .dark-skin.color-darkblue .h5 > a:hover, .dark-skin.color-darkblue .h6 > a:hover {
  color: #07478f !important;
}

.dark-skin.color-darkblue h1 > a.text-white, .dark-skin.color-darkblue h2 > a.text-white, .dark-skin.color-darkblue h3 > a.text-white, .dark-skin.color-darkblue h4 > a.text-white, .dark-skin.color-darkblue h5 > a.text-white, .dark-skin.color-darkblue h6 > a.text-white, .dark-skin.color-darkblue .h1 > a.text-white, .dark-skin.color-darkblue .h2 > a.text-white, .dark-skin.color-darkblue .h3 > a.text-white, .dark-skin.color-darkblue .h4 > a.text-white, .dark-skin.color-darkblue .h5 > a.text-white, .dark-skin.color-darkblue .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-darkblue .hover-a:hover h1 > a, .dark-skin.color-darkblue .hover-a:hover h2 > a, .dark-skin.color-darkblue .hover-a:hover h3 > a, .dark-skin.color-darkblue .hover-a:hover h4 > a, .dark-skin.color-darkblue .hover-a:hover h5 > a, .dark-skin.color-darkblue .hover-a:hover h6 > a, .dark-skin.color-darkblue .hover-a:hover .h1 > a, .dark-skin.color-darkblue .hover-a:hover .h2 > a, .dark-skin.color-darkblue .hover-a:hover .h3 > a, .dark-skin.color-darkblue .hover-a:hover .h4 > a, .dark-skin.color-darkblue .hover-a:hover .h5 > a, .dark-skin.color-darkblue .hover-a:hover .h6 > a, .dark-skin.color-darkblue .hover-a:hover p > a {
  color: #07478f !important;
}

.dark-skin.color-darkblue .hover-a:hover h1 > a.text-white, .dark-skin.color-darkblue .hover-a:hover h2 > a.text-white, .dark-skin.color-darkblue .hover-a:hover h3 > a.text-white, .dark-skin.color-darkblue .hover-a:hover h4 > a.text-white, .dark-skin.color-darkblue .hover-a:hover h5 > a.text-white, .dark-skin.color-darkblue .hover-a:hover h6 > a.text-white, .dark-skin.color-darkblue .hover-a:hover .h1 > a.text-white, .dark-skin.color-darkblue .hover-a:hover .h2 > a.text-white, .dark-skin.color-darkblue .hover-a:hover .h3 > a.text-white, .dark-skin.color-darkblue .hover-a:hover .h4 > a.text-white, .dark-skin.color-darkblue .hover-a:hover .h5 > a.text-white, .dark-skin.color-darkblue .hover-a:hover .h6 > a.text-white, .dark-skin.color-darkblue .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-darkblue #theme-settings .fa-cog {
  color: #07478f;
}

.dark-skin.color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #07478f !important;
}

.dark-skin.color-darkblue .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #07478f;
}

.dark-skin.color-darkblue .nav-block-link li a.nav-link, .dark-skin.color-darkblue .nav-block-link1 li a.nav-link, .dark-skin.color-darkblue .nav-block-link2 li a.nav-link, .dark-skin.color-darkblue .nav-block-link3 li a.nav-link, .dark-skin.color-darkblue .nav-block-link4 li a.nav-link, .dark-skin.color-darkblue .nav-block-link5 li a.nav-link, .dark-skin.color-darkblue .nav-block-link6 li a.nav-link, .dark-skin.color-darkblue .nav-block-link7 li a.nav-link, .dark-skin.color-darkblue .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-darkblue .side-link li a.caret-collapse.collapsed, .dark-skin.color-darkblue .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-darkblue .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-darkblue .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #07478f;
}

.dark-skin.color-lightblue h1, .dark-skin.color-lightblue h2, .dark-skin.color-lightblue h3, .dark-skin.color-lightblue h4, .dark-skin.color-lightblue h5, .dark-skin.color-lightblue h6, .dark-skin.color-lightblue .h1, .dark-skin.color-lightblue .h2, .dark-skin.color-lightblue .h3, .dark-skin.color-lightblue .h4, .dark-skin.color-lightblue .h5, .dark-skin.color-lightblue .h6 {
  color: #f6f6f6;
}

.dark-skin.color-lightblue h1 > a, .dark-skin.color-lightblue h2 > a, .dark-skin.color-lightblue h3 > a, .dark-skin.color-lightblue h4 > a, .dark-skin.color-lightblue h5 > a, .dark-skin.color-lightblue h6 > a, .dark-skin.color-lightblue .h1 > a, .dark-skin.color-lightblue .h2 > a, .dark-skin.color-lightblue .h3 > a, .dark-skin.color-lightblue .h4 > a, .dark-skin.color-lightblue .h5 > a, .dark-skin.color-lightblue .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-lightblue h1 > a:hover, .dark-skin.color-lightblue h2 > a:hover, .dark-skin.color-lightblue h3 > a:hover, .dark-skin.color-lightblue h4 > a:hover, .dark-skin.color-lightblue h5 > a:hover, .dark-skin.color-lightblue h6 > a:hover, .dark-skin.color-lightblue .h1 > a:hover, .dark-skin.color-lightblue .h2 > a:hover, .dark-skin.color-lightblue .h3 > a:hover, .dark-skin.color-lightblue .h4 > a:hover, .dark-skin.color-lightblue .h5 > a:hover, .dark-skin.color-lightblue .h6 > a:hover {
  color: #1db8fa !important;
}

.dark-skin.color-lightblue h1 > a.text-white, .dark-skin.color-lightblue h2 > a.text-white, .dark-skin.color-lightblue h3 > a.text-white, .dark-skin.color-lightblue h4 > a.text-white, .dark-skin.color-lightblue h5 > a.text-white, .dark-skin.color-lightblue h6 > a.text-white, .dark-skin.color-lightblue .h1 > a.text-white, .dark-skin.color-lightblue .h2 > a.text-white, .dark-skin.color-lightblue .h3 > a.text-white, .dark-skin.color-lightblue .h4 > a.text-white, .dark-skin.color-lightblue .h5 > a.text-white, .dark-skin.color-lightblue .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-lightblue .hover-a:hover h1 > a, .dark-skin.color-lightblue .hover-a:hover h2 > a, .dark-skin.color-lightblue .hover-a:hover h3 > a, .dark-skin.color-lightblue .hover-a:hover h4 > a, .dark-skin.color-lightblue .hover-a:hover h5 > a, .dark-skin.color-lightblue .hover-a:hover h6 > a, .dark-skin.color-lightblue .hover-a:hover .h1 > a, .dark-skin.color-lightblue .hover-a:hover .h2 > a, .dark-skin.color-lightblue .hover-a:hover .h3 > a, .dark-skin.color-lightblue .hover-a:hover .h4 > a, .dark-skin.color-lightblue .hover-a:hover .h5 > a, .dark-skin.color-lightblue .hover-a:hover .h6 > a, .dark-skin.color-lightblue .hover-a:hover p > a {
  color: #1db8fa !important;
}

.dark-skin.color-lightblue .hover-a:hover h1 > a.text-white, .dark-skin.color-lightblue .hover-a:hover h2 > a.text-white, .dark-skin.color-lightblue .hover-a:hover h3 > a.text-white, .dark-skin.color-lightblue .hover-a:hover h4 > a.text-white, .dark-skin.color-lightblue .hover-a:hover h5 > a.text-white, .dark-skin.color-lightblue .hover-a:hover h6 > a.text-white, .dark-skin.color-lightblue .hover-a:hover .h1 > a.text-white, .dark-skin.color-lightblue .hover-a:hover .h2 > a.text-white, .dark-skin.color-lightblue .hover-a:hover .h3 > a.text-white, .dark-skin.color-lightblue .hover-a:hover .h4 > a.text-white, .dark-skin.color-lightblue .hover-a:hover .h5 > a.text-white, .dark-skin.color-lightblue .hover-a:hover .h6 > a.text-white, .dark-skin.color-lightblue .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-lightblue #theme-settings .fa-cog {
  color: #1db8fa;
}

.dark-skin.color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #1db8fa !important;
}

.dark-skin.color-lightblue .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #1db8fa;
}

.dark-skin.color-lightblue .nav-block-link li a.nav-link, .dark-skin.color-lightblue .nav-block-link1 li a.nav-link, .dark-skin.color-lightblue .nav-block-link2 li a.nav-link, .dark-skin.color-lightblue .nav-block-link3 li a.nav-link, .dark-skin.color-lightblue .nav-block-link4 li a.nav-link, .dark-skin.color-lightblue .nav-block-link5 li a.nav-link, .dark-skin.color-lightblue .nav-block-link6 li a.nav-link, .dark-skin.color-lightblue .nav-block-link7 li a.nav-link, .dark-skin.color-lightblue .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-lightblue .side-link li a.caret-collapse.collapsed, .dark-skin.color-lightblue .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-lightblue .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-lightblue .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #1db8fa;
}

.dark-skin.color-blacksoft h1, .dark-skin.color-blacksoft h2, .dark-skin.color-blacksoft h3, .dark-skin.color-blacksoft h4, .dark-skin.color-blacksoft h5, .dark-skin.color-blacksoft h6, .dark-skin.color-blacksoft .h1, .dark-skin.color-blacksoft .h2, .dark-skin.color-blacksoft .h3, .dark-skin.color-blacksoft .h4, .dark-skin.color-blacksoft .h5, .dark-skin.color-blacksoft .h6 {
  color: #f6f6f6;
}

.dark-skin.color-blacksoft h1 > a, .dark-skin.color-blacksoft h2 > a, .dark-skin.color-blacksoft h3 > a, .dark-skin.color-blacksoft h4 > a, .dark-skin.color-blacksoft h5 > a, .dark-skin.color-blacksoft h6 > a, .dark-skin.color-blacksoft .h1 > a, .dark-skin.color-blacksoft .h2 > a, .dark-skin.color-blacksoft .h3 > a, .dark-skin.color-blacksoft .h4 > a, .dark-skin.color-blacksoft .h5 > a, .dark-skin.color-blacksoft .h6 > a {
  color: #f6f6f6;
}

.dark-skin.color-blacksoft h1 > a:hover, .dark-skin.color-blacksoft h2 > a:hover, .dark-skin.color-blacksoft h3 > a:hover, .dark-skin.color-blacksoft h4 > a:hover, .dark-skin.color-blacksoft h5 > a:hover, .dark-skin.color-blacksoft h6 > a:hover, .dark-skin.color-blacksoft .h1 > a:hover, .dark-skin.color-blacksoft .h2 > a:hover, .dark-skin.color-blacksoft .h3 > a:hover, .dark-skin.color-blacksoft .h4 > a:hover, .dark-skin.color-blacksoft .h5 > a:hover, .dark-skin.color-blacksoft .h6 > a:hover {
  color: #131313 !important;
}

.dark-skin.color-blacksoft h1 > a.text-white, .dark-skin.color-blacksoft h2 > a.text-white, .dark-skin.color-blacksoft h3 > a.text-white, .dark-skin.color-blacksoft h4 > a.text-white, .dark-skin.color-blacksoft h5 > a.text-white, .dark-skin.color-blacksoft h6 > a.text-white, .dark-skin.color-blacksoft .h1 > a.text-white, .dark-skin.color-blacksoft .h2 > a.text-white, .dark-skin.color-blacksoft .h3 > a.text-white, .dark-skin.color-blacksoft .h4 > a.text-white, .dark-skin.color-blacksoft .h5 > a.text-white, .dark-skin.color-blacksoft .h6 > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-blacksoft .hover-a:hover h1 > a, .dark-skin.color-blacksoft .hover-a:hover h2 > a, .dark-skin.color-blacksoft .hover-a:hover h3 > a, .dark-skin.color-blacksoft .hover-a:hover h4 > a, .dark-skin.color-blacksoft .hover-a:hover h5 > a, .dark-skin.color-blacksoft .hover-a:hover h6 > a, .dark-skin.color-blacksoft .hover-a:hover .h1 > a, .dark-skin.color-blacksoft .hover-a:hover .h2 > a, .dark-skin.color-blacksoft .hover-a:hover .h3 > a, .dark-skin.color-blacksoft .hover-a:hover .h4 > a, .dark-skin.color-blacksoft .hover-a:hover .h5 > a, .dark-skin.color-blacksoft .hover-a:hover .h6 > a, .dark-skin.color-blacksoft .hover-a:hover p > a {
  color: #131313 !important;
}

.dark-skin.color-blacksoft .hover-a:hover h1 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover h2 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover h3 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover h4 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover h5 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover h6 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover .h1 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover .h2 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover .h3 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover .h4 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover .h5 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover .h6 > a.text-white, .dark-skin.color-blacksoft .hover-a:hover p > a.text-white {
  color: #ffffff !important;
}

.dark-skin.color-blacksoft #theme-settings .fa-cog {
  color: #131313;
}

.dark-skin.color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .dark-skin.color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .dark-skin.color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #131313 !important;
}

.dark-skin.color-blacksoft .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
  color: #131313;
}

.dark-skin.color-blacksoft .nav-block-link li a.nav-link, .dark-skin.color-blacksoft .nav-block-link1 li a.nav-link, .dark-skin.color-blacksoft .nav-block-link2 li a.nav-link, .dark-skin.color-blacksoft .nav-block-link3 li a.nav-link, .dark-skin.color-blacksoft .nav-block-link4 li a.nav-link, .dark-skin.color-blacksoft .nav-block-link5 li a.nav-link, .dark-skin.color-blacksoft .nav-block-link6 li a.nav-link, .dark-skin.color-blacksoft .nav-block-link7 li a.nav-link, .dark-skin.color-blacksoft .nav-block-more li a.nav-link {
  color: #f6f6f6;
}

.dark-skin.color-blacksoft .side-link li a.caret-collapse.collapsed, .dark-skin.color-blacksoft .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin.color-blacksoft .side-link li a.caret-collapse.collapsed:hover, .dark-skin.color-blacksoft .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #131313;
}

@media (min-width: 992px) {
  .color-blue .hover-mode .main-nav li:hover > a {
    color: #2196f3;
  }
  .color-blue .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-blue .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #2196f3 !important;
  }
  .color-blue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-blue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-blue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #2196f3 !important;
  }
  .color-blue .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #2196f3 !important;
  }
  .color-blue .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-purple .hover-mode .main-nav li:hover > a {
    color: #7952B3;
  }
  .color-purple .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-purple .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #7952B3 !important;
  }
  .color-purple .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-purple .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-purple .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #7952B3 !important;
  }
  .color-purple .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #7952B3 !important;
  }
  .color-purple .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-pink .hover-mode .main-nav li:hover > a {
    color: #e83e8c;
  }
  .color-pink .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-pink .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #e83e8c !important;
  }
  .color-pink .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-pink .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-pink .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #e83e8c !important;
  }
  .color-pink .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #e83e8c !important;
  }
  .color-pink .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-red .hover-mode .main-nav li:hover > a {
    color: #d72924;
  }
  .color-red .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-red .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #d72924 !important;
  }
  .color-red .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-red .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-red .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #d72924 !important;
  }
  .color-red .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #d72924 !important;
  }
  .color-red .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-orange .hover-mode .main-nav li:hover > a {
    color: #fa983a;
  }
  .color-orange .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-orange .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #fa983a !important;
  }
  .color-orange .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-orange .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-orange .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #fa983a !important;
  }
  .color-orange .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #fa983a !important;
  }
  .color-orange .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-yellow .hover-mode .main-nav li:hover > a {
    color: #f4b12c;
  }
  .color-yellow .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-yellow .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #f4b12c !important;
  }
  .color-yellow .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-yellow .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-yellow .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #f4b12c !important;
  }
  .color-yellow .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #f4b12c !important;
  }
  .color-yellow .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-green .hover-mode .main-nav li:hover > a {
    color: #00b191;
  }
  .color-green .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-green .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #00b191 !important;
  }
  .color-green .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-green .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-green .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #00b191 !important;
  }
  .color-green .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #00b191 !important;
  }
  .color-green .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-black .hover-mode .main-nav li:hover > a {
    color: #000000;
  }
  .color-black .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-black .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #000000 !important;
  }
  .color-black .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-black .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-black .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #000000 !important;
  }
  .color-black .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #000000 !important;
  }
  .color-black .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-darkblue .hover-mode .main-nav li:hover > a {
    color: #07478f;
  }
  .color-darkblue .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-darkblue .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #07478f !important;
  }
  .color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-darkblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #07478f !important;
  }
  .color-darkblue .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #07478f !important;
  }
  .color-darkblue .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-lightblue .hover-mode .main-nav li:hover > a {
    color: #1db8fa;
  }
  .color-lightblue .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-lightblue .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #1db8fa !important;
  }
  .color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-lightblue .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #1db8fa !important;
  }
  .color-lightblue .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #1db8fa !important;
  }
  .color-lightblue .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
  .color-blacksoft .hover-mode .main-nav li:hover > a {
    color: #131313;
  }
  .color-blacksoft .main-menu.navbar-dark .hover-mode .main-nav > li > a.active, .color-blacksoft .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
    background-color: #131313 !important;
  }
  .color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .color-blacksoft .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
    color: #131313 !important;
  }
  .color-blacksoft .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #131313 !important;
  }
  .color-blacksoft .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #ffffff !important;
  }
}
