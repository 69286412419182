/*----------------------------------------------------------------------
    * Bootstrap Customizer
	* Bootstrap Demo Layout and Color Customizer
	* Copyright Ari Budin Bootstrap.News
	* Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*------------------------------------
	Demo Customizer by aribudin www.Bootstrap.News
------------------------------------*/
//
// Settings
//

@import "variables";

//
// Customizer scss
//

@import "theme/general";
@import "theme/color";
@import "theme/default";